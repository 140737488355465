import API from '../axios/api'
import axios from 'axios'

const BASE_LINK = `${API.BASE_LINK}/award`

export const getAllAwards = async () => {
    return await axios.get(BASE_LINK + '/')
}
export const createAwards = async (obj) => {
    return await axios.post(BASE_LINK + `/`, obj)
}
export const deleteSingleAwards = async (id) => {
    return await axios.delete(BASE_LINK + `/deleteById/${id}`)
}
export const updateSingleAwards = async (id) => {
    return await axios.patch(BASE_LINK + `/updateStatus/${id}`)
}
