import API from "../axios/api";
import axios from "axios";


const BASE_LINK = `${API.BASE_LINK}/sticker`;

export const newsticker = async (formData) => {
    return await axios.post(`${BASE_LINK}/create`,formData);
};

export const getallsticker = async () => {
    return await axios.get(`${BASE_LINK}/getall`);
};

export const stickerDelete = async (id) => {
    return await axios.delete(`${BASE_LINK}/delete?&_id=${id}`);
};

export const updatesticker = async (id, formData) => {
    console.log("id", id)
    return await axios.patch(`${BASE_LINK}/update/${id}`, formData);
};

export const getonesticker = async (id) => {
    return await axios.get(`${BASE_LINK}/get?_id=${id}`);
};
