import React from "react";
import { TextField } from "@mui/material";
// import ColorPicker from 'material-ui-color-picker'

const Variants = ({
  formVariantData,
  setFormVariantData,
  setVariantFormValues,
  variantFormValues,
  variantType,
  saveVariantData,
}) => {
  const handleFormValueChange = (e) => {
    setFormVariantData({ ...formVariantData, [e.target.name]: e.target.value });
  };

  // console.log(variantFormValues, "variantFormValues");
  console.log("variant type", variantType);
  console.log("formVariantData", formVariantData);
  
  return (
    <>
      {variantFormValues.map((item, index) => {
        console.log("item", item)
        return (
          <div key={index} style={{ marginTop: "10px" }}>
            {variantType === "color" && item === "color" ? (
              <input type="color" className="color-input" label={item}
              name={item} onChange={(e) => handleFormValueChange(e)} value={item === "color" ? formVariantData.color : ""}
              />
           
            ) : (
              <TextField
                id={variantType}
                label={item}
                name={item}
                variant="outlined"
                onChange={(e) => handleFormValueChange(e)}
                value={
                  item === "length"
                    ? formVariantData.length
                    : item === "width"
                    ? formVariantData.width
                    : item === "height"
                    ? formVariantData.height
                    : item === "color"
                    ? formVariantData.color
                    : item === "quantity"
                    ? formVariantData.quantity
                    : ""
                }
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default Variants;
