import React, { useEffect } from 'react'
import { TextField, styled, Grid, Typography } from '@mui/material';
import styles from "./banner.module.css";
import uploads from "../../../Assets/images/uploads.png";
import { useState, useRef } from 'react';

import { updateBanner, getonebanner } from '../../../services/banner';


const Labels = styled(Typography)`
color: #878787;
font-size: 12px;
margin: 5px 0px;
`

const EditModal = ({ closeEditModal, activeEditId, reload }) => {
     
    useEffect(()=>{
     data();
    },[activeEditId])
   
    // const [OneCatData, setOneCatData] = useState([]);
    
    // if(activeEditId) data();
    
    const initialValues = { name: "", order: "", validFrom: "", validTill: "", description: "" };
    console.log(initialValues.name);
    const [formValues, setFormValues] = useState(initialValues);
    const [image, setImage] = useState("");
    const inputRef = useRef(null);
    const data = async () => {
        let { data: res } = await getonebanner(activeEditId);
        console.log(res.data);
        setFormValues(res.data);
    }

    const handleImageClick = () => {
        inputRef.current.click();
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        console.log(file);
        setImage(e.target.files[0]);
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    }

    const handleDrop = (e) => {
        e.preventDefault();
        setImage(e.dataTransfer.files[0]);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        console.log("form data", formValues);
    };

    const handleEditSubmit = async (e) => {

        e.preventDefault();

        // setFormErrors(validate(formValues));

        const formdata = new FormData();
        if (image != "") formdata.append("image", image);
        if (formValues.name != "") formdata.append("name", formValues.name);
        if (formValues.order != "") formdata.append("order", formValues.order);
        if (formValues.validFrom != "") formdata.append("validFrom", formValues.validFrom);
        if (formValues.validTill != "") formdata.append("validTill", formValues.validTill);
        if (formValues.description != "") formdata.append("description", formValues.description);

        if (formValues.name || formValues.order || formValues.validFrom || formValues.validTill || formValues.description || image) {
            console.log("data edited to form ");
            let { data: res } = await updateBanner(activeEditId, formdata);
            alert(res.message)
            reload();
            setTimeout(closeEditModal, 500);
        } else {
            alert("Please fill at least one field")
            setTimeout(closeEditModal, 500);
        }
    }


    return (
        <>
            <div className={styles.wrapper} onClick={closeEditModal}></div>
            <div className={styles.editmodal}>

                <div >
                    <p className={styles.headertext}>Edit Banner Details</p>
                </div>
                <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>

                        <TextField
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            name="name"
                            value={formValues.name}
                            onChange={handleChange}
                            className={styles.inputs} />

                        <Labels>Valid From</Labels>
                        <input
                            type="date"
                            label="Valid From"
                            name="validFrom"
                            value={formValues.validFrom}
                            onChange={handleChange}
                            className={styles.date}></input>

                        <div onClick={handleImageClick} onDragOver={handleDragOver} onDrop={handleDrop}>
                            <div >
                                {image ?
                                    (<img src={URL.createObjectURL(image)} className={styles.upload} />)
                                    : (< img src={uploads} className={styles.upload} />)}
                            </div>
                            <input
                                type="file" ref={inputRef} onChange={handleImageChange} style={{ display: "none" }}></input>
                        </div>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            id="outlined-basic"
                            label="Order"
                            name="order"
                            variant="outlined"
                            value={formValues.order}
                            onChange={handleChange}
                            className={styles.inputs} />
                        <Labels>Valid Till</Labels>
                        <input
                            type="date"
                            label="Valid Till"
                            name="validTill"
                            value={formValues.validTill}
                            onChange={handleChange}
                            className={styles.date}></input>

                        <TextField
                            id="outlined-multiline-static"
                            label="Description"
                            name="description"
                            onChange={handleChange}
                            multiline
                            value={formValues.description}
                            rows={6}
                            className={styles.description}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <button className={styles.button} onClick={closeEditModal}>
                            Close
                        </button>
                        <button className={styles.button} onClick={handleEditSubmit}>
                            Save Details
                        </button>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default EditModal