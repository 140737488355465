import "./App.css";
import "./Assets/Styling/adminHome.css";
import React, {
  useState,
  useEffect,
  createContext,
  useLayoutEffect
} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./Assets/Styling/main.css";
import "react-tabs/style/react-tabs.css";
import menu from "./Assets/images/Menu.png";
import Login from "./Screens/admin/Login";
import Activity from "./Screens/admin/activity/Activity";
import TabScreen from "./Screens/admin/TabScreen";
import NavbarComponent from "./Component/Navbar";
import Footer from "./Component/Footer";
import AddActivity from "./Screens/admin/activity/AddActivity/AddActivity";
import BottomLine from "./Component/BottomLine";
import AddAttribute from "./Screens/admin/activity/AddAtribute/AddAtribute";
import AddPlaylist from "./Screens/admin/Playlist/AddPlaylist";
import UpdateActivity from "./Screens/admin/activity/UpdateActivity/UpdateActivity";
import Sidebar from "./Component/Sidebar";
import Categories from "./Screens/admin/Category/Categories";
import Activities from "./Screens/admin/Activities/Activities";
import EditAttribute from "./Screens/admin/activity/EditAttribute/EditAttribute";
import Playlists from "./Screens/admin/Playlist/Playlists";
import UpdatePlaylist from "./Screens/admin/Playlist/UpdatePlaylist/UpdatePlaylist";
import Subscriptions from "./Screens/admin/Subscription/Subscriptions";
import UpdateSubscription from "./Screens/admin/Subscription/UpdateSubscription/UpdateSubscription";
import PaymentPage from "./Component/Payment/PaymentPage";
import PaymentStatus from "./Component/Payment/PaymentStatus";
import PaymentFailure from "./Component/Payment/PaymentFailure";
import AddVideo from "./Screens/admin/Playlist/UpdatePlaylist/AddVideo";
import UpdateVideo from "./Screens/admin/Playlist/UpdatePlaylist/UpdateVideo";
import Notifications from "./Screens/admin/Notifications/Notification";
import Blessings from "./Screens/admin/Blessings/Blessings";
import BlessPayment from "./Screens/admin/Payment/blessingpayment";
import ListOfUsers from "./Screens/admin/Reportings/ListOfUsers";
import DetailOfUser from "./Screens/admin/Reportings/DetailOfUser";
import ListOfKids from "./Screens/admin/Reportings/ListOfKids";
import Reports from "./Screens/admin/Reportings/Reports";
import ListOfAllKids from "./Screens/admin/Reportings/ListOfAllKids";
import GetAllSubscription from "./Screens/admin/Reportings/GetAllSubscription";
import AddSubscription from "./Screens/admin/Reportings/AddSubscription";
import ViewUsersForSubscription from "./Screens/admin/Reportings/ViewUsersForSubscription";
import CouponsView from "./Screens/admin/Coupons/CouponsView";
import CouponsAdd from "./Screens/admin/Coupons/CouponsAdd";
import Award from "./Screens/admin/Award/Award";
import { useNavigate } from "react-router";
import TopUpPayment from "./Screens/admin/Payment/topupPayment";
import ImportantLinks from "./Screens/admin/ImportantLinks/ImportantLinks";

import Loader from "./Component/Loader";
import AllUserReports from "./Screens/admin/Reports/AllUserReports";
import AllWallet from "./Screens/admin/Reportings/AllUserWallet";
import DeletedUsers from "./Screens/admin/Reportings/DeletedUser";
import Emailverify from "./Screens/admin/emailverification/emailverifify";
import AxisBankTopupPayment from "./Screens/admin/Payment/AxisbankTopupPayment";
import AllMissions from "./Screens/admin/Reportings/AllMission";
import AllVideos from "./Screens/admin/Reportings/AllVideos";
import AppRedirection from "./Screens/admin/AppRedirections/AppRedirection";

/* Ecommerce Paths */

import AllOrders from "./Screens/admin/Reportings/Order";
import OrderData from "./Screens/admin/Reportings/OrderData";
import AddProduct from "./Screens/admin/ecommerce/AddProduct";
import AddProductVariants from "./Screens/admin/ecommerce/AddProductVariants";
import EditProduct from "./Screens/admin/ecommerce/EditProduct";
import Category from "./Screens/admin/ecommerce/Category";
import Productlist from "./Screens/admin/ecommerce/productlist";
import NewCategory from "./Screens/admin/ecommerce/NewCategory";
import UpdateCategory from "./Screens/admin/ecommerce/CategoryUpdate";
import PrefixTable from "./Screens/admin/PrefixDetails/PrefixTable";
import PrefixForm from "./Screens/admin/PrefixDetails/PrefixForm";
import UniverseTable from "./Screens/admin/UniverseDetails/UniverseTable";
import UniverseForm from "./Screens/admin/UniverseDetails/UniverseForm";
import TaxTypeForm from "./Screens/admin/TaxType/TaxTypeForm";
import TaxTypeTable from "./Screens/admin/TaxType/TaxTypeTable";
import GstForm from "./Screens/admin/GstDetails/GstForm";
import GstTable from "./Screens/admin/GstDetails/GstTable";
import TaxTable from "./Screens/admin/TaxDetails/TaxTable";
import TaxForm from "./Screens/admin/TaxDetails/TaxForm";
//Banner paths
import Banner from "./Screens/admin/Banner/Banner";
import AddBanner from "./Screens/admin/Banner/AddBanner";
//sticker paths
import Sticker from "./Screens/admin/Stickers/Sticker";
import AddSticker from "./Screens/admin/Stickers/AddSticker";
import NewSubCategory from "./Screens/admin/ecommerce/NewSubCategory";
import ListOfDevices from "./Screens/admin/Reportings/ListOfDevices";

import CreateBook from "./Screens/admin/StoryBooks/CreateBook";
import BookList from "./Screens/admin/StoryBooks/BookList";
import EditBook from "./Screens/admin/StoryBooks/EditBook";
import CreatePages from "./Screens/admin/StoryBooks/CreatePages";
import FaqList from "./Screens/admin/StoryBooks/FaqList";
import CreateFaq from "./Screens/admin/StoryBooks/CreateFaq";

import AddGroup from "./Screens/admin/Quizzess/AddGroup";
import QuizGroupList from "./Screens/admin/Quizzess/QuizGroupList";
import UpdateGroup from "./Screens/admin/Quizzess/UpdateGroup";
import QuizList from "./Screens/admin/Quizzess/QuizList";
import UpdateQuizList from "./Screens/admin/Quizzess/UpdateQuizList";
import AddQuiz from "./Screens/admin/Quizzess/AddQuiz";

import PodcastList from "./Screens/admin/Podcasts/PodcastList";
import AddPodcast from "./Screens/admin/Podcasts/AddPodcast";
import PodcastAudios from "./Screens/admin/Podcasts/PodcastAudios";
import UpdatePodCast from "./Screens/admin/Podcasts/UpdatePodcast";
import UpdateAudio from "./Screens/admin/Podcasts/UpdateAudio";

import ParentPodcastList from "./Screens/admin/Podcasts/parentPodCastList";
import AddParentPodcast from "./Screens/admin/Podcasts/AddParentPodcast";
import ParentPodcastAudios from "./Screens/admin/Podcasts/ParentPodcastAudios";
import UpdateParentPodCast from "./Screens/admin/Podcasts/UpdateParentPodcast";
import UpdateParentAudio from "./Screens/admin/Podcasts/UpdateParentAudio";

export const authContext = createContext();
export const searchContext = createContext();

function App() {
  const [visible, setVisible] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(authContext);
  const [searchObj, setSearchObj] = useState({
    searchInput: "",
    filteredArr: [],
    searchArr: []
  });
  const [width, setWidth] = useState(0);
  const [location, setLocation] = useState(window.location.pathname);
  // let navigate = useNavigate();
  const checkIfAuthorised = () => {
    let token = localStorage.getItem("auth_token");
    if (token) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  };
  useEffect(() => {
    let w = window.screen.width;
    setWidth(w);
    checkIfAuthorised();
    setLocation(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <div className="app">
      <authContext.Provider value={[isAuthorized, setIsAuthorized]}>
        <searchContext.Provider value={[searchObj, setSearchObj]}>
          <BrowserRouter>
            {/* {isAuthorized && */}
            <>
              {location === "/payment" ||
              location === "/blessing_payment" ||
              location === "/topup_payment" ||
              location === "/payment-success" ||
              location === "/payment-error" ? (
                <> </>
              ) : (
                width < 600 && (
                  <img
                    src={menu}
                    onClick={() => {
                      setVisible((previous) => !previous);
                    }}
                    style={{
                      width: 30,
                      position: "absolute",
                      top: 10,
                      right: 10,
                      zIndex: 10000,
                      width: 30,
                      margin: 10
                    }}
                  />
                )
              )}
              {location != "/blessings" && isAuthorized && <NavbarComponent />}
              <div style={flexRow}>
                {location != "/blessings" && isAuthorized && (
                  <>
                    {width > 600 ? (
                      <div
                        className="sidebarOuter"
                        style={{ flex: 0.3, backgroundColor: "white" }}
                      >
                        <Sidebar />
                      </div>
                    ) : (
                      <div
                        className="sidebarOuter"
                        style={{
                          width: "100vw",
                          zIndex: 150000,
                          position: "absolute",
                          left: 0,
                          top: 120,
                          backgroundColor: "white"
                        }}
                      >
                        {visible && <Sidebar />}
                      </div>
                    )}
                  </>
                )}
                <div
                  style={{
                    flex: 1,
                    backgroundColor: "#F2F4F6",
                    overflow: "hidden"
                  }}
                >
                  <Routes>
                    {isAuthorized ? (
                      <>
                        <Route exact path="/" element={<Categories />} />
                        <Route
                          exact
                          path="/categories"
                          element={<Categories />}
                        />
                        <Route
                          exact
                          path="/activities"
                          element={<Activities />}
                        />
                        <Route
                          exact
                          path="/home/activity/:id"
                          element={<Activity />}
                        />
                        <Route
                          exact
                          path="/home/activity/update-activity/:id"
                          element={<UpdateActivity />}
                        ></Route>
                        <Route
                          exact
                          path="/home/activity/add-attribute/:id"
                          element={<AddAttribute />}
                        ></Route>
                        <Route
                          exact
                          path="/home/activity/edit-attribute/:id"
                          element={<EditAttribute />}
                        ></Route>
                        <Route
                          exact
                          path="/playlists"
                          element={<Playlists />}
                        ></Route>
                        <Route
                          exact
                          path="/home/add-playlist"
                          element={<AddPlaylist />}
                        ></Route>
                        <Route
                          exact
                          path="/playlist/update/:id"
                          element={<UpdatePlaylist />}
                        ></Route>
                        <Route
                          exact
                          path="/playlist/add-video/:id"
                          element={<AddVideo />}
                        ></Route>
                        <Route
                          exact
                          path="/video/update/:id"
                          element={<UpdateVideo />}
                        ></Route>
                        {/* //Subscriptions */}
                        <Route
                          exact
                          path="/subscriptions"
                          element={<Subscriptions />}
                        ></Route>
                        <Route
                          exact
                          path="/subscription/update/:id"
                          element={<UpdateSubscription />}
                        ></Route>
                        <Route
                          exact
                          path="/payment"
                          element={<PaymentPage />}
                        ></Route>
                        <Route
                          exact
                          path="/blessing_payment"
                          element={<BlessPayment />}
                        ></Route>
                        <Route
                          exact
                          path="/payment-success"
                          element={<PaymentStatus />}
                        ></Route>
                        <Route
                          exact
                          path="/payment-error"
                          element={<PaymentFailure />}
                        ></Route>
                        <Route
                          exact
                          path="/notifications"
                          element={<Notifications />}
                        ></Route>
                        <Route
                          exact
                          path="/importantLinks"
                          element={<ImportantLinks />}
                        ></Route>
                        <Route
                          exact
                          path="/topup_payment"
                          element={<TopUpPayment />}
                        ></Route>
                        <Route
                          exact
                          path="/blessings"
                          element={<Blessings />}
                        ></Route>
                        <Route
                          exact
                          path="/couponsView"
                          element={<CouponsView />}
                        ></Route>
                        <Route exact path="/awards" element={<Award />}></Route>

                        <Route
                          exact
                          path="/reportings"
                          element={<Reports />}
                        ></Route>
                        <Route
                          exact
                          path="/viewParents"
                          element={<ListOfUsers />}
                        ></Route>
                        <Route
                          exact
                          path="/viewDeviceDetails"
                          element={<ListOfDevices />}
                        ></Route>
                        <Route
                          exact
                          path="/viewParents/:id"
                          element={<DetailOfUser />}
                        ></Route>
                        <Route
                          exact
                          path="/viewAllKids"
                          element={<ListOfAllKids />}
                        ></Route>

                        <Route
                          exact
                          path="/addSubscription"
                          element={<AddSubscription />}
                        ></Route>
                        <Route
                          exact
                          path="/viewAllSubscription"
                          element={<GetAllSubscription />}
                        ></Route>
                        <Route
                          exact
                          path="/ViewSubscribedUsers/:id"
                          element={<ViewUsersForSubscription />}
                        ></Route>
                        <Route
                          exact
                          path="/viewKids/:id"
                          element={<ListOfKids />}
                        ></Route>
                        <Route
                          exact
                          path="/viewAllUsers"
                          element={<AllUserReports />}
                        ></Route>
                        <Route
                          exact
                          path="/Allwallet"
                          element={<AllWallet />}
                        ></Route>
                        <Route
                          exact
                          path="/DeletedUsers"
                          element={<DeletedUsers />}
                        ></Route>

                        <Route
                          exact
                          path="/AllMissions"
                          element={<AllMissions />}
                        ></Route>
                        <Route
                          exact
                          path="/AllVideos"
                          element={<AllVideos />}
                        ></Route>
                        <Route
                          exact
                          path="/ecommerce/orders"
                          element={<AllOrders />}
                        ></Route>

                        <Route
                          exact
                          path="/ecommerce/orderData/:id"
                          element={<OrderData />}
                        ></Route>

                        <Route
                          exact
                          path="/ecommerce/category"
                          element={<Category />}
                        ></Route>
                        <Route
                          exact
                          path="/ecommerce/product"
                          element={<Productlist />}
                        ></Route>
                        <Route
                          exact
                          path="/ecommerce/product/addproduct"
                          element={<AddProduct />}
                        ></Route>
                        <Route
                          exact
                          path="/ecommerce/product/:id/variants"
                          element={<AddProductVariants />}
                        ></Route>
                        <Route
                          exact
                          path="/ecommerce/product/:id"
                          element={<EditProduct />}
                        ></Route>
                        <Route
                          exact
                          path="/ecommerce/category/addcategory/"
                          element={<NewCategory />}
                        ></Route>
                        <Route
                          exact
                          path="/ecommerce/category/addsubcategory/:id"
                          element={<NewSubCategory />}
                        ></Route>

                        <Route
                          exact
                          path="/ecommerce/category/updateCategory/:id"
                          element={<UpdateCategory />}
                        ></Route>

                        {/* banner */}
                        <Route
                          exact
                          path="/banner"
                          element={<Banner />}
                        ></Route>
                        <Route
                          exact
                          path="/banner/addBanner"
                          element={<AddBanner />}
                        ></Route>

                        <Route
                          exact
                          path="/stickers"
                          element={<Sticker />}
                        ></Route>
                        <Route
                          exact
                          path="/stickers/addsticker"
                          element={<AddSticker />}
                        ></Route>

                        <Route
                          exact
                          path="/taxprefix-table"
                          element={<PrefixTable />}
                        ></Route>

                        <Route
                          exact
                          path="/add-taxprefix"
                          element={<PrefixForm />}
                        ></Route>

                        <Route
                          exact
                          path="/universe-table"
                          element={<UniverseTable />}
                        ></Route>

                        <Route
                          exact
                          path="/add-universetable"
                          element={<UniverseForm />}
                        ></Route>

                        <Route
                          exact
                          path="/add-taxtype"
                          element={<TaxTypeForm />}
                        ></Route>

                        <Route
                          exact
                          path="/taxtype-table"
                          element={<TaxTypeTable />}
                        ></Route>

                        <Route
                          exact
                          path="/add-gstDetails"
                          element={<GstForm />}
                        ></Route>

                        <Route
                          exact
                          path="/gst-details"
                          element={<GstTable />}
                        ></Route>

                        <Route
                          exact
                          path="/add-taxDetails"
                          element={<TaxForm />}
                        ></Route>

                        <Route
                          exact
                          path="/tax-details"
                          element={<TaxTable />}
                        ></Route>

                        <Route
                          exact
                          path="/storybooks/creatBook"
                          element={<CreateBook />}
                        ></Route>

                        <Route
                          exact
                          path="/storybooks/bookList"
                          element={<BookList />}
                        ></Route>

                        <Route
                          exact
                          path="/storybooks/updateBook/:id"
                          element={<EditBook />}
                        ></Route>

                        <Route
                          exact
                          path="/storybooks/book/:id/pages"
                          element={<CreatePages />}
                        ></Route>

                        <Route
                          exact
                          path="/storybooks/faqList"
                          element={<FaqList />}
                        ></Route>

                        <Route
                          exact
                          path="/storybooks/createFaq"
                          element={<CreateFaq />}
                        ></Route>

                        <Route
                          exact
                          path="/quizzess/addGroup"
                          element={<AddGroup />}
                        ></Route>

                        <Route
                          exact
                          path="/quizzess/quizGroup"
                          element={<QuizGroupList />}
                        ></Route>

                        <Route
                          exact
                          path="/quizzess/updateGroup/:id"
                          element={<UpdateGroup />}
                        ></Route>

                        <Route
                          exact
                          path="/quizzess/quizList"
                          element={<QuizList />}
                        ></Route>

                        <Route
                          exact
                          path="/quizzess/updatequizList/:id"
                          element={<UpdateQuizList />}
                        ></Route>

                        <Route
                          exact
                          path="/quizzess/addQuiz"
                          element={<AddQuiz />}
                        ></Route>

                        <Route
                          exact
                          path="/podcasts/podcastList"
                          element={<PodcastList />}
                        ></Route>

                        <Route
                          exact
                          path="/podcasts/parentPodCastList"
                          element={<ParentPodcastList />}
                        ></Route>

                        <Route
                          exact
                          path="/podcasts/newPodCast"
                          element={<AddPodcast />}
                        ></Route>

                        <Route
                          exact
                          path="/podcasts/addParentPodcast"
                          element={<AddParentPodcast />}
                        ></Route>

                        <Route
                          exact
                          path="/podcasts/updatePodcast/:id"
                          element={<UpdatePodCast />}
                        ></Route>

                        <Route
                          exact
                          path="/podcasts/updateParentPodcast/:id"
                          element={<UpdateParentPodCast />}
                        ></Route>

                        <Route
                          exact
                          path="/podcasts/addAudio/:id"
                          element={<PodcastAudios />}
                        ></Route>

                        <Route
                          exact
                          path="/podcasts/parentaddAudio/:id"
                          element={<ParentPodcastAudios />}
                        ></Route>

                        <Route
                          exact
                          path="/podcasts/:id/audio/:id"
                          element={<UpdateAudio />}
                        ></Route>

                        <Route
                          exact
                          path="/podcasts/:id/parentaudio/:id"
                          element={<UpdateParentAudio />}
                        ></Route>
                      </>
                    ) : (
                      <>
                        <Route exact path="/" element={<Login />} />
                        <Route
                          exact
                          path="/blessings"
                          element={<Blessings />}
                        ></Route>

                        <Route
                          exact
                          path="/payment"
                          element={<PaymentPage />}
                        ></Route>
                        <Route
                          exact
                          path="/blessing_payment"
                          element={<BlessPayment />}
                        ></Route>
                        <Route
                          exact
                          path="/topup_payment"
                          element={<TopUpPayment />}
                        ></Route>
                        <Route
                          exact
                          path="/payment-success"
                          element={<PaymentStatus />}
                        ></Route>
                        <Route
                          exact
                          path="/payment-error"
                          element={<PaymentFailure />}
                        ></Route>
                        <Route
                          exact
                          path="/verify/user/email/:token"
                          element={<Emailverify />}
                        ></Route>
                        <Route
                          exact
                          path="/axis/payment/:amount/:merchantTxnId/:customerId/:customerName/:customerEmailId/:customerMobileNo/:customerPIN"
                          element={<AxisBankTopupPayment />}
                        ></Route>
                        <Route
                          exact
                          path="/app/downloads"
                          element={<AppRedirection />}
                        ></Route>
                      </>
                    )}
                  </Routes>
                </div>
              </div>
            </>
            {/* } */}
            <BottomLine />
          </BrowserRouter>
        </searchContext.Provider>
      </authContext.Provider>
    </div>
  );
}

const flexRow = {
  display: "flex",
  flexDirection: "row"
};

export default App;
