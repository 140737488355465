import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Swal from 'sweetalert2'
import { getAllParents, getAllParentsByDate } from '../../../services/reports'
import styles from './activity.module.css'
import ReportsExportsModels from './ReportsExportsModels';


const AllUserReports = () => {
    const [parentsArr, setParentsArr] = useState([])
    const [showExportModal, setExportModal] = useState(false);
    let navigate = useNavigate()
    let id = useParams()

    const handleShowExportMod = () => {
        setExportModal(!showExportModal);
    }

    const getPlaylists = async () => {
        try {
            let { data: res } = await getAllParents()
            if (res.success == true) {
                setParentsArr(res?.data)
            }
        } catch (error) {
            console.error(error)
            if (error?.response?.data?.message) {
                Swal.fire(error.response.data.message)
            } else {
                Swal.fire(error?.message, "this is errore");
            }
        }
    }





    useEffect(() => {
        getPlaylists()
        // getActivityByCategoryId();
    }, [])


    const TableListElement = () => {
        return (
            <table id='resultTable' cellspacing="100" style={{cellPadding: 100}} width="100%">
                <thead id='first' className='table-title'>
                    <tr style={{padding:"10px"}}>
                        <th>Parent Name</th>
                        <th>Phone Number</th>
                        <th>Kid Name</th>
                        <th>Kid Age</th>
                        <th>User Creation Date</th>
                        <th>Subscription Plan Start Date</th>
                        <th>Subscription Plan Valid Till</th>
                        <th>Coupon Applied</th>
                        {/* <th>Referral Code</th> */}
                        <th>Parent Create</th>
                        <th>Parent Update</th>
                        <th>Kid Insert Date</th>
                        <th>Kid Update Date</th>
                        <th>Wallet User Id</th>
                    </tr>
                </thead>
                <tbody>
                    {parentsArr.map((el, index) => (
                        <tr key={index}>
                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                        {el?.parent?.firstName} {el?.parent?.lastName}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                        {el?.parent?.phone}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                        {el?.kids?.firstName}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                        {el?.kids?.age}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                        {new Date(el?.parent?.createdAt).toDateString()}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                        {new Date(el?.subscription?.createdAt).toDateString()}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                    {new Date(el?.subscription?.validTill).toDateString()}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                        {el?.subscription?.couponObj?.name}
                                    </p>
                                </div>
                            </td>
                            {/* <td>
                                <div>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                    {el?.parent?.referalCode}
                                    </p>
                                </div>
                            </td> */}
                            <td>
                                <div>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                        {new Date(el?.parent?.updatedAt).toDateString()}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                        {new Date(el?.parent?.createdAt).toDateString()}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                    {new Date(el?.kids?.createdAt).toDateString()}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                    {new Date(el?.kids?.updatedAt).toDateString()}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                    {el?.parent?.favcyUserId}
                                    </p>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    return (
        <div className='home-outer'>
         <ReportsExportsModels show={showExportModal} handleClose={handleShowExportMod}/>
            <div
                className={'container col playlist-outer pt-5 ' + styles.activityInnner}
            >
                <div className='row'>
                    {/* <h2>Category Name</h2> */}
                    <div style={{ padding: '8rem' }} className='col-md'>
                        <div className='row'>
                            <h1 style={{ padding: 0 }} className={styles.screenTitle}>
                                {parentsArr?.name}
                            </h1>
                            <div className='col playlistTitle p-0'>
                                <h3 style={{ padding: 0 }}>List of all Users</h3>
                            </div>
                            {/* <div className='col playlistTitle p-0' style={{ alignItems: "flex-end", justifyContent: "flex-end", display: "flex" }}>
                                <h3 style={{ padding: 0, width: "max-content" }}>Total Parents : {parentsArr.length}</h3>
                            </div> */}
                            <div className='col playlistTitle p-0' style={{ alignItems: "flex-end", justifyContent: "flex-end", display: "flex" }}>
                                <button
                                    className="addPlaylistBtn primaryBtn"
                                    onClick={() => handleShowExportMod()}  
                                    >
                                    Export Report
                                </button>
                            </div>
                        </div>
                        <div className='playlistTable'>
                            {/* {ActivitiesByCatIdInfo.loading === true ? (
                                      null
                                  ) : activitiesState.data.length === 0 ? (<p style={{color: "red"}}>no activities found</p>) : ( */}
                            <div className='row tableMain' style={{overflow: 'auto', height: '100vh'}}>
                                <TableListElement />
                            </div>
                            {/* )} */}
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default AllUserReports;
