import React, { useEffect } from 'react'

export default function PaymentFailure() {
    useEffect(() => {
        // window.location.href = "/"
    }, [])
    return (
        <div>
            <section id="sign_in_main">
                <div className="top_image">
                </div>
                <div className="container">
                    <div className="sign_in_main_div">
                        <h3 className="cokieTxt text-center">Payment Unsuccessful</h3>
                        <div  >
                            <form>
                                <div className="row">
                                    <h5 className="mt-5 cokieTxt text-center">Thank you for choosing us !!</h5>
                                    <button className="btn mt-5 btn-danger">Failed</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
