import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";
import GroupImg from "../../../Assets/images/Group.png";
import pigImg from "../../../Assets/images/mascot.png";
import { createAwards, deleteSingleAwards, getAllAwards } from "../../../services/Award";
import { getAllCategories } from "../../../services/category";
import styles from "./activity.module.css";

export default function Award() {
    const [showAwardAddModel, setShowAwardAddModel] = useState(false);
    const [showAwardDeleteModal, setShowAwardDeleteModal] = useState(false);
    const [AwardArr, setAwardArr] = useState([]);
    const [name, setName] = useState("");
    const [value, setValue] = useState("");
    const [file, setFile] = useState(new Date);
    const [selectedCategory, setSelectedCategory] = useState(false);
    const [selectedAward, setSelectedAward] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [categoryArr, setCategoryArr] = useState([]);
    let navigate = useNavigate();
    let id = useParams();


    const getAwardFromDB = async () => {
        try {
            let { data: res } = await getAllAwards();
            console.log(res.data, "Awards");
            if (res.success == true) {
                setAwardArr(res?.data);
            }
        } catch (error) {
            console.error(error);
            if (error?.response?.data?.message) {
                Swal.fire(error.response.data.message);
            } else {
                Swal.fire(error?.message);
            }
        }
    };

    const getCategoriesFromDB = async () => {
        try {
            let { data: res } = await getAllCategories();
            // console.log(res.data, "Awards");
            if (res.success == true) {
                setCategoryArr(res?.data);
            }
        } catch (error) {
            console.error(error);
            if (error?.response?.data?.message) {
                Swal.fire(error.response.data.message);
            } else {
                Swal.fire(error?.message);
            }
        }
    };

    const handleAwardAdd = async () => {
        try {

            if (
                name != "" &&
                file != "" &&
                selectedCategory != ""
            ) {
                // let obj = {
                //     name,
                //     file,
                //     activityId,
                // };
                let formData = new FormData()
                formData.append("name", name);
                formData.append("file", file);
                formData.append("categoryId", selectedCategory);
                const { data: res } = await createAwards(formData);
                if (res.success) {
                    console.log("this is data: ", res.data);
                    Swal.fire(res.message);
                    getAwardFromDB();
                    setShowAwardAddModel(false);
                }
            }
            else {
                Swal.fire("Please Fill all fields");
            }

        } catch (error) {
            console.error(error);
            if (error?.response?.data?.message) {
                Swal.fire(error.response.data.message);
            } else {
                Swal.fire(error?.message);
            }
        }
    };
    const clearstates = () => {
        setName("");
    };

    useEffect(() => {
        getAwardFromDB();
        getCategoriesFromDB()
    }, []);

    const handleDeleteModalTrigger = () => {
        if (selectedAward.name) {
            setShowAwardDeleteModal(true);
        } else {
            Swal.fire("No category selected to perform this action on");
        }
    };

    const handleAwardDelete = async () => {
        try {
            let { data: res } = await deleteSingleAwards(selectedAward?._id);
            console.log(res.data);
            if (res.success == true) {
                Swal.fire(res.message);
                getAwardFromDB();
                setShowAwardDeleteModal();
            }
        } catch (error) {
            console.error(error);
        }
    };



    const TableListElement = () => {
        return (
            <table id="resultTable">
                <thead id="first" className="table-title">
                    <tr>
                        <th>Award Name</th>
                        <th>Award Image</th>
                        <th>Category</th>
                    </tr>
                </thead>
                <tbody>
                    {AwardArr.map((el, index) => (
                        <tr key={index}>
                            <td>
                                <div className="d-flex">
                                    <input
                                        onChange={() => console.log(el)}
                                        onClick={() => {
                                            setSelectedAward(el);
                                        }}
                                        type="radio"
                                        checked={selectedAward._id == el._id}
                                    />
                                    <p className="tableInputTxt" type="text" name="column_1">
                                        {el?.name}
                                    </p>
                                </div>

                            </td>
                            <td>
                                <div className="d-flex">
                                    <img src={el?.imageUrl} style={{ height: 100, width: 100 }} alt="" />
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <p className="tableInputTxt" type="text" name="column_1">
                                        {el?.categoryObj?.name}
                                    </p>
                                </div>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="home-outer">
            <div
                className={"container col playlist-outer pt-5 " + styles.activityInnner}
            >
                <div className="row">
                    {/* <h2>Category Name</h2> */}
                    <div style={{ padding: "3rem 8rem" }} className="col-md">
                        <div className="row">
                            <div className="col playlistTitle p-0">
                                <h3 style={{ padding: 0 }}>List of all Awards</h3>
                            </div>
                            <div className="col-lg-3 p-0">
                                <div className="icon-outer">
                                    <img
                                        onClick={() => handleDeleteModalTrigger()}
                                        className="tableIcon"
                                        src={GroupImg}
                                        alt="delete"
                                    />
                                </div>
                            </div>
                            <Modal
                                show={showAwardDeleteModal}
                                onHide={() => setShowAwardDeleteModal(false)}
                            >
                                <div className="modelMain">
                                    <Modal.Body className="modelBody ">
                                        <div className="d-flex">
                                            <div className="row">
                                                <div className="col d-flex ">
                                                    <h3 className="deletePlaylistTitle">
                                                        Are you sure you want to delete this Award?
                                                    </h3>
                                                </div>
                                                <div className="col modelFooter mt-4">
                                                    <button
                                                        style={{ width: "160px" }}
                                                        className="mainButton modelBtn"
                                                        variant="secondary"
                                                        onClick={handleAwardDelete}
                                                    >
                                                        Yes, remove it
                                                    </button>
                                                    <button
                                                        className="mainButton modelBtn"
                                                        style={{
                                                            backgroundColor: "#AA23AD",
                                                            color: "#F2F4F6",
                                                            width: "160px",
                                                        }}
                                                        variant="primary"
                                                        onClick={() => setShowAwardDeleteModal(false)}
                                                    >
                                                        No, i just clicked it
                                                    </button>
                                                </div>
                                            </div>
                                            <img width={124} src={pigImg} alt="" />
                                        </div>
                                    </Modal.Body>
                                </div>
                            </Modal>
                        </div>
                        <div className="playlistTable">
                            {/* {ActivitiesByCatIdInfo.loading === true ? (
                                      null
                                  ) : activitiesState.data.length === 0 ? (<p style={{color: "red"}}>no activities found</p>) : ( */}
                            <div className="row tableMain">
                                <TableListElement />
                            </div>
                            {/* )} */}
                        </div>
                        <button
                            className="addPlaylistBtn primaryBtn"
                            onClick={() => setShowAwardAddModel(true)}
                        >
                            Add Award
                        </button>
                    </div>
                    <Modal show={showAwardAddModel} onHide={() => setShowAwardAddModel(false)}>
                        <div className="modelMain" style={{ marginTop: 180 }}>
                            <Modal.Body style={{}} className="modelBody">
                                <div className="row container m-2">
                                    <label
                                        className="primaryText"
                                        style={{ marginBottom: 20, fontSize: "25px" }}
                                    >
                                        Add Award
                                    </label>

                                    <div className="col-12 catName m-0">
                                        <label>Award Name:</label>
                                        <input
                                            className="catInput"
                                            placeholder="Type Award Name Here"
                                            required
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        ></input>
                                    </div>

                                    <div className="col-12 catName m-0 ">
                                        <label>Award Image: </label>
                                        <input
                                            className="catInput"
                                            required
                                            id={file}
                                            type="file"
                                            accept="image/png, image/gif, image/jpeg"
                                            placeholder={file?.name}
                                            onChange={(e) => { console.log(e.target.files[0]); setFile(e.target.files[0]) }}
                                        ></input>
                                        <label style={{ marginBottom: 15 }} htmlFor="file">Selected File : {file.name ? file.name : "No file selected"}</label>
                                    </div>

                                    <div className="col-12 catName m-0 ">
                                        <label>Category for which the award is for</label>
                                        <div className="row">
                                            {
                                                categoryArr.map(el => {
                                                    return (
                                                        <div className="col-6" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <input
                                                                name="IsValidOncePerUser"
                                                                className="catInput"
                                                                required
                                                                type="radio"
                                                                checked={selectedCategory == el._id}
                                                                onChange={(e) => setSelectedCategory(el._id)}
                                                            />
                                                            <div style={{ marginLeft: 10, marginRight: 10 }}>{el.name}</div>
                                                        </div>
                                                    )
                                                })

                                            }
                                        </div>

                                    </div>
                                    {/* <div className="col-12 catName m-0 ">
                                        <label>Is Valid Once Per User: </label>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <input
                                                name="IsValidOncePerUser"
                                                className="catInput"
                                                required
                                                type="radio"
                                                value={isValidOncePerUser}
                                                checked={isValidOncePerUser == true}
                                                onChange={(e) => setIsValidOncePerUser(true)}
                                            />
                                            <div style={{ marginLeft: 10, marginRight: 10 }}>Yes</div>
                                            <input
                                                name="IsValidOncePerUser"
                                                className="catInput"
                                                required
                                                type="radio"
                                                value={isValidOncePerUser}
                                                checked={isValidOncePerUser == false}
                                                onChange={(e) => setIsValidOncePerUser(false)}
                                            />
                                            <div style={{ marginLeft: 10, marginRight: 10 }}>No</div>
                                        </div>
                                    </div>
                                    <div className="col-12 catName m-0 ">
                                        <label>Discount Type: </label>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <input
                                                name="discountType"
                                                className="catInput"
                                                required
                                                type="radio"
                                                checked={discountType == "FLAT"}
                                                onChange={(e) => setDiscountType("FLAT")}
                                            />
                                            <div style={{ marginLeft: 10, marginRight: 10 }}>Flat Off</div>
                                            <input
                                                name="discountType"
                                                className="catInput"
                                                required
                                                type="radio"
                                                checked={discountType == "PERCENTAGE"}
                                                onChange={(e) => setDiscountType("PERCENTAGE")}
                                            />
                                            <div style={{ marginLeft: 10, marginRight: 10 }}>Percentage Off</div>
                                        </div>
                                        <div className="col-12 catName m-0 ">
                                            <label>Value: </label>
                                            <input
                                                className="catInput"
                                                placeholder="Type Value Here"
                                                required
                                                type="number"
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                            ></input>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="modelFooter justify-content-center">
                                    <button
                                        className="mainButton modelBtn"
                                        variant="secondary"
                                        onClick={() => {
                                            setShowAwardAddModel(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="mainButton modelBtn"
                                        variant="primary"
                                        onClick={handleAwardAdd}
                                    >
                                        Save
                                    </button>
                                </div>
                            </Modal.Body>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    );
}
