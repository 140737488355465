import API from "../axios/api";
import axios from "axios";

const BASE_LINK = `${API.BASE_LINK}/UserPlaylistVideo`;


export const getAllVideos = async (id, formData) => {
    return await axios.get(`${BASE_LINK}/getAllVideos`);
};
export const getAllVideosbydate = async (formData) => {
    return await axios.post(`${BASE_LINK}/getAllVideos/bydate`,formData);
};