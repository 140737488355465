import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';

function Loader ({show}) {

    const [loading, setLoading] = useState(show);

  return (
    <>
    { loading ? 
    <div className='loader-container'>
        <Spinner animation="border" role="status" styles={{ zIndex: '101'}} size="md" variant="light">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    </div>
    :
    ""
    }
    </>
  )
}

export default Loader;