import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import styles from "../ecommerce/ecommerce.module.css";
import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import { FaDatabase } from "react-icons/fa6";
import Switch from "@mui/material/Switch";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "react-bootstrap/Modal";

import {
  getallBooks,
  addObjective,
  getBookObjects,
  bookStatus
} from "../../../services/StoryBooks/books";

const BookList = () => {
  const navigate = useNavigate();
  const [booklist, setbooklist] = useState([]);
  const [checked, setchecked] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [objPop, setObjPop] = useState(false);
  const [objData, setObjData] = useState([]);

  useEffect(() => {
    getproduct();
  }, [checked]);

  const getproduct = async () => {
    let { data: res } = await getallBooks();
    setbooklist(res.data);
  };

  // console.log(booklist, "booklist");

  const addObjectives = (data) => {
    // console.log(data, "data");
    setActiveId(data?._id);
    fetchObjective(data?._id);
    setObjPop(true);
  };

  const handleChangeSwitch = async (id) => {
    let { data: res } = await bookStatus(id);
    console.log("checked value", res.data);
    setchecked(!checked);
  };

  const sendEditBook = (row) => {
    navigate(`/storybooks/updateBook/${row._id}`, { state: row });
  };

  const fetchObjective = async (id) => {
    // console.log("i am runnign vr");
    let res = await getBookObjects(id);
    if (res.status == 200) {
      console.log(res.data.data, "res data from get objective");

      setObjData(res.data.data);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <p className={styles.headertext}>Books List</p>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end"
            }}
          >
            <Link to="/storybooks/creatBook">
              <button className="button2">Add New Book</button>
            </Link>
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Book Name</TableCell>
                <TableCell align="center">Book Img</TableCell>
                <TableCell align="center">Moral</TableCell>
                <TableCell align="center">Total Pages</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {booklist?.map((row, index) => (
                <TableRow
                  style={{ backgroundColor: "white" }}
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.bookname}</TableCell>
                  <TableCell align="center">
                    <img
                      src={row.imagePath}
                      alt={row.product_name}
                      height="40"
                      width="50"
                    />
                  </TableCell>

                  <TableCell align="center">{row.moral}</TableCell>
                  <TableCell align="center">4</TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={row.status}
                      onChange={() => handleChangeSwitch(row._id)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <div>
                      <Link to={`/storyBooks/book/${row?._id}/pages`}>
                        <FaPlus className="product-update product-add" />
                      </Link>
                      <MdDelete
                        // onClick={() => delModelHandle(row._id)}
                        className="product-delete"
                      />

                      <BiEdit
                        onClick={() => {
                          sendEditBook(row);
                        }}
                        className="product-update"
                      />
                      <FaDatabase
                        onClick={() => {
                          addObjectives(row);
                        }}
                        className="product-delete"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <EditModel
        show={objPop}
        handleShow={() => setObjPop(!objPop)}
        activeId={activeId}
        handleSubmit={addObjective}
        objData={objData}
      />
    </>
  );
};

export default BookList;

const EditModel = ({ show, handleShow, activeId, handleSubmit, objData }) => {
  console.log(activeId, "activeId", objData);

  const [objectivesFields, setObjectivesFields] = useState([]);

  const objHandleFormChange = (event, index) => {
    console.log(event, index);
    let data = [...objectivesFields];
    data[index][event.target.name] = event.target.value;
    setObjectivesFields(data);
    console.log("childFormFields", objectivesFields);
  };

  const runaddFn = () => {
    console.log("i am running");

    if (objData.length > 0) {
      setObjectivesFields([...objectivesFields, objData]);

      //   addObjectiveFields();
    }
  };

  const addObjectiveFields = (e) => {
    e.preventDefault();
    let object = {
      objectiveName: ""
    };
    setObjectivesFields([...objectivesFields, object]);
  };

  const removeFields = (index) => {
    let data = [...objectivesFields];
    data.splice(index, 1);
    setObjectivesFields(data);
  };

  const editSubmitHandler = async () => {
    console.log(objectivesFields, "objectivesFields");
    let { data: res } = await handleSubmit(activeId, objectivesFields);
    console.log("res", res);
    if (res.status) {
      handleShow(false);
    }
  };

  useEffect(() => {
    runaddFn();
  }, []);

  return (
    <>
      <Modal show={show} onHide={() => handleShow(false)}>
        <div className="modelMain  modal-dialog-centered ">
          <Modal.Body className="modelBody ">
            <div className="container m-2">
              <div className="catName m-0">
                <label>Add Objectives:</label>
                {objectivesFields.map((data, index) => {
                  return (
                    // <>
                    <div key={index} className="objective-div">
                      <input
                        className="catInput"
                        placeholder="Add Objective*"
                        required
                        name="objectiveName"
                        value={data.objectiveName}
                        onChange={(event) => objHandleFormChange(event, index)}
                      ></input>
                      <div className="next-section-heading">
                        <button
                          className="remove-btn"
                          onClick={() => removeFields(index)}
                        >
                          remove
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
              <button className={styles.button} onClick={addObjectiveFields}>
                Add Objective
              </button>
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                  handleShow(false);
                }}
              >
                Cancel
              </button>
              <button
                className="mainButton modelBtn "
                variant="primary"
                onClick={editSubmitHandler}
              >
                Update
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
