import { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import React from 'react'
import styles from "./banner.module.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";

// import GGPLOGO from "../../../Assets/images/new-logo.png";

import DelModal from "./DelModal";
import EditModal from "./EditModal";

import { getallbanner, updateBanner } from "../../../services/banner";




const Banner = () => {

  const [checked,setChecked] =useState(false);

  //get data
  const [bannerList, setBannerList] = useState([])
  const [input, setInput] = useState(false);
  const reload = () => setInput(!input);

  const getBanners = async () => {
    let { data: res } = await getallbanner();
    setBannerList(res.data);
    console.log(res.data);
  }

  // del banner 
  const [activeId, setActiveId] = useState("");
  const handleDel = async (id) => {
    // console.log("Delete", id);
    setActiveId(id);
    setShowDel(!showDel);
  }

  //edit 
  const [activeEditId, setActiveEditId] = useState("");
  const handleEdit = async (id) => {
    // console.log("eidt id", id);
    setActiveEditId(id);
    setShowEdit(!showEdit)
  }

  const [showDel, setShowDel] = useState(false);
  const closeDelModal = () => setShowDel(false);

  const [showEdit, setShowEdit] = useState(false);
  const closeEditModal = () => setShowEdit(false);


  const handlToggleSwitch = async (id,status) => {
   
    console.log(id,"switch id");
    let { data: res } = await updateBanner(id, {"status":!status});
    console.log(res.data);
    setChecked(!checked);
    console.log(!status);
  }

  useEffect(() => {
    getBanners();
  }, [input,checked])
  // console.log(input);

  return (
    <>
      <div className={styles.container} >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div >
            <h2 className={styles.headertext}>Banners</h2>
          </div>
          <div >
            <Link to="/banner/addBanner">
              <button className={styles.button}>Add Banner</button>
            </Link>
          </div>
        </div>
        <TableContainer className={styles.table}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Banner Image</TableCell>
                <TableCell align="center">Valid From</TableCell>
                <TableCell align="center">Valid Till</TableCell>
                <TableCell align="center">Order</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {bannerList?.map((row) => (

                <TableRow
                  style={{ backgroundColor: "white" }}
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {/* Name */}

                  <TableCell align="center">{row.name}</TableCell>


                  <TableCell align="center">
                    <img
                      src={row.imgUrl}
                      alt={row.name}

                      height="40"
                      width="50"
                    />
                  </TableCell>

                  <TableCell align="center"> {row.validFrom.slice(0, 10)}  </TableCell>

                  <TableCell align="center"> {row.validTill.slice(0, 10)}</TableCell>

                  <TableCell align="center">{row.order}</TableCell>
                  <TableCell align="center">
                    <Switch checked={row.status} onClick={() => { handlToggleSwitch(row._id,row.status) }} />
                  </TableCell>

                  <TableCell align="center">
                    <BiEdit className={styles.icons} onClick={() => handleEdit(row._id)} />
                    <MdDelete className={styles.icons} onClick={() => handleDel(row._id)} />
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {showEdit && < EditModal closeEditModal={closeEditModal} activeEditId={activeEditId} reload={reload} />}
      {showDel && < DelModal closeDelModal={closeDelModal} activeId={activeId} reload={reload} />}
    </>
  )
}

export default Banner