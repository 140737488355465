import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import styles from "../ecommerce/ecommerce.module.css";
import { BiEdit } from "react-icons/bi";
import Switch from "@mui/material/Switch";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "react-bootstrap/Modal";

import {
  getAllQuizList,
  addObjective,
  quizStatus
} from "../../../services/StoryBooks/books";

const QuizList = () => {
  const navigate = useNavigate();
  const [faqlist, setFaqlist] = useState([]);
  const [checked, setchecked] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [objPop, setObjPop] = useState(false);

  useEffect(() => {
    getFaqs();
  }, [checked]);

  const getFaqs = async () => {
    let { data: res } = await getAllQuizList();
    setFaqlist(res.data);
  };

  console.log(faqlist, "booklist");

  const addObjectives = (data) => {
    console.log(data, "data");
    setActiveId(data);
    setObjPop(true);
  };

  const handleChangeSwitch = async (id) => {
    let { data: res } = await quizStatus(id);
    console.log("checked value", res.data);
    setchecked(!checked);
  };

  const sendEditQuizList = (row) => {
    navigate(`/quizzess/updatequizList/${row._id}`, { state: row });
  };

  return (
    <>
      <div className={styles.container}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <p className={styles.headertext}>Quizzess List</p>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end"
            }}
          >
            <Link to="/quizzess/addQuiz">
              <button className="button2">Add New Quiz</button>
            </Link>
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Quiz Question</TableCell>
                {/* <TableCell align="center">message</TableCell> */}
                <TableCell align="center">Options</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {faqlist?.map((row, index) => (
                <TableRow
                  style={{ backgroundColor: "white" }}
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.question}</TableCell>

                  <TableCell align="center">{row?.answers?.length}</TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={row.status}
                      onChange={() => handleChangeSwitch(row._id)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <div>
                      <BiEdit
                        onClick={() => {
                          sendEditQuizList(row);
                        }}
                        className="product-update"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <EditModel
        show={objPop}
        handleShow={() => setObjPop(!objPop)}
        activeId={activeId}
        handleSubmit={addObjective}
      />
    </>
  );
};

export default QuizList;

const EditModel = ({ show, handleShow, activeId, handleSubmit }) => {

  console.log(activeId, "active Data");

  console.log(activeId?.question, "activeId");

  const [quesss, setQues] = useState(activeId)

  console.log(quesss, 'quesss');



  const quesValue = {
    ques: activeId.question
  }

  const [formValues, setFormValues] = useState(quesValue);

  console.log(formValues?.ques, 'foem valu ques')


  const handleChangeQues = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  const editSubmitHandler = async () => {
    console.log("objectivesFields");
    let { data: res } = await handleSubmit(activeId, formValues);
    console.log("res", res);
    if (res.status) {
      handleShow(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => handleShow(false)}>
        <div className="modelMain  modal-dialog-centered ">
          <Modal.Body className="modelBody ">
            <div className="container m-2">
              <div className="catName m-0">
                <label>Ques Name:</label>

                <div className="objective-div">
                  <textarea
                    className="catInput"
                    placeholder="Update Ques*"
                    row="10"
                    style={{height: 'auto', minHeight: '150px'}}
                    required
                    name="quesss"
                    value={quesss.question}
                    onChange={handleChangeQues}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                  handleShow(false);
                }}
              >
                Cancel
              </button>
              <button
                className="mainButton modelBtn "
                variant="primary"
                onClick={editSubmitHandler}
              >
                Update
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
