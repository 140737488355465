import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import {
  getRegisteredParents,
  deleteParentById,
  updateUserStatus,
} from "../../../services/User";
import pigImg from "../../../Assets/images/mascot.png";
import styles from "./activity.module.css";

export default function ListOfUsers() {
  const [parentsArr, setParentsArr] = useState([]);
  const [delPopup, setDelPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [page, setPage] = useState([]);
  let navigate = useNavigate();

  const [input, setInput] = useState("");
  const [parentList, setParentList] = useState([]);


  const getPlaylists = async () => {
    try {
      let { data: res } = await getRegisteredParents();
      if (res.success == true) {
        setParentsArr(res?.data);
        setPage(res?.data);
        setParentList(res?.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        // Swal.fire(error?.message, "this is errore");
      }
    }
  };

  useEffect(() => {
    getPlaylists();
  }, []);


  const delModelHandle = (id) => {
    if (!activeId) {
      setActiveId(id);
    } else {
      setActiveId("");
    }
    setDelPopup(!delPopup);
  };

  const deleteParent = async (id) => {
    console.log("deleted Parent", id);

    try {
      let { status } = await deleteParentById(id);
      console.log(status, 'del status');
      if (status === 200) {
        console.log("deleted Parent", id);
        getPlaylists();
        delModelHandle();
      }
    } catch (error) {
      delModelHandle();
      Swal.fire(error?.response?.data?.message, "Error While Deleting Parent");
    }
  };

  const updateStatus = async (e, id) => {
    let res = await updateUserStatus(id, { status: e.target.value });
    if (res.status === 200) {
      getPlaylists();
    }
  };

  const sortUsers = (e) => {
    let newSortData;
    if (e.target.value === "Newest") {
      newSortData = parentsArr.slice().sort((a, b) => {
        return (
          new Date(b.createdAt.slice(0, 10)) -
          new Date(a.createdAt.slice(0, 10))
        );
      });
      setParentsArr(newSortData);
    } else if (e.target.value === "Oldest") {
      newSortData = parentsArr.slice().sort((a, b) => {
        return (
          new Date(a.createdAt.slice(0, 10)) -
          new Date(b.createdAt.slice(0, 10))
        );
      });
      setParentsArr(newSortData);
    } else if (e.target.value === "AtoZ") {
      newSortData = parentsArr.slice().sort((a, b) => {
        return a.firstName.localeCompare(b.firstName);
      });
      setParentsArr(newSortData);
    } else if (e.target.value === "ZtoA") {
      newSortData = parentsArr.slice().sort((a, b) => {
        return b.firstName.localeCompare(a.firstName);
      });
      setParentsArr(newSortData);
    }
  };

  useEffect(() => {
    console.log('search user', parentsArr);
  }, [input]);


  const searchUser = (e) => {
    console.log(e.target.value, 'value searched')
    setInput(e.target.value);
    setParentsArr(
      parentList.filter((parent) => {
        return (
          parent?.phone?.toString()?.startsWith(e.target.value) ||
          parent?.email?.toString()?.startsWith(e.target.value)
        )
      })
    );
  }


  const TableListElement = () => {
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "100vh",
          }}
        >
          <table id="resultTable">
            <thead id="first" className="table-title">
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Registered On</th>
                <th>View Kids</th>
                <th>Delete User</th>
              </tr>
            </thead>
            <tbody>
              {parentsArr.map((el, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex">
                      <p
                        className="tableInputTxt"
                        style={{ marginLeft: "-1em" }}
                        type="text"
                        name="column_1"
                      >
                        {el?.firstName} {el?.lastName}
                      </p>
                    </div>
                  </td>
                  <td>
                    <select
                      name="user_Status"
                      onChange={(e) => updateStatus(e, el._id)}
                      defaultValue={el?.user_Status}
                    >
                      <option value="user">User</option>
                      <option value="developer">Developer</option>
                      <option value="tester">Tester</option>
                      <option value="blocked">Blocked</option>
                    </select>
                  </td>

                  <td>
                    <div className="d-flex">
                      <Link to={`/viewParents/${el._id}`}>
                        <p
                          className="tableInputTxt"
                          style={{ marginLeft: "-1em" }}
                          type="text"
                          name="column_1"
                        >
                          {el?.phone}
                        </p>
                      </Link>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                      <Link to={`/viewParents/${el._id}`}>
                        <p
                          className="tableInputTxt"
                          style={{ marginLeft: "-1em" }}
                          type="text"
                          name="column_1"
                        >
                          {el?.email}
                        </p>
                      </Link>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p
                        className="tableInputTxt"
                        style={{ marginLeft: "-1em" }}
                        type="text"
                        name="column_1"
                      >
                        {new Date(el?.createdAt).toDateString()}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                      <button
                        className="addPlaylistBtn primaryBtn"
                        onClick={() => navigate(`/viewKids/${el?._id}`)}
                      >
                        View Kids
                      </button>
                    </div>
                  </td>
                  <td style={{ paddingRight: "30px", paddingLeft: "30px" }}>
                    <div className={styles.tableImgOuter}>
                      <button
                        className="addPlaylistBtn primaryBtn"
                        onClick={() => delModelHandle(el?._id)}
                      >
                        Delete User
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };


  return (
    <div className="home-outer" style={{ paddingRight: "1.5rem" }}>
      <div
        className={"container col playlist-outer pt-5 " + styles.activityInnner}
      >
        <div style={{ textAlign: "right" }}></div>

        <div
          style={{
            textAlign: "right",
          }}
        >
          <select
            style={{
              margin: "20px",
              borderRadius: "20px",
              padding: "4px 5px",
              border: "3px solid #a32bb4",
              outline: "none",
              cursor: "pointer",
              width: "100px",
            }}
            onChange={sortUsers}
          >
            <option value="" style={{ display: "none" }}>
              Sort
            </option>
            <option value="Oldest">Oldest</option>
            <option value="Newest">Newest </option>
            <option value="AtoZ">A to Z</option>
            <option value="ZtoA">Z to A</option>
          </select>
          <input
            type="search"
            // value={input}
            placeholder="Search Parents"
            onChange={searchUser}
            style={{
              borderRadius: "20px",
              padding: "3px 15px",
              width: "18rem",
              border: "3px solid #a32bb4",
              outline: "none",
            }}
          />
        </div>

        <div className="row">
          {/* <h2>Category Name</h2> */}
          <div
            style={{ paddingLeft: "2.5rem", height: "130vh" }}
            className="col-md"
          >
            <div className="row">
              <h1 style={{ padding: 0 }} className={styles.screenTitle}>
                {parentsArr?.name}
              </h1>
              <div className="col playlistTitle p-0">
                <h3 style={{ padding: 0 }}>List of all Parents</h3>
              </div>
              <div
                className="col playlistTitle p-0"
                style={{
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <h3 style={{ padding: 0, width: "max-content" }}>
                  Total Parents : {parentsArr?.length}
                </h3>
              </div>
            </div>
            <div className="playlistTable">
              <div className="row tableMain" style={{ overflowX: "scroll" }}>
                <TableListElement
                  handleDelModelShow={() => setDelPopup(!delPopup)}
                />
              </div>

              <DelModel
                show={delPopup}
                handleShow={() => setDelPopup(!delPopup)}
                activeDelId={activeId}
                handleSubmit={(x) => deleteParent(x)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const DelModel = ({ show, handleShow, activeDelId, handleSubmit }) => {
  return (
    <>
      <Modal show={show} onHide={() => handleShow()}>
        <div className="modelMain">
          <Modal.Body className="modelBody ">
            <div className="d-flex">
              <div className="row">
                <div className="col d-flex ">
                  <h3 className="deletePlaylistTitle">
                    Are you sure you want to delete this User?
                  </h3>
                </div>
                <div className="col modelFooter mt-4">
                  <button
                    style={{ width: "160px" }}
                    className="mainButton modelBtn"
                    variant="secondary"
                    onClick={() => handleSubmit(activeDelId)}
                  >
                    Delete It
                  </button>
                  <button
                    className="mainButton modelBtn"
                    style={{
                      backgroundColor: "#AA23AD",
                      color: "#F2F4F6",
                      width: "160px",
                    }}
                    variant="primary"
                    onClick={() => handleShow()}
                  >
                    No
                  </button>
                </div>
              </div>
              <img width={124} src={pigImg} alt="Piggy" />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
