import React, { useState, useEffect } from "react";
import { getRegisteredDevices } from "../../../services/User";
import moment from "moment";
import { Link } from "react-router-dom";
import styles from "./activity.module.css";


const ListOfDevices = () => {
  const [devicedetails, setDeviceDetails] = useState([]);
  const [total, setTotal] = useState([]);
  const [input, setInput] = useState("");

  const getDevDet = async () => {
    try {
      let res = await getRegisteredDevices();
      if (res.data.status === "success") {
        console.log("ello");
        console.log(res.data.data, "res for dev");
        setTotal(res.data.data);
        setDeviceDetails(res.data.data);
      }
    } catch (err) {
      console.log(err, "err at dev det");
    }
  };

  useEffect(() => {
    getDevDet();
  }, []);

  const sortDevices = (e) => {
    let newSortData;
    if (e.target.value === "Newest") {
      newSortData = devicedetails.slice().sort((a, b) => {
        return (
          new Date(b.createdAt.slice(0, 10)) -
          new Date(a.createdAt.slice(0, 10))
        );
      });
      setDeviceDetails(newSortData);
    } else if (e.target.value === "Oldest") {
      newSortData = devicedetails.slice().sort((a, b) => {
        return (
          new Date(a.createdAt.slice(0, 10)) -
          new Date(b.createdAt.slice(0, 10))
        );
      });
      setDeviceDetails(newSortData);
    } else if (e.target.value === "Android") {
      // getDevDet();
      newSortData = devicedetails.filter((curelem) => {
        return curelem.platform === e.target.value;
      });
      setDeviceDetails(newSortData);
    }else if (e.target.value === "iOS") {
      // getDevDet();
      console.log(e.target.value, 'target')
      newSortData = devicedetails.filter((curelem) => {
        return curelem.platform === e.target.value;
      });
      setDeviceDetails(newSortData);
    }
  }

  useEffect(() => {
    console.log('search user', devicedetails);
  }, [input]);

  const searchDevice = (e) => {
    console.log(e.target.value, 'value searched')
    setInput(e.target.value);
    setDeviceDetails(
      total.filter((parent) => {
        return (
          parent?.deviceModel?.toString()?.startsWith(e.target.value) ||
          parent?.platform?.toString()?.startsWith(e.target.value)
        )
      })
    );
  }

  const TableListElement = () => {
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "115vh",
          }}
        >
          <table id="resultTable">
            <thead id="first" className="table-title">
              <tr>
                <th>Id</th>
                <th>Manufacturer</th>
                <th>Model</th>
                <th>OS Version</th>
                <th>IP Address</th>
                <th>Reg. Date</th>
              </tr>
            </thead>
            <tbody>
              {devicedetails.map((device, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex">
                      <p
                        className="tableInputTxt"
                        style={{ marginLeft: "-1em" }}
                        type="text"
                        name="column_1"
                      >
                        {device?._id}
                      </p>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex">
                     
                        <p
                          className="tableInputTxt"
                          style={{ marginLeft: "-1em" }}
                          type="text"
                          name="column_1"
                        >
                          {device.deviceManufacturer} <span>({device.platform})</span>
                        </p>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                        <p
                          className="tableInputTxt"
                          style={{ marginLeft: "-1em" }}
                          type="text"
                          name="column_1"
                        >
                          {device?.deviceModel}
                        </p>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                      <p
                        className="tableInputTxt"
                        style={{ marginLeft: "-.51em" }}
                        type="text"
                        name="column_1"
                      >
                        {device.osVersion} <span>({device.deviceType})</span>
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className={styles.tableImgOuter}>
                      <p className="tableInputTxt" type="text" name="column_1">
                      {device?.ipAddress}
                      </p>
                    </div>
                  </td>
                  <td style={{ paddingRight: "10px", paddingLeft: "30px" }}>
                  {new Date(device?.createdAt).toDateString()}
                   
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };


  return (
    <>
      <div className="home-outer" style={{ paddingRight: "1.5rem" }}>
      <div
        className={"container col playlist-outer pt-5 " + styles.activityInnner}
      >
        <div style={{ textAlign: "right" }}></div>
        {/* 
        
        {/*searching and sorting*/}
        <div
          style={{
            textAlign: "right",
          }}
        >
         <select
            style={{
              margin: "20px",
              borderRadius: "20px",
              padding: "4px 5px",
              border: "3px solid #a32bb4",
              outline: "none",
              cursor: "pointer",
              width: "100px",
            }}
            onChange={sortDevices}
          >
            <option value="" style={{ display: "none" }}>
              Sort
            </option>
            <option value="Oldest">Oldest</option>
            <option value="Newest">Newest </option>
            <option value="Android">Android </option>
            <option value="iOS">IOS</option>
          </select>
          <input
            type="search"
            placeholder="Search Devices"
            onChange={searchDevice}
            style={{
              borderRadius: "20px",
              padding: "3px 15px",
              width: "18rem",
              border: "3px solid #a32bb4",
              outline: "none",
            }}
          />
        </div>

        <div className="row">
          {/* <h2>Category Name</h2> */}
          <div
            style={{ paddingLeft: "2.5rem", height: "130vh" }}
            className="col-md"
          >
            <div className="row">
              <h1 style={{ padding: 0 }} className={styles.screenTitle}>
                {devicedetails?.name}
              </h1>
              <div className="col playlistTitle p-0">
                <h3 style={{ padding: 0 }}>List of all Devices</h3>
              </div>
              <div
                className="col playlistTitle p-0"
                style={{
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <h3 style={{ padding: 0, width: "max-content" }}>
                  Total Devices : {devicedetails?.length}
                </h3>
              </div>
            </div>
            <div className="playlistTable">
              <div className="row tableMain" style={{ overflowX: "scroll" }}>
                <TableListElement
                 
                />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ListOfDevices;
