import React, { useEffect, useState, useRef } from "react";
import { TextField, Grid, Modal, MenuItem, Alert } from "@mui/material";
import styles from "../ecommerce/ecommerce.module.css";
import Files from "react-files";
import uploads from "../../../Assets/images/uploads.png";
import { addSubs } from "../../../services/StoryBooks/books";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";

const AddSubscription = () => {
  const navigate = useNavigate();
  const [src, setSrc] = useState();
  const [files, setFiles] = useState({});

  const initialValues = {
    name: "",
    description: "",
    duration: "",
    validity: "",
    isPopular: "false",
    isFree: "false",
    iapandroid: "",
    iapios: "",
    offer: "",
    price: "",
    mrp: ""
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  const submithandler = async (e) => {
    try{
        e.preventDefault();
        // setFormErrors(validate(formValues));
        const formdata = new FormData();
        formdata.append("image", files);
        formdata.append("name", formValues.name);
        formdata.append("description", formValues.description);
        formdata.append("duration", formValues.duration);
        formdata.append("validity", formValues.validity);
        formdata.append("isPopular", formValues.isPopular);
        formdata.append("isFree", formValues.isFree);
        formdata.append("iapandroid", formValues.iapandroid);
        formdata.append("iapios", formValues.iapios);
        formdata.append("offer", formValues.offer);
        formdata.append("price", formValues.price);
        formdata.append("mrp", formValues.mrp);

            // console.log(formValues, 'form Data')

    // return
    // if (formValues.bookname && formValues.moral) {
      console.log("API HIT", formValues);
      let { data: res } = await addSubs(formdata);
      console.log("data", res);
      return
      if (res.status === "success") {
        setTimeout(() => {
          alert("Subscription Added Successfully");
          navigate(`/viewAllSubscription`);
        }, 800);
      }
    // }
    }

    catch(err){
        // console.log(err.response.data.message, 'err at cat')
      Swal.fire(err.response.data.message);
        
    }
  


  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.name) {
      errors.name = "Name is Required";
    }

    if (!values.moral) {
      errors.moral = "Description is Required";
    }
    if (values.moral.length > 150) {
      errors.moral = "Can't Add more than 500 Charatcers";
    }
    return errors;
  };

  return (
    <div className={styles.container}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="name"
            value={formValues.name}
            onChange={handleChange}
            className={styles.inputs}
            label="Name *"
            variant="outlined"
          />
          <small className="text-red">{formErrors.name}</small>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="description"
            value={formValues.description}
            onChange={handleChange}
            className={styles.inputs}
            label="Description*"
            variant="outlined"
          />
          <small className="text-red">{formErrors.description}</small>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="duration"
            value={formValues.duration}
            onChange={handleChange}
            className={styles.inputs}
            label="Duration*"
            variant="outlined"
          />
          <small className="text-red">{formErrors.duration}</small>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="validity"
            value={formValues.validity}
            onChange={handleChange}
            className={styles.inputs}
            label="Validity*"
            variant="outlined"
          />
          <small className="text-red">{formErrors.validity}</small>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-select"
            select
            label="Popular *"
            value={formValues.isPopular}
            name="isPopular"
            onChange={handleChange}
            className={styles.inputs}
          >
            <MenuItem value="false">False</MenuItem>

            <MenuItem value="true">True</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-select"
            select
            label="Free *"
            value={formValues.isFree}
            name="isFree"
            onChange={handleChange}
            className={styles.inputs}
          >
            <MenuItem value="false">False</MenuItem>

            <MenuItem value="true">True</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="iapandroid"
            value={formValues.iapandroid}
            onChange={handleChange}
            className={styles.inputs}
            label="Android IAP*"
            variant="outlined"
          />
          <small className="text-red">{formErrors.iapandroid}</small>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="iapios"
            value={formValues.iapios}
            onChange={handleChange}
            className={styles.inputs}
            label="IAP IOS*"
            variant="outlined"
          />
          <small className="text-red">{formErrors.iapios}</small>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="price"
            value={formValues.price}
            onChange={handleChange}
            className={styles.inputs}
            label="Subs Price*"
            variant="outlined"
          />
          <small className="text-red">{formErrors.price}</small>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="mrp"
            value={formValues.mrp}
            onChange={handleChange}
            className={styles.inputs}
            label="Subs MRP*"
            variant="outlined"
          />
          <small className="text-red">{formErrors.mrp}</small>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="offer"
            value={formValues.offer}
            onChange={handleChange}
            className={styles.inputs}
            label="Subs MRP*"
            variant="outlined"
          />
          <small className="text-red">{formErrors.mrp}</small>
        </Grid>


        <Grid item xs={6}>
          {!files?.id && (
            <div className={styles.img}>
              <Files
                className="files-dropzone"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={[
                  "image/png",
                  "image/jpg",
                  "image/jpeg",
                  "image/webp",
                  "audio/*"
                ]}
                multiple
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <img src={uploads} height="170" width={"100%"} />
              </Files>
            </div>
          )}
          {files?.id && (
            <div className={styles.img}>
              <Files
                className="files-dropzone"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={["image/png", ".pdf", "audio/*"]}
                multiple
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <img src={src} height="170" width="170" />
              </Files>
            </div>
          )}
        </Grid>

        <Grid item xs={12}>
          <button className={styles.button} onClick={submithandler}>
            Save Details
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddSubscription;
