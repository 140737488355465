import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import styles from "../ecommerce/ecommerce.module.css";
import { TextField, Grid } from "@mui/material";
import Files from "react-files";
import uploads from "../../../Assets/images/uploads.png";
import { updateAudio } from "../../../services/Podcasts/podcasts";

const UpdateAudio = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const audioData = location.state;
  const [loading, setloading] = useState(false);
  const [audio, setAudio] = useState({});

  const initialValues = {
    audioname: audioData.audioName,
    description: audioData.description
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [files, setFiles] = useState({});
  const [src, setSrc] = useState();

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const addFile = (audios) => {
    console.log(audios, "lolp");
    setAudio(audios[0]);
    const audio = audios.map((item) => URL.createObjectURL(item));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    // console.log("form vla", formValues);
  };

  const editSubmitHandler = async () => {
    const formdata = new FormData();
    formdata.append("image", files);
    formdata.append("audio", audio);
    formdata.append("audioName", formValues.audioname);
    formdata.append("description", formValues.description);

    if (formValues.audioname && formValues.description) {
      let { data: res } = await updateAudio(audioData?._id, formdata);
      // console.log("res", res);
      if (res.status) {
        console.log(res, "form data send to api");
        // alert(res.message);
        // navigate("/ecommerce/product")
      }
    }
  };

  return (
    <>
      {loading ? (
        <div class="custom-loader"></div>
      ) : (
        <div className={styles.container}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                name="audioname"
                value={formValues.audioname}
                onChange={handleChange}
                className={styles.inputs}
                label="Book Name *"
                variant="outlined"
              />
              <small className="text-red">{formErrors.audioname}</small>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                name="description"
                value={formValues.description}
                onChange={handleChange}
                className={styles.inputs}
                label="Description*"
                variant="outlined"
              />
              <small className="text-red">{formErrors.description}</small>
            </Grid>

            <Grid item xs={6}>
              {!files?.id && (
                <div className={styles.img}>
                  <Files
                    className="files-dropzone"
                    onChange={onFilesChange}
                    onError={onFilesError}
                    accepts={[
                      "image/png",
                      "image/jpg",
                      "image/jpeg",
                      "image/webp"
                    ]}
                    multiple
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <img src={uploads} height="170" width={"100%"} />
                  </Files>
                </div>
              )}
              {files?.id && (
                <div className={styles.img}>
                  <Files
                    className="files-dropzone"
                    onChange={onFilesChange}
                    onError={onFilesError}
                    accepts={["image/png", ".pdf", "audio/*"]}
                    multiple
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <img src={src} height="170" width={"100%"} />
                  </Files>
                </div>
              )}
            </Grid>

            {/* addd audio file here */}

            <Grid item xs={6}>
              {!audio?.id && (
                <div className={styles.img}>
                  <Files
                    className="files-dropzone"
                    onChange={addFile}
                    onError={onFilesError}
                    accepts={["audio/*"]}
                    multiple
                    maxFileSize={30000000}
                    minFileSize={0}
                    clickable
                  >
                    {/* <img src={uploads} height="170" width={"100%"} /> */}
                    <h3
                      style={{
                        height: "160px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      Upload Audio here
                    </h3>
                  </Files>
                </div>
              )}
              {audio?.id && (
                <div className={styles.img}>
                  <Files
                    className="files-dropzone"
                    onChange={addFile}
                    onError={onFilesError}
                    accepts={["audio/*"]}
                    multiple
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    {/* <img src={src} height="170" width="170" /> */}
                    <h3
                      style={{
                        height: "160px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      Audio Uploaded
                    </h3>
                  </Files>
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              <button className={styles.button} onClick={editSubmitHandler}>
                Save Details
              </button>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default UpdateAudio;
