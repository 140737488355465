import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router';
// import { handlePayment } from '../../Services/Parent';
import Loader from '../Loader';

export default function PaymentPage() {
    const id = useParams();
    let navigate = useNavigate();

    const [auth_token, setAuth_token] = useState("");
    const [client, setClient] = useState("");
    const [order_id, setOrder_id] = useState("");
    const [success_callback, setSuccess_callback] = useState("");
    const [error_callback, setError_callback] = useState("");
    const [additionalParams, setadditionalParams] = useState("");
    const [favcyPaymentResponse, setFavcyPaymentResponse] = useState("");
    const [redirect, setRedirect] = useState("0");
    const [currency_type, setCurrency_type] = useState("INR");
    const [provider, setProvider] = useState("razorpay");
    const [amount_in_100, setAmount_in_100] = useState(0);

    useEffect(() => {
        setAuth_token(id.tokenId)
        setClient("ggp");
        setRedirect("O")
        let orderId = `${new Date().getTime()}`
        setOrder_id(orderId)
        setCurrency_type("INR")
        setProvider("razorpay")
        setSuccess_callback("https://app.goodgoodpiggy.com/PaymentStatus");
        setError_callback("https://app.goodgoodpiggy.com/Paymentfailure");
        let paymentObj = localStorage.getItem("addMoneyObj")
        paymentObj = JSON.parse(paymentObj)
        setAmount_in_100(paymentObj.amount * 100)
        if (client != "" && success_callback != "" && error_callback != "" && auth_token != "" && order_id != "") {
            let form = document.getElementById("form");
            form.submit();
        }
    }, [client, success_callback, error_callback, amount_in_100, auth_token])

    return (
        <div>
            <Loader show={true} />
            <form id="form" style={{ display: "none" }} action="https://www.favcy.com/payments/pay" method="POST">
                <input type="text" style={{ display: "none" }} name="additional_params" value='{"brand_name": "Good Good Piggy","description": "You are paying for Good Good Piggy"}' />
                <input type="text" style={{ display: "none" }} class="displayHidden" name="auth_token" value={auth_token} onChange={(e) => setAuth_token(e.target.value)} placeholder="Auth Token" />
                <input type="text" style={{ display: "none" }} class="displayHidden" name="client" value={client} onChange={(e) => setClient(e.target.value)} placeholder="Client" />
                <input type="text" style={{ display: "none" }} class="displayHidden" name="order_id" value={`${order_id}`} onChange={(e) => setOrder_id(e.target.value)} placeholder="Order_Id" />
                <input type="text" style={{ display: "none" }} class="displayHidden" name="success_callback" value={success_callback} onChange={(e) => setSuccess_callback(e.target.value)} placeholder="Success Callback" />
                <input type="text" style={{ display: "none" }} class="displayHidden" name="error_callback" value={error_callback} onChange={(e) => setError_callback(e.target.value)} placeholder="Error Callback" />
                <input type="text" style={{ display: "none" }} class="displayHidden" name="redirect" value={redirect} onChange={(e) => setRedirect(e.target.value)} placeholder="Error Callback" />
                <input type="text" style={{ display: "none" }} class="displayHidden" name="provider" value={provider} onChange={(e) => setProvider(e.target.value)} placeholder="Provider" />
                <input type="text" style={{ display: "none" }} class="displayHidden" name="currency_type" value={currency_type} onChange={(e) => setCurrency_type(e.target.value)} placeholder="currency_type" />
                <input type="text" style={{ display: "none" }} class="displayHidden" name="amount_in_100" value={amount_in_100} onChange={(e) => setAmount_in_100(e.target.value)} placeholder="amount_in_100" />

            </form>
            {/* <button onClick={() => handlePaymentTemp()}>Submit</button> */}

        </div>
    )
}
