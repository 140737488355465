import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import styles from "../ecommerce/ecommerce.module.css";
import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import { FaDatabase } from "react-icons/fa6";
import Switch from "@mui/material/Switch";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

import { getallGroups, groupStatus, getGroupQuizs, addQuizs, getallQuizsForGroups} from "../../../services/StoryBooks/books";

const QuizGroupList = () => {
  const navigate = useNavigate();
  const [booklist, setbooklist] = useState([]);
  const [checked, setchecked] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [objPop, setObjPop] = useState(false);
  const [fetchconnectFaqs, setFetchConnectFaqs] = useState([]);
  const [allfaq, setAllfaq] = useState([]);



  const getproduct = async () => {
    let { data: res } = await getallGroups();
    setbooklist(res.data);
  };

  console.log(booklist, "booklist");

  const handleChangeSwitch = async (id) => {
    let { data: res } = await groupStatus(id);
    console.log("checked value", res.data);
    setchecked(!checked);
  };

  const sendEditBook = (row) => {
    navigate(`/quizzess/updateGroup/${row._id}`, { state: row });
  };

  const connectQuizs = (data) => {
    console.log(data, "data we get from click");
    setActiveId(data?._id);
    getConnectQuizs(data?._id);
    setObjPop(true);
  };

  const getConnectQuizs = async (activeId) => {
    console.log(activeId, 'act id')
    let { data: res } = await getGroupQuizs(activeId);
    console.log("data for faqs", res.data);
    setFetchConnectFaqs(res.data);
  };

  const fetchfaqsforSet = async () => {
    console.log(
      "==================>>>>>>>>>>>>>>><<<<<<<<<<<<<<<===================="
    );
    let { data: res } = await getallQuizsForGroups();
    res?.data?.forEach((element) => {
      element.label = element.question;
      element.value = element._id;
      // console.log(element, "elwement print for reacrh");
    });
    setAllfaq(res.data);
  };

  useEffect(() => {
    getproduct();
  }, [checked]);

  useEffect(() => {
    fetchfaqsforSet();
  }, [objPop]);

  return (
    <>
      <div className={styles.container}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <p className={styles.headertext}>Quiz Group List</p>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end"
            }}
          >
            <Link to="/quizzess/addGroup">
              <button className="button2">Add New Group</button>
            </Link>
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Group Name</TableCell>
                <TableCell align="center">Group Img</TableCell>
                <TableCell align="center">Total Quizzess</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {booklist?.map((row, index) => (
                <TableRow
                  style={{ backgroundColor: "white" }}
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.groupName}</TableCell>
                  <TableCell align="center">
                    <img
                      src={row.imagePath}
                      alt={row.product_name}
                      height="40"
                      width="50"
                    />
                  </TableCell>

                  <TableCell align="center">4</TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={row.status}
                      onChange={() => handleChangeSwitch(row._id)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <div>
                      {/* <Link to={`/storyBooks/book/${row?._id}/pages`}> */}
                      <FaPlus
                        onClick={() => {
                          connectQuizs(row);
                        }}
                        className="product-update product-add"
                      />
                      {/* </Link> */}
                      {/* <MdDelete
                        onClick={() => delModelHandle(row._id)}
                        className="product-delete"
                      /> */}

                      <BiEdit
                        onClick={() => {
                          sendEditBook(row);
                        }}
                        className="product-update product-delete"
                      />
                      {/* <FaDatabase
                        onClick={() => {
                          addObjectives(row);
                        }}
                        className="product-delete"
                      /> */}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <AddFAQ
        show={objPop}
        handleShow={() => setObjPop(!objPop)}
        activeId={activeId}
        allfaq={allfaq}
        handleSubmit={addQuizs}
        fetchconnectFaqs={fetchconnectFaqs}
      />
    </>
  );
};

export default QuizGroupList;

const AddFAQ = ({
    show,
    handleShow,
    activeId,
    allfaq,
    handleSubmit,
    fetchconnectFaqs,
  }) => {
    // console.log(activeId, "activeId");
  
    console.log(fetchconnectFaqs, "allfaq");

  
    const initialValues = {
      faq: [],
    };
    const [formValues, setFormValues] = useState(initialValues);
  
    const handleMultiple = async (name, data) => {
      if (name === "faq") {
        console.log("running if part", data);
        setFormValues({ ...formValues, [name]: data });
      } else {
        console.log("running else part");
      }
    };
  
    const editSubmitHandler = async () => {
      const formdata = new FormData();
      formdata.append(
        "faqs",
        formValues.faq.map((x) => x._id)
      );
      let obj = {
        quizs: formValues.faq.map((x) => x._id)
      }
      console.log("objectivesFields", formValues.faq.map((x) => x._id));
      let { data: res } = await handleSubmit(activeId, obj);
      if (res.status == "success") {
        handleShow(false);
      }
      console.log(res, "res for add fqaqs");
    };
  
    return (
      <>
        <Modal show={show} onHide={() => handleShow(false)}>
          <div className="modelMain  modal-dialog-centered ">
            <Modal.Body className="modelBody ">
              <div className="container m-2">
                <div className="faqs-name">
                  <h5>Quizzes LIST</h5>
                  {fetchconnectFaqs.map((data, index) => {
                    return <p key={index}>{data.question}</p>;
                  })}
                </div>
                <div className="catName m-0">
                  <label>Connect FAQS:</label>
                  <Grid item xs={6}>
                    <Select
                      isMulti
                      onChange={(data) => {
                        handleMultiple("faq", data);
                      }}
                      options={allfaq}
                      value={formValues.faq}
                    />
                    <br />
                  </Grid>
                </div>
              </div>
              <div className="modelFooter justify-content-center">
                <button
                  className="mainButton modelBtn"
                  variant="secondary"
                  onClick={() => {
                    handleShow(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="mainButton modelBtn "
                  variant="primary"
                  onClick={editSubmitHandler}
                >
                  Update
                </button>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </>
    );
  };
  