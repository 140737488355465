import API from "../../axios/api";
import axios from "axios";

const BASE_LINK = `${API.BASE_LINK}`;

export const createPodcast = async (formData) => {
  console.log(formData, "form data");
  return await axios.post(`${BASE_LINK}/podCasts/createnew`, formData);
};

export const createParentPodcast = async (formData) => {
  console.log(formData, "form data");
  return await axios.post(`${BASE_LINK}/podCasts/parent/createnew`, formData);
};

export const getallPods = async () => {
  return await axios.get(`${BASE_LINK}/podCasts/getAllPods`);
};

export const getallParentPods = async () => {
  return await axios.get(`${BASE_LINK}/podCasts/parent/getAllPods`);
};

export const parentPodStatus = async (id) => {
  return await axios.get(`${BASE_LINK}/podCasts/parent/updateStatus/${id}`);
};

export const podStatus = async (id) => {
  return await axios.get(`${BASE_LINK}/podCasts/updateStatus/${id}`);
};

export const addPodAudios = async (formData) => {
  return await axios.post(`${BASE_LINK}/podCasts/createNewAudios`, formData);
};

export const getAllAudios = async (id) => {
  return await axios.get(`${BASE_LINK}/podCasts/getAllAudios/${id}`);
};

export const audioStatus = async (id) => {
  return await axios.get(`${BASE_LINK}/podCasts/audioStatus/${id}`);
};

export const editSerial = async (id, serialNum) => {
  console.log(serialNum, "form data", id);
  return await axios.patch(
    `${BASE_LINK}/podCasts/updateSerial/${id}`,
    serialNum
  );
};

export const updatePodcast = async (id, formData) => {
  console.log(formData, "form data");
  return await axios.patch(`${BASE_LINK}/podCasts/updatepodCast/${id}`, formData);
};

export const updateParentPodcast = async (id, formData) => {
  console.log(formData, "form data");
  return await axios.patch(`${BASE_LINK}/podCasts/parent/updatepodCast/${id}`, formData);
};

export const updateAudio = async (id, formData) => {
  console.log(formData, "form data");
  return await axios.patch(`${BASE_LINK}/podCasts/updateAudio/${id}`, formData);
};

export const addParentPodAudios = async (formData) => {
  return await axios.post(`${BASE_LINK}/podCasts/parent/createNewAudios`, formData);
};

export const getAllParentAudios = async (id) => {
  return await axios.get(`${BASE_LINK}/podCasts/parent/getAllAudios/${id}`);
};

export const parentAudioStatus = async (id) => {
  return await axios.get(`${BASE_LINK}/podCasts/parent/audioStatus/${id}`);
};

export const editParentSerial = async (id, serialNum) => {
  console.log(serialNum, "form data", id);
  return await axios.patch(
    `${BASE_LINK}/podCasts/parent/updateSerial/${id}`,
    serialNum
  );
};


export const updateParentAudio = async (id, formData) => {
  console.log(formData, "form data");
  return await axios.patch(`${BASE_LINK}/podCasts/parent/updateAudio/${id}`, formData);
};
