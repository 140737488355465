import React, { useState, useEffect } from "react";
import { TextField, Grid, MenuItem  } from "@mui/material";
// import TextareaAutosize from "@mui/base/TextareaAutosize";
import { TextareaAutosize } from '@material-ui/core'
import styles from "../ecommerce/ecommerce.module.css";
import { useNavigate } from "react-router";
import { addGstDetails, getUniverseData } from "../../../services/GstDetails/gstDetails";

const GstForm = () => {
  const navigate = useNavigate();
  const initialValues = { hsnNo: "", natureOfTransaction: "", isNonGstGoods: "", description: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [selectData, SetSelectData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  const getTransactionData = async () => {
    let { data: res } = await getUniverseData("entityCode=gstTable-NatureOfTrans&&status=true");
    SetSelectData(res.data);
  };

  useEffect(() => {
    getTransactionData();
  }, []);

  const submithandler = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (formValues.hsnNo && formValues.isNonGstGoods) {
      let { data: res } = await addGstDetails(formValues);
      console.log("data", res);
      if (res.success == true) {
        setTimeout(() => {
          alert("GST Details Added Successfully");
          navigate(`/gst-details`);
        }, 800);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.hsnNo) {
      errors.hsnNo = "HSN No. is Required";
    }

    if (!values.natureOfTransaction) {
      errors.natureOfTransaction = "Nature of Transaction is Required";
    }
    if (!values.isNonGstGoods) {
      errors.isNonGstGoods = "Is Non GstGoods is Required";
    }
   
    return errors;
  };

  return (
    <div className={styles.container}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="hsnNo"
            value={formValues.hsnNo}
            onChange={handleChange}
            className={styles.inputs}
            label="HSN No.*"
            variant="outlined"
          />
          <small className="text-red">{formErrors.hsnNo}</small>
        </Grid>

        <Grid item xs={6}>
       
            <TextField
              select
              id="outlined-basic"
              value={formValues.natureOfTransaction}
              onChange={handleChange}
              className={styles.inputs}
              label="Nature of Transaction*"
              variant="outlined"
              name="natureOfTransaction"
            >
                 {selectData?.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.valueDesc}
              </MenuItem>
            ))}
            </TextField>
          <p className="text-red">{formErrors.natureOfTransaction}</p>
        </Grid>
        

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            select
            value={formValues.isNonGstGoods}
            onChange={handleChange}
            className={styles.inputs}
            label="is GST Goods or Not ?*"
            variant="outlined"
            name="isNonGstGoods"
          >
             <MenuItem value="yes">
                Yes
              </MenuItem>
              <MenuItem value="no">
                No
              </MenuItem>
          </TextField>
          <p className="text-red">{formErrors.isNonGstGoods}</p>
        </Grid>

        <Grid item xs={6}>
          <TextareaAutosize
            minRows={6}
            id="outlined-basic"
            value={formValues.description}
            name="description"
            onChange={handleChange}
            className={styles.textarea}
            placeholder="Description"
            variant="outlined"
          />
          <small className="text-red">{formErrors.description}</small>
        </Grid>

        <Grid item xs={12}>
          <button className={styles.button} onClick={submithandler}>
            Save Details
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default GstForm;
