import React, { useEffect, useState } from "react";
import NavbarComponent from "../../../../Component/Navbar";
import SidenavTabs from "../../../../Component/SidenavTabs";
import uploadIcon from "../../../../Assets/images/uploadicon.png";
import editImg from "../../../../Assets/images/edit.png";
import EllipseImg from "../../../../Assets/images/Ellipse.png";
import GroupImg from "../../../../Assets/images/Group.png";
import pigImg from "../../../../Assets/images/mascot.png";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import {
  getSingleActivity,
  updateSingleActivity,
} from "../../../../services/activity";
import { getSingleCategory } from "../../../../services/category";
import {
  deleteSingleAttribute,
  getAttributesByActivityId,
} from "../../../../services/attributes";
import Swal from "sweetalert2";
import {
  getSingleSubscription,
  updateSingleSubscription,
} from "../../../../services/subscription";

function UpdateSubscription() {
  const [fromCount, setFromCount] = useState("");
  const [toCount, setToCount] = useState("");
  const [showDeletePlaylistModel, setShowDeletePlaylistModel] = useState(false);
  const [showPlaylistModel, setShowPlaylistModel] = useState(false);
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [fileLink, setFileLink] = useState("");
  const [file, setFile] = useState("");
  const [activityObj, setActivityObj] = useState({});
  const [attributesArr, setAttributesArr] = useState([]);
  const [categoryObj, setCategoryObj] = useState({});
  const [duration, setDuration] = useState("");
  const [month, setmonth] = useState("");
  const [description, setDescription] = useState("");
  const [offerText, setofferText] = useState("");
  const [price, setprice] = useState("");
  const [mrp, setmrp] = useState("");

  //formdata
  const [selectedAttribute, setSelectedAttribute] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  let navigate = useNavigate();
  let id = useParams();

  const handleClose = () => {
    setFile("");
    navigate("/subscriptions");
  };
  const handleNavigation = () => {
    navigate("/home");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(updateActivityByid(actName, itemId, selectedFile, fromCount, toCount))
    navigate("/home/activity");
  };

  const handleOnint = () => {
    getSubscriptionById();
    // getAttributeDataByActivityId();
  };

  useEffect(() => {
    handleOnint();
  }, []);

  const getSubscriptionById = async () => {
    try {
      let { data: res } = await getSingleSubscription(id.id);
      if (res.success == true) {
        console.log("this is data: ", res.data);
        setActivityObj(res?.data);
        setName(res.data.name);
        setDuration(res.data.duration);
        setmonth(res.data.duration);
        setDescription(res.data.description);
        setmrp(res.data.mrp);
        setofferText(res.data.offerText);
        setprice(res.data.price);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
        navigate(-1);
      }
    }
  };
  const getCategoryDataByActivityId = async (id) => {
    try {
      let { data: res } = await getSingleCategory(id);
      if (res.success == true) {
        setCategoryObj(res?.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };
  const getAttributeDataByActivityId = async () => {
    try {
      let { data: res } = await getAttributesByActivityId(id.id);
      if (res.success == true) {
        console.log(res.data);
        setAttributesArr(res?.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  const handleActivityEdit = async () => {
    try {
      let obj = {
        name: name,
        duration: duration, //// in months
        description: description,
        offerText: offerText,
        price: price,
        mrp: mrp,
      };
      const { data: res } = await updateSingleSubscription(id.id, obj);
      if (res.success) {
        console.log(res.data);
        Swal.fire(res.message);
        handleOnint();
        clearstates();
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  const handleMonth = (d) => {
    let m = d.slice(d.length - 2);
    setDuration(d);
    setmonth(m);
  };

  const handleAttributeDelete = async () => {
    try {
      const { data: res } = await deleteSingleAttribute(selectedAttribute?._id);
      if (res.success) {
        console.log(res.data);
        Swal.fire(res.message);
        handleOnint();
        setShowDeletePlaylistModel(false);
        clearstates();
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  const handleModalOpen = () => {
    if (selectedAttribute._id) {
      setShowDeletePlaylistModel(true);
    } else {
      Swal.fire("please select an attribute to proceed further");
    }
  };
  const handleEditRedirect = () => {
    if (selectedAttribute._id) {
      navigate(`/home/activity/edit-attribute/${selectedAttribute._id}`);
    } else {
      Swal.fire("please select an attribute to proceed further");
    }
  };
  const handleAddAttributeRedirect = () => {
    navigate(`/home/activity/add-attribute/${id.id}`);
  };

  const clearstates = () => {
    setName("");
    setFromCount(0);
    setFileLink("");
    setToCount(0);
  };

  const handleFromCount = (e) => {
    if (fromCount >= toCount) {
      setErrorMessage("cannot be greaterthanTo");
    } else {
      setFromCount((prevState) => parseInt(prevState) + 1);
    }
  };
  const handleToCount = (e) => {
    if (toCount <= fromCount) {
      setErrorMessage("cannot be lessthanFrom");
    } else {
      setToCount((prevState) => parseInt(prevState) - 1);
    }
  };

  const handleNegativeFromCount = () => {
    if (fromCount <= 0) {
      setErrorMessage("cannot be lessthan0");
    } else {
      setFromCount((prevState) => parseInt(prevState) - 1);
    }
  };

  const TableLiElement = (props) => {
    return (
      <table id="resultTable">
        <thead>
          <tr id="first" className="table-title">
            <th>Attribute Name</th>
            <th>Calendar Req.</th>
            <th>Coins Req.</th>
          </tr>
        </thead>
        <tbody>
          {attributesArr.map((el, index) => (
            <tr key={index}>
              <td>
                <div className="d-flex">
                  <input
                    onChange={() => console.log(el)}
                    onClick={() => {
                      console.log(el);
                      setSelectedAttribute(el);
                    }}
                    type="radio"
                    checked={selectedAttribute._id == el._id}
                  />
                  <p className="tableInputTxt" type="text" name="column_1">
                    {el?.name}
                  </p>
                </div>
              </td>
              <td>{el.frequency ? "Yes" : "No"}</td>
              <td>{el.willAddCoins ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <div className="home-outer">
        <div className="innerPageContent">
          <div className="modelMain">
            <div className="container">
              <div className=" m-2 row flex-row">
                <div className="catName d-flex flex-row w-75 m-0">
                  <h2 className="my-auto p-2 primaryText">
                    {categoryObj?.name} Sub
                  </h2>
                </div>
                <div className="catName col-6 m-0">
                  <label className="my-3 p-2">Subscription Name: </label>
                  <input
                    className="catInput w-50 float-end"
                    placeholder="Type Subscription Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </div>
                <div className="catName col-6 m-0">
                  <label className="my-3 p-2">Duration: </label>
                  <input
                    className="catInput w-50 float-end"
                    placeholder="Type Duration Here"
                    type="number"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                  ></input>
                </div>
                <div className="catName col-6 m-0">
                  <label className="my-3 p-2 col-4">Description: </label>
                  <input
                    className="catInput w-50 float-end"
                    placeholder="Type Description Here"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></input>
                </div>
                <div className="catName col-6 m-0">
                  <label className="my-3 p-2">Offer: </label>
                  <input
                    className="catInput w-50 float-end"
                    placeholder="Type Offer Here"
                    type="text"
                    maxLength={3}
                    size={3}
                    value={offerText}
                    onChange={(e) => setofferText(e.target.value)}
                  ></input>
                </div>
                <div className="catName col-6 m-0">
                  <label className="my-3 p-2">Price: </label>
                  <input
                    className="catInput w-50 float-end"
                    placeholder="Type Price Name Here"
                    value={price}
                    type="number"
                    onChange={(e) => setprice(e.target.value)}
                  ></input>
                </div>
                <div className="catName col-6 m-0">
                  <label className="my-3 p-2">MRP: </label>
                  <input
                    className="catInput w-50 float-end"
                    placeholder="Type MRP Name Here"
                    value={mrp}
                    type="number"
                    onChange={(e) => setmrp(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ width: "40%" }}
            className="container mx-auto modelFooter"
          >
            <button
              className="mainButton modelBtn"
              variant="secondary"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="mainButton modelBtn"
              variant="primary"
              onClick={handleActivityEdit}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateSubscription;
