import API from "../axios/api";
import axios from "axios";

const BASE_LINK = `${API.BASE_LINK}/users`;


export const getdeleteduser = async (id, formData) => {
    return await axios.get(`${BASE_LINK}/deletedUsers`);
};
export const getdeleteduserbydate = async ( formData) => {
    return await axios.post(`${BASE_LINK}/deletedUsers/bydate`,formData);
};
