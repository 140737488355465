import React from 'react'
import tab1 from "../Assets/images/categories.png";
import tab2 from "../Assets/images/activity.png";
import tab3 from "../Assets/images/playlist.png";
import tab4 from "../Assets/images/report.png";
import "../Assets/Styling/adminHome.css";

function SidenavTabs(props) {
    const { showHome, activeTab } = props
    return (
        <>
            {/* <NavbarComponent search={true} /> */}

            <div className="row sidenav-outer col-3">
                <div className="sidenavTabs nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist">
                    <div
                        onClick={showHome}
                        className={activeTab === 'first' ? "col tab nav-link active" : "col tab nav-link"}
                        id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="false"
                    >
                        {/* <span className="tab-span "></span> */}
                        <div className="tab-inner mt-2">
                            <img src={tab1}></img>
                            <h4>Categories</h4>
                        </div>
                    </div>
                    <div onClick={showHome} className={activeTab === 'second' ? "col tab nav-link active" : "col tab nav-link"} id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected={activeTab ? "true" : "false"}>
                        <div className="tab-inner mt-2">
                            <img src={tab2}></img>
                            <h4>Activity</h4>
                        </div>
                    </div>
                    <div onClick={showHome} className={activeTab === 'third' ? "col tab nav-link active" : "col tab nav-link"}  id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                        <div className="tab-inner mt-2">
                            <img src={tab3}></img>
                            <h4>Playlist</h4>
                        </div>
                    </div>
                    <div onClick={showHome} className={activeTab === 'forth' ? "col tab nav-link active" : "col tab nav-link"} id="v-pills-reportings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-reportings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                        <div className="tab-inner mt-2">
                            <img src={tab4}></img>
                            <h4>Reporting</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SidenavTabs
