import API from "../axios/api";
import axios from "axios";

const BASE_LINK = `${API.BASE_LINK}/userMissions`;


export const getAllMissions = async (id, formData) => {
    return await axios.get(`${BASE_LINK}/getAllMissions`);
};

export const getAllMissionsbydate = async (formData) => {
    return await axios.post(`${BASE_LINK}/getAllMissions/bydate`,formData);
};