import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  InputGroup,
  Row,
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  Offcanvas,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import { searchContext } from "../App";
import logo from "../Assets/images/new-complete-logo1.png";
// import logotext from '../Assets/images/GGP-newwordmark1.svg'
import searchicon from "../Assets/images/searchicon.png";
import "../Assets/Styling/navbar.css";
import styles from "../Assets/Styling/navbar.module.css";
import { getSearchResults } from "../services/search";

function NavbarComponent(props) {
  const { white } = props;
  let navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [resultArr, setResultArr] = useState([]);
  const handleChange = (e) => {
    setQuery(e.target.value);
    // if (e.target.key == 13) { }
  };
  const handleEnterKeyPress = async (e) => {
    if (e.key == "Enter" || e.target.value !== "") {
      setQuery(e.target.value);
      try {
        setLoading(true);
        setModalIsVisible(true);
        let res = await getSearchResults({ query: query });
        console.log(res);
        if (res.data) {
          console.log(res.data);
          setResultArr(res.data.data);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        if (err?.response?.data?.message) {
          console.log(err?.response?.data?.message);
          alert(err?.response?.data?.message);
        } else {
          console.log(err);
          alert(err);
        }
      }
    }
  };

  return (
    <div className={white ? "navbar-outer white" : "navbar-outer"}>
      {/* <Row>
        <Col>
          <img src={logo} alt="logo"></img>
        </Col>
        <Col>
          <input className="input" placeholder="Search here..."></input>
        </Col>
        <Col>
          <div className="slideMenuBtn"></div>
        </Col>
      </Row> */}

      <Navbar expand={false}>
        <Container fluid>
          <Link to="/">
          <img src={logo} alt="logo" width={300} height={100}></img>
          </Link>

          {/* <div className={styles.searchInputOuter + ` input`}>
            <input
              className={styles.navbarSearch + ` input`}
              placeholder="Search here..."
              onKeyDown={handleEnterKeyPress}
              onChange={(e) => handleEnterKeyPress(e)}
              name="searchInput"
            ></input>
            <img
              style={{
                marginRight: "1em",
                width: "7%",
                height: "20px",
                marginTop: "5px",
              }}
              src={searchicon}
              alt=""
            />

            {modalIsVisible && (
              <div className={styles.searchInputInner}>
                {loading ? (
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      height: "100%",
                    }}
                  >
                    <iframe
                      src="https://giphy.com/embed/y1ZBcOGOOtlpC"
                      width="100"
                      height="100"
                      frameBorder="0"
                      class="giphy-embed"
                      allowFullScreen
                    ></iframe>
                  </div>
                ) : (
                  <div>
                    {resultArr &&
                      resultArr.map((el, index) => {
                        return (
                          <div
                            onClick={() => {
                              setModalIsVisible(false);
                              el.type == "activity"
                                ? // alert("Activity")
                                navigate(
                                  `/home/activity/update-activity/${el._id}`
                                )
                                : el.type == "category"
                                  ? navigate(`/categories`)
                                  : el.type == "attributes"
                                    ? navigate(
                                      `/home/activity/edit-attribute/${el._id}`
                                    )
                                    : el.type == "playlist"
                                      ? navigate(`/playlist/update/${el._id}`)
                                      : navigate(`/video/update/${el._id}`);

                              window.location.reload();
                            }}
                            style={{
                              borderBottom: "solid 1px lightgray",
                              cursor: "pointer",
                            }}
                            className="searchResultEl"
                          >
                            <div
                              style={{
                                paddingLeft: "10px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                              key={index}
                            >
                              <b>{el.name}</b>
                            </div>
                            <div
                              style={{
                                paddingLeft: "10px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                            >
                              in {el.type}
                            </div>
                          </div>
                        );
                      })}
                    <div>
                      {resultArr.length == 0 && (
                        <div
                          style={{
                            paddingLeft: "10px",
                            paddingTop: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          No data found for this query
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div> */}
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
          >
            {/* <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                Offcanvas
              </Offcanvas.Title>
            </Offcanvas.Header> */}
            <Offcanvas.Body>{/* <SideNavTabs /> */}</Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
}

// const SideNavTabs = (props) => {
//   return (
//     <Row className="sidenavTabs">
//       <Col className="tab">
//         <div className="tab-inner">
//           <img src={tab1}></img>

//           <h4>Categories</h4>
//         </div>
//       </Col>
//       <Col className="tab">
//         <div className="tab-inner">
//           <img src={tab2}></img>
//           <h4>Activity</h4>
//         </div>
//       </Col>
//       <Col className="tab">
//         <div className="tab-inner">
//           <img src={tab3}></img>
//           <h4>Playlist</h4>
//         </div>
//       </Col>
//       <Col className="tab">
//         <div className="tab-inner">
//           <img src={tab4}></img>
//           <h4>Reporting</h4>
//         </div>
//       </Col>
//     </Row>
//   );
// };

export default NavbarComponent;
