import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";
import { getSubscriptions, subStatus } from "../../../services/subscription";
import { getRegisteredParents } from "../../../services/User";
import styles from "./activity.module.css";
import { Link } from "react-router-dom";
import Switch from "@mui/material/Switch";

export default function GetAllSubscription() {
  const [subscriptionArr, setSubscriptionsArr] = useState([]);
  const [checked, setchecked] = useState(false);
  let navigate = useNavigate();
  let id = useParams();

  const getPlaylists = async () => {
    try {
      let { data: res } = await getSubscriptions();
      console.log(res.data);
      if (res.success == true) {
        setSubscriptionsArr(res?.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        // Swal.fire(error?.message, "this is errore");
      }
    }
  };

  console.log(subscriptionArr, "subscriptionArr");

  useEffect(() => {
    getPlaylists();
    // getActivityByCategoryId();
  }, [checked]);

  const handleChangeSwitch = async (id) => {
    let { data: res } = await subStatus(id);
    console.log("checked value", res.data);
    setchecked(!checked);
  };

  const TableListElement = () => {
    return (
      <table id="resultTable">
        <thead id="first" className="table-title">
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>MRP</th>
            <th>Created On</th>
            <th>Status</th>
            <th>No. of Users</th>
            <th>View Users</th>
          </tr>
        </thead>
        <tbody>
          {subscriptionArr.map((el, index) => (
            <tr key={index}>
              <td>
                <div className="d-flex">
                  <p
                    className="tableInputTxt"
                    style={{ marginLeft: "-1em" }}
                    type="text"
                    name="column_1"
                  >
                    {el?.name}
                  </p>
                  {/* <img src={el?.planImage} width="50" alt={el?.name} /> */}
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <p
                    className="tableInputTxt"
                    style={{ marginLeft: "-1em" }}
                    type="text"
                    name="column_1"
                  >
                    INR {el?.price}
                  </p>
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <p
                    className="tableInputTxt"
                    style={{ marginLeft: "-1em" }}
                    type="text"
                    name="column_1"
                  >
                    INR {el?.mrp}
                  </p>
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <p
                    className="tableInputTxt"
                    style={{ marginLeft: "-1em" }}
                    type="text"
                    name="column_1"
                  >
                    {new Date(el?.createdAt).toDateString()}
                  </p>
                </div>
              </td>
              <td>
                <Switch
                  checked={el.isActive}
                  onChange={() => handleChangeSwitch(el._id)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </td>
              <td>
                <div className={styles.tableImgOuter}>
                  <p className="tableInputTxt" type="text" name="column_1">
                    {el?.userSubscriptionArr?.length}
                  </p>
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <button
                    className="addPlaylistBtn primaryBtn"
                    onClick={() => navigate(`/ViewSubscribedUsers/${el?._id}`)}
                  >
                    View Users
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="home-outer">
      <div
        className={"container col playlist-outer pt-5 " + styles.activityInnner}
      >
        <div className="row">
          {/* <h2>Category Name</h2> */}
          <div style={{ padding: "8rem" }} className="col-md">
            <div className="row">
              <h1 style={{ padding: 0 }} className={styles.screenTitle}>
                {subscriptionArr?.name}
              </h1>
              <div className="col playlistTitle p-0">
                <h3 style={{ padding: 0 }}>List of all Subscriptions</h3>
              </div>
              <div
                className="col playlistTitle p-0"
                style={{
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <button>
                  <Link to="/addSubscription">Add Subscription</Link>
                </button>
                <h3 style={{ padding: 0, width: "max-content" }}>
                  Total Subscriptions : {subscriptionArr?.length}
                </h3>
              </div>
            </div>
            <div className="playlistTable">
              {/* {ActivitiesByCatIdInfo.loading === true ? (
                                      null
                                  ) : activitiesState.data.length === 0 ? (<p style={{color: "red"}}>no activities found</p>) : ( */}
              <div className="row tableMain">
                <TableListElement />
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
