import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Swal from 'sweetalert2'
import { getSingleSubscription, getSubscriptions } from '../../../services/subscription'
import { getRegisteredParents } from '../../../services/User'
import styles from './activity.module.css'
export default function ViewUsersForSubscription() {
    const [usersubscriptionArr, setUsersubscriptionArr] = useState([])
    const [SubscriptionObj, setSubscriptionObj] = useState({});
    let navigate = useNavigate()
    let id = useParams()

    const getPlaylists = async () => {
        try {
            let { data: res } = await getSingleSubscription(id.id)
            console.log(res.data.userSubscriptionArr)
            if (res.success == true) {
                setUsersubscriptionArr(res?.data.userSubscriptionArr)
                setSubscriptionObj(res?.data)

            }
        } catch (error) {
            console.error(error)
            if (error?.response?.data?.message) {
                Swal.fire(error.response.data.message)
            } else {
                // Swal.fire(error?.message, "this is errore");
            }
        }
    }




    useEffect(() => {
        getPlaylists()
        // getActivityByCategoryId();
    }, [])


    const TableListElement = () => {
        return (
            <table id='resultTable'>
                <thead id='first' className='table-title'>
                    <tr>
                        <th>Subscription Name</th>
                        <th>Subscription Desctription</th>
                        <th>Price</th>
                        <th>Status</th>
                        <th>Purchased On</th>
                        <th>User Name</th>
                    </tr>
                </thead>
                <tbody>
                    {usersubscriptionArr && usersubscriptionArr.length > 0 && usersubscriptionArr.map((el, index) => (
                        <tr key={index}>
                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                        {el?.name}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                        {SubscriptionObj?.description}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                        INR {el?.price}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                        {el?.status}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                        {new Date(el?.createdAt).toDateString()}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                        {el?.userObj?.firstName} {el?.userObj?.lastName}
                                    </p>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    return (
        <div className='home-outer'>
            <div
                className={'container col playlist-outer pt-5 ' + styles.activityInnner}
            >
                <div className='row'>
                    {/* <h2>Category Name</h2> */}
                    <div style={{ padding: '8rem' }} className='col-md'>
                        <div className='row'>
                            <h1 style={{ padding: 0 }} className={styles.screenTitle}>
                                {/* {usersubscriptionArr?.name} */}
                            </h1>
                            <div className='col playlistTitle p-0'>
                                <h3 style={{ padding: 0 }}>List of all Users for Subscription ({SubscriptionObj?.name})</h3>
                            </div>
                            <div className='col playlistTitle p-0' style={{ alignItems: "flex-end", justifyContent: "flex-end", display: "flex", flexDirection: "column" }}>
                                {/* <h3 style={{ padding: 0, width: "max-content" }}>Total Users having subscription: {usersubscriptionArr.reduce((acc, el) => acc + el.userSubscriptionArr.length, 0)}</h3> */}
                                {/* <h3 style={{ padding: 0, width: "max-content" }}>Total Subscriptions : {usersubscriptionArr.length}</h3> */}
                            </div>


                        </div>
                        <div className='playlistTable'>
                            {/* {ActivitiesByCatIdInfo.loading === true ? (
                                      null
                                  ) : activitiesState.data.length === 0 ? (<p style={{color: "red"}}>no activities found</p>) : ( */}
                            <div className='row tableMain'>
                                <TableListElement />
                            </div>
                            {/* )} */}
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

