import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getGSTDetailsTable,
  updateGstDetailsTable,
  gstDetailsDelete,
  getUniverseData
} from "../../../services/GstDetails/gstDetails";
import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import pigImg from "../../../Assets/images/GGP-newlogo-wo-word1.png";

const GstTable = () => {
  const [universeList, setUniverseList] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [checked, setchecked] = useState(false);
  const [delPopup, setDelPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [selectedPrefix, setSelectedPrefix] = useState({});
  const [selectData, SetSelectData] = useState([]);


  const getUniverse = async () => {
    let { data: res } = await getGSTDetailsTable();
    setUniverseList(res.data);
    console.log("universeList", universeList);
  };

  const getTransactionData = async () => {
    let { data: res } = await getUniverseData("entityCode=gstTable-NatureOfTrans&&status=true");
    console.log(res.data)
    SetSelectData(res.data);
  };

  useEffect(() => {
    getTransactionData();
  }, []);

  //   console.log("sdfshdfjda", prefixList);

  useEffect(() => {
    getUniverse();
  }, [checked, showAddModal]);

  const delModelHandle = async (id) => {
    console.log("Delete", id);
    if (!activeId) {
      setActiveId(id);
    } else {
      setActiveId("");
    }
    setchecked(!checked);
    setDelPopup(!delPopup);
  };

  const deletePrefix = async (id) => {
    try {
      let { status: status } = await gstDetailsDelete(id);
      if (status === 200) {
        console.log("deleted Category Done", id);
        delModelHandle();
      }
    } catch (error) {
      delModelHandle();
      Swal.fire(
        error?.response?.data?.message,
        "Error While Deleting Category"
      );
    }
  };

  const initialValues = { hsnNo: "", natureOfTransaction: "", isNonGstGoods: "", description: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  const handleEdit = (item) => {
    console.log("item");
    let editItem = item;
    // editItem.category = item.category._id;
    setFormValues(editItem);
    console.log("after selection edit formvalues", formValues);
    setShowAddModal(true);
  };

  const editSubmitHandler = async () => {
    console.log("id");
    setFormErrors(validate(formValues));    

    if (
        formValues.hsnNo && formValues.isNonGstGoods
    ) {
      // console.log("Product update data", formValues.category._id);
      let { data: res } = await updateGstDetailsTable(
        selectedPrefix?._id,
        formValues
      );
      console.log("res", res);
      if (res.success == true) {
        Swal.fire(res.message);
        setShowAddModal(false);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.hsnNo) {
      errors.hsnNo = "HSN No. is Required";
    }

    if (!values.natureOfTransaction) {
      errors.natureOfTransaction = "Nature of Transaction is Required";
    }
    if (!values.isNonGstGoods) {
      errors.isNonGstGoods = "Is Non GstGoods is Required";
    }
 
    return errors;
  };


  return (
    <>
      <Link to="/add-gstDetails">
        <button className="button2">Add More</button>
      </Link>
      <div className="myaccount-table table-responsive text-center">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>HSN No.</th>
              <th>Desc</th>
              <th>Nature of Trans.</th>
              <th>GSt Goods</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {universeList?.map((prefix, index) => {
              return (
                <tr>
                  {console.log(prefix)}
                  <td>{prefix.hsnNo}</td>
                  <td>{prefix.description}</td>
                  <td>{prefix?.natureOfTransaction?.valueDesc}</td>
                  <td>{prefix.isNonGstGoods}</td>
                 
                  <td>
                    <MdDelete
                      onClick={() => delModelHandle(prefix._id)}
                      className="product-delete"
                    />
                    <BiEdit
                      onClick={() => {
                        handleEdit(prefix);
                        setSelectedPrefix(prefix);
                      }}
                      className="product-update"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <DelModel
        show={delPopup}
        handleShow={() => setDelPopup(!delPopup)}
        activeDelId={activeId}
        handleSubmit={(x) => deletePrefix(x)}
      />

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <div className="modelMain  modal-dialog-centered ">
          <Modal.Body className="modelBody ">
            <div className="container m-2">
              <div className="catName m-0">
                <label>HSN No:</label>
                <input
                  className="catInput"
                  placeholder="HSN No.*"
                  required
                  value={formValues.hsnNo}
                  name="hsnNo"
                  onChange={handleChange}
                ></input>
                <small className="text-red">{formErrors.hsnNo}</small>
              </div>

              <div className="catName m-0">
                <label>Nature Of Transaction:</label>
                <select
                  className="catInput"
                  placeholder="Nature of Transaction*"
                  required
                  value={formValues.natureOfTransaction}
                  onChange={handleChange}
                  name="natureOfTransaction"
                >
                     

                  {selectData?.map((option) => (
              <option key={option._id} value={option._id}>
                {option.valueDesc}
              </option>
            ))}
               
                </select>
                <small className="text-red">{formErrors.natureOfTransaction}</small>
              </div>
              <div className="catName m-0">
                <label>is GST Goods or Not ? *</label>
                <input
                  className="catInput"
                  placeholder="GST Goods or Not*"
                  required
                  value={formValues.isNonGstGoods}
                  onChange={handleChange}
                  name="isNonGstGoods"
                ></input>
                <small className="text-red">{formErrors.isNonGstGoods}</small>
              </div>
              
              <div className="catName m-0">
                <label>Description</label>
                <input
                  className="catInput"
                  placeholder="Description*"
                  required
                  value={formValues.description}
                  onChange={handleChange}
                  name="description"
                ></input>
                <small className="text-red">{formErrors.description}</small>
              </div>
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                  setShowAddModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="mainButton modelBtn "
                variant="primary"
                onClick={editSubmitHandler}
              >
                Save
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default GstTable;

const DelModel = ({ show, handleShow, activeDelId, handleSubmit }) => {
  return (
    <>
      <Modal show={show} onHide={() => handleShow()}>
        <div className="modelMain">
          <Modal.Body className="modelBody ">
            <div className="d-flex">
              <div className="row">
                <div className="col d-flex ">
                  <h3 className="deletePlaylistTitle">
                    Are you sure you want to delete this GST Detail ?
                  </h3>
                </div>
                <div className="col modelFooter mt-4">
                  <button
                    style={{ width: "160px" }}
                    className="mainButton modelBtn"
                    variant="secondary"
                    onClick={() => handleSubmit(activeDelId)}
                  >
                    Delete It
                  </button>
                  <button
                    className="mainButton modelBtn"
                    style={{
                      backgroundColor: "#AA23AD",
                      color: "#F2F4F6",
                      width: "160px",
                    }}
                    variant="primary"
                    onClick={() => handleShow()}
                  >
                    No
                  </button>
                </div>
              </div>
              <img width={124} src={pigImg} alt="Piggy" />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
