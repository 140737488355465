// for production
// const API = {
//   BASE_LINK: "https://api.goodgoodpiggy.com/api",
//   Ecommerce: "https://api.goodgoodpiggy.com/api",
//   PAYMENT_URL: "https://api.goodgoodpiggy.com",
// };

// dev/
const API = {
  BASE_LINK: "https://devapi.goodgoodpiggy.in/api",
  Ecommerce: "https://devapi.goodgoodpiggy.in/api",
  PAYMENT_URL: "https://devadmin.goodgoodpiggy.in/",
};

/* for Local Machine */

// const API = {
//  BASE_LINK: "http://192.168.1.14:4012/api",
//  BASE_LINK2: "http://192.168.1.14:4012/api/v2",
//  Ecommerce:"http://192.168.1.14:4012/api",
//  PAYMENT_URL:"http://192.168.1.14:3000",
// }

// const API = {
//   BASE_LINK: "http://192.168.202.60:4012/api",
//   BASE_LINK2: "http://192.168.202.60:4012/api/v2",
//   Ecommerce: "http://192.168.202.60:4012/api",
//   PAYMENT_URL: "http://192.168.202.60:3000"
// };

export default API;
