import API from "../../axios/api";
import axios from "axios";

const BASE_LINK = `${API.BASE_LINK}`;

export const getallPrefixes = async () => {
    return await axios.get(`${BASE_LINK}/taxprefix/getAllPrefix`);
};

export const addTaxPrefix = async (formData) => {
    // console.log("Set APi")
    return await axios.post(`${BASE_LINK}/taxprefix/addTaxPrefix`, formData);
};

export const prefixTableDelete = async (id) => {
    console.log("Deleted")
    return await axios.delete(`${BASE_LINK}/taxprefix/deleteById/${id}`);
};


export const updatePrefix = async (id, formData) => {
    console.log("id", formData)
    return await axios.patch(`${BASE_LINK}/taxprefix/updateById/${id}`, formData);
};


///////////////////////// Universe table /////////////////////////////


export const addUniverseTable = async (formData) => {
    return await axios.post(`${BASE_LINK}/universetable/create`, formData);
};


export const getUniverseTable = async () => {
    return await axios.get(`${BASE_LINK}/universetable/get`);
};


export const updateUniverseTable = async (id, formData) => {
    console.log("id", formData)
    return await axios.patch(`${BASE_LINK}/universetable/update?_id=${id}`, formData);
};


export const updateUniverseStatus = async (id) => {
    console.log("form", id)
    return await axios.get(`${BASE_LINK}/universetable/update-status?_id=${id}`);
};

export const universeTableDelete = async (id) => {
    return await axios.delete(`${BASE_LINK}/universetable/delete?_id=${id}`);
};



/////////////// TAX TYPE APIS CONNECTED ////////////


export const addTaxType = async (formData) => {
    return await axios.post(`${BASE_LINK}/taxtype/create`, formData);
};

export const getTaxTypeTable = async () => {
    return await axios.get(`${BASE_LINK}/taxtype/get`);
};


export const updateTaxTypeTable = async (id, formData) => {
    console.log("id", formData)
    return await axios.patch(`${BASE_LINK}/taxtype/update?_id=${id}`, formData);
};

export const taxTableDelete = async (id) => {
    return await axios.delete(`${BASE_LINK}/taxtype/delete?_id=${id}`);
};



/////////////// GST DETAILS APIS CONNECTED ////////////


export const addGstDetails = async (formData) => {
    return await axios.post(`${BASE_LINK}/gstDetails/create`, formData);
};

export const getGSTDetailsTable = async () => {
    return await axios.get(`${BASE_LINK}/gstDetails/get`);
};

export const updateGstDetailsTable = async (id, formData) => {
    console.log("id", formData)
    return await axios.patch(`${BASE_LINK}/gstDetails/update?_id=${id}`, formData);
};

export const gstDetailsDelete = async (id) => {
    return await axios.delete(`${BASE_LINK}/gstDetails/delete?_id=${id}`);
};

/////////////// TAX DETAILS APIS CONNECTED ////////////


export const addTaxDetails = async (formData) => {
    return await axios.post(`${BASE_LINK}/taxDetails/create`, formData);
};

export const getTaxDetailsTable = async () => {
    return await axios.get(`${BASE_LINK}/taxDetails/get`);
};

export const updateTaxDetailsTable = async (id, formData) => {
    console.log("id", formData)
    return await axios.patch(`${BASE_LINK}/taxDetails/update?_id=${id}`, formData);
};

export const taxDetailsDelete = async (id) => {
    return await axios.delete(`${BASE_LINK}/taxDetails/delete?_id=${id}`);
};


////// GET UNIVERSE DATA ////////

export const getUniverseData = async (type) => {
    return await axios.get(`${BASE_LINK}/universetable/getSelectData?${type}`)
}
