import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  getallOrders,
  updateOrderStatus,
} from "../../../services/Ecommerce/Orders";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import styles from "./activity.module.css";
import OrderModal from "./OrderModal";

export default function AllOrders() {
  const navigate = useNavigate();
  const [parentsArr, setParentsArr] = useState([]);
  const [orderlist, setOrderList] = useState([]);
  const [activeRow, setActiveRow] = useState({});
  const [show, setShow] = useState(false);

  const handleShow = (id) => {
    if (show === false) {
      setActiveRow(id);
    }
    setShow(!show);
  };

  const orders = async () => {
    try {
      let { data: res } = await getallOrders();
      // console.log("orders data", res.data);
      // setOrderList([]);
      setOrderList(res.data);
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    orders();
  }, []);

  const showOrderData = (row) => {
    navigate(`/ecommerce/orderData/${row._id}`, { state: row });
  }

  console.log("product orders", orderlist);

  const TableListElement = () => {
    return (
      <>
        <div  style={{
            width: "100%",
            height: "100vh", 
          }} className="myaccount-table table-responsive text-center">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th>Order ID</th>
                <th>Parent Name & Kid Name & Phone Number</th>
                <th>Quantity and Amount</th>
                <th>Date/Time</th>
                {/* <th>Amount</th> */}
                <th>status</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {orderlist?.map((order, index) => {
                return (
                  <ROW
                    item={order}
                    handleShow={(x) => handleShow(x)}
                    show={show}
                    showOrderData={showOrderData}
                  />
                );
              })}
            </tbody>
          </table>
          <OrderModal
            orderdet={activeRow}
            show={show}
            handleShow={handleShow}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="home-outer">
        <div
          className={
            "container col playlist-outer pt-5 " + styles.activityInnner
          }
        >
          <div className="row">
            {/* <h2>Category Name</h2> */}
            <div className="col-md">
              <div className="row">
                <h1 style={{ padding: 0 }} className={styles.screenTitle}>
                  {parentsArr?.name}
                </h1>
                <div
                  className="col playlistTitle p-0"
                  style={{
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    display: "flex",
                  }}
                >
                  <h3 style={{ padding: 0, width: "max-content" }}>
                    Total Orders : {orderlist.length}
                  </h3>
                </div>
              </div>
              <div className="playlistTable">
                <div className="row tableMain" style={{ overflowX: "scroll" }}>
                  <TableListElement />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const ROW = ({ item, handleShow, show, showOrderData }) => {
  const [qtyArr, setQtyArr] = useState(0);
  const [confirmShow, setConfirmShow] = useState(false);

 
  const [orderStatus, setOrderStatus] = useState({
    status: "test"
  });
  const updatestatus = async (e, id) => {
    setOrderStatus(e.target.value);
    console.log(e.target.value)
    // console.log("ID", id)
    let { data: res } = await updateOrderStatus(id, { status: e.target.value});
    console.log("response status", res.data);
  };

  useEffect(() => {
    let qtrArray = [];
    let proLi = item.products_List;
    proLi.forEach((element) => {
      qtrArray.push(element.qty);
    });
    setQtyArr(parseInt(qtrArray.reduce((partialSum, a) => partialSum + a, 0)));
  }, []);
  


  return (
    <>
      <tr>
        <td>{item.order_Id}</td>
        <td>
          {`${item?.userId?.firstName} ${item?.userId?.lastName ? `${item?.userId?.lastName} ` : ''}`} 
          & 
          {` ${item?.kidId?.firstName} `}
          &
          {` ${item?.userId?.phone} `} 
        </td>
        <td>{qtyArr}
        <p>
        ₹ {item.orderAmount}/-
          </p></td>
        <td>{moment(item.createdAt).format("llll")}</td>
        {/* <td>{item.orderAmount}</td> */}
        <td>
          {/* <select
            defaultValue={item?.order_Status}
            name="order_Status"
            onChange={(e) => updatestatus(e, item._id)}
          >
            <option value="pending">Pending</option>
            <option value="cancelledBySeller">Cancelled By Seller</option>
            <option value="orderPlaced">Order Placed</option>
            <option value="confirmed">Confirmed</option>
            <option value="readyToShip">Ready to Ship</option>
            <option value="shipped">Shipped</option>
            <option value="outForDelivery">Out For Delivery</option>
            <option value="delivered">Delivered</option>
            <option value="return">Return</option>
            <option value="returned">Returned</option>
          </select> */}
          {item?.order_Status}
        </td>
        <td>
          <btn
            className="btn order-view-btn rounded-pill userinfo"
            onClick={() => {
              showOrderData(item);
              // handleShow(item);
            }}
          >
            View Order
          </btn>
        </td>
      </tr>

     
    </>
  );
};
