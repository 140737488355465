import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import { Modal } from "react-bootstrap";
import EllipseImg from "../../../Assets/images/Ellipse.png";
import editImg from "../../../Assets/images/edit.png";
import GroupImg from "../../../Assets/images/Group.png";
import pigImg from "../../../Assets/images/mascot.png";
import NavbarComponent from "../../../Component/Navbar";
import SidenavTabs from "../../../Component/SidenavTabs";
import { getSingleCategory } from "../../../services/category";
import {
    addNewActivity,
    deleteSingleActivity,
    getActivitiesByCategoryId,
} from "../../../services/activity";
import styles from "./activity.module.css";
import uploadIcon from "../../../Assets/images/uploadicon.png";
import Swal from "sweetalert2";
import {
    addNewSubscription,
    deleteSingleSubscription,
    getSubscriptions,
} from "../../../services/subscription";
import { createCoupons, deleteSingleCoupons, getAllCoupons, updateSingleCoupons } from "../../../services/Coupons";

export default function CouponsView() {
    const [showCouponAddModel, setShowCouponAddModel] = useState(false);
    const [showCouponDeleteModal, setShowCouponDeleteModal] = useState(false);
    const [couponArr, setCouponArr] = useState([]);
    const [name, setName] = useState("");
    const [value, setValue] = useState("");
    const [validTill, setValidTill] = useState(new Date);
    const [isValidOncePerUser, setIsValidOncePerUser] = useState(false);
    const [discountType, setDiscountType] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [maxUsers, setMaxUsers] = useState(1);
    let navigate = useNavigate();
    
    let id = useParams();


    const getCouponFromDB = async () => {
        try {
            let { data: res } = await getAllCoupons();
            console.log(res.data, "Coupons");
            if (res.success == true) {
                setCouponArr(res?.data);
            }
        } catch (error) {
            console.error(error);
            if (error?.response?.data?.message) {
                Swal.fire(error.response.data.message);
            } else {
                Swal.fire(error?.message);
            }
        }
    };

    const handleCouponAdd = async () => {
        try {
            if (discountType == "PERCENTAGE" && value > 100) {
                Swal.fire("Percentage cannot be more than 100");
            }
            else {

                if (
                    name != "" &&
                    validTill != "" &&
                    value != ""
                ) {
                    let obj = {
                        name,
                        value,
                        maxUsers,
                        validTill: validTill,
                        isValidOncePerUser,
                        discountType
                    };
                    const { data: res } = await createCoupons(obj);
                    if (res.success) {
                        console.log("this is data: ", res.data);
                        Swal.fire(res.message);
                        getCouponFromDB();
                        setShowCouponAddModel(false);
                        // clearstates();
                    }
                }
                else {
                    Swal.fire("Please Fill all fields");
                }
            }

        } catch (error) {
            console.error(error);
            if (error?.response?.data?.message) {
                Swal.fire(error.response.data.message);
            } else {
                Swal.fire(error?.message);
            }
        }
    };
    const clearstates = () => {
        setName("");
    };

    useEffect(() => {
        getCouponFromDB();
    }, []);

    const handleDeleteModalTrigger = () => {
        if (selectedCoupon.name) {
            setShowCouponDeleteModal(true);
        } else {
            Swal.fire("No activity selected to perform this action on");
        }
    };

    const handleCouponDelete = async () => {
        try {
            let { data: res } = await deleteSingleCoupons(selectedCoupon?._id);
            console.log(res.data);
            if (res.success == true) {
                Swal.fire(res.message);
                getCouponFromDB();
                setShowCouponDeleteModal();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleCouponUpdate = async (id) => {
        try {
            let { data: res } = await updateSingleCoupons(id);
            if (res.success == true) {
                Swal.fire(res.message);
                getCouponFromDB();
            }
        } catch (error) {
            console.error(error);
        }
    };


    const TableListElement = () => {
        return (
            <table id="resultTable">
                <thead id="first" className="table-title">
                    <tr>
                        <th>Coupon Name</th>
                        <th>Discount Type</th>
                        <th>Is Valid Once Per User</th>
                        <th>Coupon Valid Till</th>
                        <th>Value</th>
                        <th>Max. Allowed</th>
                        <th>Active Status</th>
                        <th>Change Status</th>
                    </tr>
                </thead>
                <tbody>
                    {couponArr.map((el, index) => (
                        <tr key={index}>
                            <td>
                                <div className="d-flex">
                                    <input
                                        onChange={() => console.log(el)}
                                        onClick={() => {
                                            setSelectedCoupon(el);
                                        }}
                                        type="radio"
                                        checked={selectedCoupon._id == el._id}
                                    />
                                    <p className="tableInputTxt" type="text" name="column_1">
                                        {el?.name}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className="tableInputTxt" type="number" name="column_1">
                                        {el?.discountType}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className="tableInputTxt" type="text" name="column_1">
                                        {el?.isValidOncePerUser ? "Yes" : "No"}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className="tableInputTxt" style={{ marginLeft: "-1em" }} type="text" name="column_1">
                                        {new Date(el?.validTill).toDateString()}
                                    </p>
                                </div>
                            </td>

                            <td>
                                <div>
                                    <p className="tableInputTxt" type="text" name="column_1">
                                        {el.discountType == "PERCENTAGE" ? `${el?.value}%` : el?.value}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className="tableInputTxt" type="text" name="column_1">
                                        {el?.maxUsers ? el?.maxUsers : 0}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p className="tableInputTxt" type="text" name="column_1">
                                        {el.isActive ? "Active" : "Inactive"}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <button className="addPlaylistBtn primaryBtn" style={{ marginRight: 100 }} onClick={() => handleCouponUpdate(el._id)}>{!el.isActive ? "Active" : "Inactive"}</button>
                                </div>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="home-outer">
            <div
                className={"container col playlist-outer pt-5 " + styles.activityInnner}
            >
                <div className="row">
                    {/* <h2>Category Name</h2> */}
                    <div style={{ padding: "3rem 8rem" }} className="col-md">
                        <div className="row">
                            <div className="col playlistTitle p-0">
                                <h3 style={{ padding: 0 }}>List of all Coupons for Subscriptions</h3>
                            </div>
                            <div className="col-lg-3 p-0">
                                <div className="icon-outer">
                                    <img
                                        onClick={() => handleDeleteModalTrigger()}
                                        className="tableIcon"
                                        src={GroupImg}
                                        alt="delete"
                                    />
                                </div>
                            </div>
                            <Modal
                                show={showCouponDeleteModal}
                                onHide={() => setShowCouponDeleteModal(false)}
                            >
                                <div className="modelMain">
                                    <Modal.Body className="modelBody ">
                                        <div className="d-flex">
                                            <div className="row">
                                                <div className="col d-flex ">
                                                    <h3 className="deletePlaylistTitle">
                                                        Are you sure you want to delete this Coupon?
                                                    </h3>
                                                </div>
                                                <div className="col modelFooter mt-4">
                                                    <button
                                                        style={{ width: "160px" }}
                                                        className="mainButton modelBtn"
                                                        variant="secondary"
                                                        onClick={handleCouponDelete}
                                                    >
                                                        Yes, remove it
                                                    </button>
                                                    <button
                                                        className="mainButton modelBtn"
                                                        style={{
                                                            backgroundColor: "#AA23AD",
                                                            color: "#F2F4F6",
                                                            width: "160px",
                                                        }}
                                                        variant="primary"
                                                        onClick={() => setShowCouponDeleteModal(false)}
                                                    >
                                                        No, i just clicked it
                                                    </button>
                                                </div>
                                            </div>
                                            <img width={124} src={pigImg} alt="" />
                                        </div>
                                    </Modal.Body>
                                </div>
                            </Modal>
                        </div>
                        <div className="playlistTable">
                            {/* {ActivitiesByCatIdInfo.loading === true ? (
                                      null
                                  ) : activitiesState.data.length === 0 ? (<p style={{color: "red"}}>no activities found</p>) : ( */}
                            <div className="row tableMain">
                                <TableListElement />
                            </div>
                            {/* )} */}
                        </div>
                        <button
                            className="addPlaylistBtn primaryBtn"
                            onClick={() => setShowCouponAddModel(true)}
                        >
                            Add Coupon
                        </button>
                    </div>
                    <Modal show={showCouponAddModel} onHide={() => setShowCouponAddModel(false)}>
                        <div className="modelMain">
                            <Modal.Body style={{}} className="modelBody">
                                <div className="row container m-2">
                                    <label
                                        className="primaryText"
                                        style={{ marginBottom: 20, fontSize: "25px" }}
                                    >
                                        Add Coupon
                                    </label>

                                    <div className="col-12 catName m-0">
                                        <label>Coupon Name:</label>
                                        <input
                                            className="catInput"
                                            placeholder="Type Coupon Name Here"
                                            required
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        ></input>
                                    </div>

                                    <div className="col-12 catName m-0">
                                        <label>Maximum times Coupon can be used:</label>
                                        <input
                                            className="catInput"
                                            placeholder="Maximum times Coupon can be used"
                                            required
                                            value={maxUsers}
                                            onChange={(e) => setMaxUsers(e.target.value)}
                                        ></input>
                                    </div>

                                    <div className="col-12 catName m-0 ">
                                        <label>Valid Till: </label>
                                        <input
                                            className="catInput"
                                            required
                                            type="date"
                                            value={validTill}
                                            // step="4"
                                            min={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
                                            onChange={(e) => setValidTill(e.target.value)}
                                        ></input>
                                    </div>
                                    <div className="col-12 catName m-0 ">
                                        <label>Is Valid Once Per User: </label>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <input
                                                name="IsValidOncePerUser"
                                                className="catInput"
                                                required
                                                type="radio"
                                                value={isValidOncePerUser}
                                                checked={isValidOncePerUser == true}
                                                onChange={(e) => setIsValidOncePerUser(true)}
                                            />
                                            <div style={{ marginLeft: 10, marginRight: 10 }}>Yes</div>
                                            <input
                                                name="IsValidOncePerUser"
                                                className="catInput"
                                                required
                                                type="radio"
                                                value={isValidOncePerUser}
                                                checked={isValidOncePerUser == false}
                                                onChange={(e) => setIsValidOncePerUser(false)}
                                            />
                                            <div style={{ marginLeft: 10, marginRight: 10 }}>No</div>
                                        </div>
                                    </div>
                                    <div className="col-12 catName m-0 ">
                                        <label>Discount Type: </label>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <input
                                                name="discountType"
                                                className="catInput"
                                                required
                                                type="radio"
                                                checked={discountType == "FLAT"}
                                                onChange={(e) => setDiscountType("FLAT")}
                                            />
                                            <div style={{ marginLeft: 10, marginRight: 10 }}>Flat Off</div>
                                            <input
                                                name="discountType"
                                                className="catInput"
                                                required
                                                type="radio"
                                                checked={discountType == "PERCENTAGE"}
                                                onChange={(e) => setDiscountType("PERCENTAGE")}
                                            />
                                            <div style={{ marginLeft: 10, marginRight: 10 }}>Percentage Off</div>
                                        </div>
                                        <div className="col-12 catName m-0 ">
                                            <label>Value: </label>
                                            <input
                                                className="catInput"
                                                placeholder="Type Value Here"
                                                required
                                                type="number"
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="modelFooter justify-content-center">
                                    <button
                                        className="mainButton modelBtn"
                                        variant="secondary"
                                        onClick={() => {
                                            setShowCouponAddModel(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="mainButton modelBtn"
                                        variant="primary"
                                        onClick={handleCouponAdd}
                                    >
                                        Save
                                    </button>
                                </div>
                            </Modal.Body>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    );
}
