import API from "../../axios/api";
import axios from "axios";

const BASE_LINK = `${API.Ecommerce}`;


// export const addproduct = async (formData) => {
//     return await axios.post(`${BASE_LINK}/shop/product/create`, formData);
// };

// export const addproductVariants = async (id, formData) => {
//     return await axios.post(`${BASE_LINK}/shop/product/variants/create`, formData);
// };

export const getallOrders = async () => {
    return await axios.get(`${BASE_LINK}/shop/orders/getAll`);
};

export const updateOrderStatus = async (id, formdata) => {
    console.log("form", formdata)
    return await axios.post(`${BASE_LINK}/shop/orders/updateStatus/${id}`, formdata);
};