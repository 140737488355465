import React, { useContext, useState } from "react";
import tab1 from "../Assets/images/categories.png";
import tab2 from "../Assets/images/activity.png";
import tab3 from "../Assets/images/playlist.png";
import tab4 from "../Assets/images/report.png";
import { NavLink } from "react-router-dom";
import styles from "../Assets/Styling/sidebar.module.css";
import { authContext } from "../App";
import { useNavigate } from "react-router-dom";
import { ecommerceList } from "../Assets/data/ecommercedata";
import { RiArrowDropDownLine } from "react-icons/ri";

export default function Sidebar() {
  const [isAuthorized, setIsAuthorized] = useContext(authContext);
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    console.log("click", clicked);
    console.log("ind", index);
    if (clicked === index) {
      //if clicked question is already active, then close it
      setClicked(index);
      return setClicked(null);
    }

    setClicked(index);
  };

  const handleLogout = () => {
    localStorage.removeItem("auth_token");
    setIsAuthorized(false);
    navigate("/");
  };

  // console.log("sdfds", ecommerceList);

  return (
    <div className="sidenav-outer">
      <div className="sidenavTabs">
        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/categories"
        >
          {/* <div className={"col tab nav-link"}> */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab1}></img>
            <h4>Categories</h4>
          </div>
          {/* </div>? */}
        </NavLink>
        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/activities"
        >
          {/* <div className={"col tab nav-link"}> */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab2}></img>
            <h4>Activity</h4>
          </div>
          {/* </div> */}
        </NavLink>

        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/playlists"
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab3}></img>
            <h4>Playlist</h4>
          </div>
          {/* </div> */}
        </NavLink>
        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/subscriptions"
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>Subscription</h4>
          </div>
          {/* </div> */}
        </NavLink>
        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/couponsView"
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>Coupons</h4>
          </div>
          {/* </div> */}
        </NavLink>
        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/awards"
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>Awards</h4>
          </div>
          {/* </div> */}
        </NavLink>
        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/reportings"
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>Reporting</h4>
          </div>
          {/* </div> */}
        </NavLink>
        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/taxprefix-table"
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>Tax Prefix</h4>
          </div>
        </NavLink>
        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/universe-table"
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>Universal Table</h4>
          </div>
        </NavLink>
        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/taxtype-table"
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>Tax Type Table</h4>
          </div>
        </NavLink>
        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/gst-details"
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>GST Details</h4>
          </div>
        </NavLink>
        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/tax-details"
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>Tax Details</h4>
          </div>
        </NavLink>
        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/importantLinks"
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>Important Links</h4>
          </div>
          {/* </div> */}
        </NavLink>
        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/banner"
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>Banners</h4>
          </div>
          {/* </div> */}
        </NavLink>
        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/stickers"
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>stickers</h4>
          </div>
          {/* </div> */}
        </NavLink>

        {ecommerceList.map((item, index) => {
          console.log("ind", index);
          return (
            // <>
            <li
              className="col tab nav-link"
              onClick={() => toggle(index)}
              key={index}
            >
              <div className="tab-inner mt-2 d-flex justify-content-between align-items-center">
                <div className="d-flex submenu">
                  <img className={styles.sidebarIcon} src={tab4}></img>
                  <h4 className="menu-title">{item.catname}</h4>
                </div>

                <div>
                  {clicked === index ? (
                    <RiArrowDropDownLine className="dropdown-icon" />
                  ) : (
                    <RiArrowDropDownLine className="dropdown-icon" />
                  )}
                </div>
              </div>

              {clicked === index ? (
                <ul className="submenu-category-list">
                  {item.subcategory.map((subcat, index) => {
                    return (
                      <>
                        <li className="submenu-category">
                          <NavLink
                            activeClassName="active"
                            to={`/${subcat.url}`}
                            className="submenu-title nav-link"
                            key={index}
                            onClick={() => toggle(index)}
                          >
                            {subcat.subcatname}
                          </NavLink>
                        </li>
                      </>
                    );
                  })}
                </ul>
              ) : null}
            </li>
            // </>
          );
        })}

        {/* <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/podcasts/podcastList"
        >
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>Podcasts</h4>
          </div>
        </NavLink> */}

        <NavLink
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          to="/notifications"
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>In App Notifications</h4>
          </div>
          {/* </div> */}
        </NavLink>

        <div
          activeClassName="active"
          style={{ textDecoration: "none" }}
          className="col tab nav-link"
          onClick={() => handleLogout()}
        >
          {/* <div className={"col tab nav-link"} > */}
          <div className="tab-inner mt-2">
            <img className={styles.sidebarIcon} src={tab4}></img>
            <h4>Logout</h4>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
