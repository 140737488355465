import API from '../axios/api'
import axios from 'axios'

const BASE_LINK = `${API.BASE_LINK}/coupon`

export const getAllCoupons = async () => {
    return await axios.get(BASE_LINK + '/')
}
export const createCoupons = async (obj) => {
    return await axios.post(BASE_LINK + `/`, obj)
}
export const deleteSingleCoupons = async (id) => {
    return await axios.delete(BASE_LINK + `/deleteById/${id}`)
}
export const updateSingleCoupons = async (id) => {
    return await axios.patch(BASE_LINK + `/updateStatus/${id}`)
}
