import React, { useState, useEffect } from "react";
import { TextField, Grid } from "@mui/material";
import styles from "../ecommerce/ecommerce.module.css";
import { useNavigate } from "react-router";
import { addUniverseTable } from "../../../services/GstDetails/gstDetails";

const UniverseForm = () => {
  const navigate = useNavigate();
  const initialValues = { entityCode: "", valueCode: "", valueDesc: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  const submithandler = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    const formdata = new FormData();
    formdata.append("name", formValues.entityCode);
    formdata.append("description", formValues.valueCode);
    formdata.append("type", formValues.valueDesc);
    if (formValues.entityCode && formValues.valueCode && formValues.valueDesc) {
      //   console.log(formValues, "formValues")
      let { data: res } = await addUniverseTable(formValues);
      console.log("data", res);
      if (res.success == true) {
        setTimeout(() => {
          alert("Universe Field Added Successfully");
          navigate(`/universe-table`);
        }, 800);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.entityCode) {
      errors.entityCode = "entityCode is Required";
    }

    if (!values.valueCode) {
      errors.valueCode = "valueCode is Required";
    }
    if (!values.valueDesc) {
      errors.valueDesc = "valueDesc is Required";
    }
    return errors;
  };

  return (
    <div className={styles.container}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="entityCode"
            value={formValues.entityCode}
            onChange={handleChange}
            className={styles.inputs}
            label="Entity Code *"
            variant="outlined"
          />
          <small className="text-red">{formErrors.entityCode}</small>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            value={formValues.valueCode}
            onChange={handleChange}
            className={styles.inputs}
            label="Value Code*"
            variant="outlined"
            name="valueCode"
          />
          <p className="text-red">{formErrors.valueCode}</p>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            value={formValues.valueDesc}
            onChange={handleChange}
            className={styles.inputs}
            label="Value Desc*"
            variant="outlined"
            name="valueDesc"
          />
          <p className="text-red">{formErrors.valueDesc}</p>
        </Grid>

        <Grid item xs={12}>
          <button className={styles.button} onClick={submithandler}>
            Save Details
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default UniverseForm;
