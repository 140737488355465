import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import styles from "../ecommerce/ecommerce.module.css";
import { BiEdit } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import Switch from "@mui/material/Switch";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { parentPodStatus, getallParentPods } from "../../../services/Podcasts/podcasts";

const ParentPodcastList = () => {
  const navigate = useNavigate();
  const [datalist, setDatalist] = useState([]);
  const [checked, setchecked] = useState(false);

  const getpodcast = async () => {
    let { data: res } = await getallParentPods();
    setDatalist(res.data);
  };

  const handleChangeSwitch = async (id) => {
    let { data: res } = await parentPodStatus(id);
    console.log("checked value", res.data);
    setchecked(!checked);
  };

  const sendEditBook = (row) => {
    navigate(`/podcasts/updateParentPodcast/${row._id}`, { state: row });
  };


  useEffect(() => {
    getpodcast();
  }, [checked]);

  console.log(datalist)

  return (
    <>
      <div className={styles.container}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <p className={styles.headertext}>Parent Pods List</p>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end"
            }}
          >
            <Link to="/podcasts/addParentPodcast">
              <button className="button2">Add New Podcasts</button>
            </Link>
          </Grid>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Pod Name</TableCell>
                <TableCell align="center">Img</TableCell>
                <TableCell align="center">Moral</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {datalist?.map((row, index) => (
                <TableRow
                  style={{ backgroundColor: "white" }}
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.podcastName}</TableCell>
                  <TableCell align="center">
                    <img
                      src={row.imagePath}
                      alt={row.product_name}
                      height="40"
                      width="50"
                    />
                  </TableCell>

                  <TableCell align="center">{row.moral}</TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={row.status}
                      onChange={() => handleChangeSwitch(row._id)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <div>
                      <Link to={`/podcasts/parentaddAudio/${row?._id}`}>
                        <FaPlus className="product-update product-delete product-add" />
                      </Link>
                      {/* <MdDelete
                        onClick={() => delModelHandle(row._id)}
                        className="product-delete"
                      /> */}

                      <BiEdit
                        onClick={() => {
                          sendEditBook(row);
                        }}
                        className="product-update"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default ParentPodcastList;
