import API from "../../axios/api";
import axios from "axios";

const BASE_LINK = `${API.Ecommerce}/shop/category`;

export const allcategories = async () => {
    return await axios.get(`${BASE_LINK}/allcategories`);
};

export const allsubcategories = async (id) => {
    return await axios.get(`${BASE_LINK}/subcategories/${id}`);
};

export const allsubcategoriesByCategoties = async (id) => {
    return await axios.post(`${BASE_LINK}/subcategories/byCategories`, id);
};

export const categoryById = async (id) => {
    console.log(id);
    return await axios.get(`${BASE_LINK}/findbyid/${id}`);
};

export const newcategory = async (formData) => {
    return await axios.post(`${BASE_LINK}/create`,formData);
};

export const categoryStatus = async (id) => {
    console.log("id",id);
    return await axios.patch(`${BASE_LINK}/updatestatus/${id}`);
};

export const categoryDelete = async (id) => {
    return await axios.delete(`${BASE_LINK}/deletebyid/${id}`);
};


export const updatecategory = async (id, formData) => {
    console.log("Id============================>", id)
    return await axios.patch(`${BASE_LINK}/updateById/${id}`,formData);
};
