import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Swal from 'sweetalert2'
import { getAllMissions } from '../../../services/AllMission'
import { getAllVideos } from '../../../services/Allvideo'
import { getdeleteduser } from '../../../services/deletedusers'
import { getRegisteredParents } from '../../../services/User'
import { getAllWallet } from '../../../services/Wallet'
import styles from './activity.module.css'
import Videoexport from './videoexport'
export default function AllVideos() {
    const [parentsArr, setParentsArr] = useState([])
    const [showExportModal, setExportModal] = useState(false);

    let navigate = useNavigate()
    let id = useParams()
     
    const handleShowExportMod = () => {
        setExportModal(!showExportModal);
    }
    const getUser = async () => {
        try {
            let { data: res } =await getAllVideos()
                console.log(res.data,"hiii")
            if (res.success == true) {
                setParentsArr(res?.data)

            }
        } catch (error) {
            console.error(error)
            if (error?.response?.data?.message) {
                Swal.fire(error.response.data.message)
            } else {
                // Swal.fire(error?.message, "this is errore");
            }
        }
    }




    useEffect(() => {
        getUser()
        // getActivityByCategoryId();
    }, [])


    const TableListElement = () => {
        return (
            <table id='resultTable' cellspacing="100" style={{cellPadding: 100}} width="100%">
                <thead id='first' className='table-title'>
                    <tr>
                        <th>Video Header</th>
                        <th>Parent Name</th>
                        <th>Phone</th>
                        <th>Kids Name</th>
                        <th>Video Status</th>
                        {/* <th>Mission age group</th> */}
                        <th>Reward</th>
                        <th>Bonus</th>

                        <th>Created On</th>

                        {/* <th>View Kids</th> */}
                        {/* <th>Registered On</th> */}
                        {/* <th>No. of Kids</th> */}
                        {/* <th>View Kids</th> */}
                    </tr>
                </thead>
                <tbody>
                    {parentsArr.map((el, index) => (
                        <tr key={index}>
                             <td>
                                <div className="d-flex">
                                    <p
                                        className="tableInputTxt"
                                        style={{ marginLeft: "-1em" }}
                                        type="text"
                                        name="column_1"
                                    >
                                         {el?.videoHeading}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div className="d-flex">
                                    <p
                                        className="tableInputTxt"
                                        style={{ marginLeft: "-1em" }}
                                        type="text"
                                        name="column_1"
                                    >
                                         {el?.userdetails[0]?.firstName+el?.userdetails[0]?.lastName}
                                    </p>
                                </div>
                            </td>

                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                    {el?.userdetails[0]?.phone}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                        {el?.kiddetails[0]?.firstName}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div className='d-flex'>
                                    <p className='tableInputTxt' style={{ marginLeft: "-1em" }} type='text' name='column_1'>
                                        {el.status}
                                    </p>
                                </div>
                            </td>
                            {/* <td>
                                <div  className='d-flex'>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                    {el?.attributeArr[0].ageGroupObj.startAge+"-"+el?.attributeArr[0].ageGroupObj.endAge}
                                    </p>
                                </div>
                            </td> */}
                            <td>
                                <div  className='d-flex'>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                    {el?.reward}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div  className='d-flex'>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                    {el?.bonus}
                                    </p>
                                </div>
                            </td>
                            <td>
                                <div  className='d-flex'>
                                    <p className='tableInputTxt' type='text' name='column_1'>
                                    {new Date(el?.createdAt).toDateString()}
                                    </p>
                                </div>
                            </td>
                            {/* <td>
                                <div className='d-flex'>
                                    <button
                                        className='addPlaylistBtn primaryBtn'
                                        onClick={() => navigate(`/viewKids/${el?._id}`)}
                                    >
                                        View Kids
                                    </button>
                                </div>
                            </td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    return (
        <div className='home-outer'>
                                 <Videoexport show={showExportModal} handleClose={handleShowExportMod}/>
            <div
                className={'container col playlist-outer pt-5 ' + styles.activityInnner}
            >
                <div className='row'>
                    {/* <h2>Category Name</h2> */}
                    <div style={{ padding: '8rem' }} className='col-md'>
                        <div className='row'>
                            <h1 style={{ padding: 0 }} className={styles.screenTitle}>
                                {parentsArr?.name}
                            </h1>
                            <div className='col playlistTitle p-0'>
                                <h3 style={{ padding: 0 }}>All Videos </h3>
                            </div>
                            <div className='col playlistTitle p-0' style={{ alignItems: "flex-end", justifyContent: "flex-end", display: "flex" }}>
                                <h3 style={{ padding: 0, width: "max-content" }}>Total Videos : {parentsArr.length}</h3>
                            </div>
                            <div className='col playlistTitle p-0' style={{ alignItems: "flex-end", justifyContent: "flex-end", display: "flex" }}>
                                <button
                                    className="addPlaylistBtn primaryBtn"
                                    onClick={() => handleShowExportMod()}  
                                    >
                                    Export Report
                                </button>
                            </div>


                        </div>
                        <div className='playlistTable'>
                            {/* {ActivitiesByCatIdInfo.loading === true ? (
                                      null
                                  ) : activitiesState.data.length === 0 ? (<p style={{color: "red"}}>no activities found</p>) : ( */}
                            <div className='row tableMain'>
                                <TableListElement />
                            </div>
                            {/* )} */}
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}
