export const ecommerceList = [
    {
      id: 1,
      catname: "Ecommerce",
      subcategory: [
        {
          subcatname: "Order History",
          url: "ecommerce/orders"
        },
        {
          subcatname: "Category Management",
          url: "ecommerce/category"
        },
        {
          subcatname: "Product Management",
          url: "ecommerce/product"
        },
      ],
    },
    {
      id: 2,
      catname: "Story Books",
      subcategory: [
        {
          subcatname: "Book List",
          url: "storybooks/bookList"
        },
        {
          subcatname: "Faq List",
          url: "storybooks/faqList"
        },
      ],
    },
    {
      id: 3,
      catname: "Quizzess",
      subcategory: [
        {
          subcatname: "Quiz Group",
          url: "quizzess/quizGroup"
        },
        {
          subcatname: "Quiz List",
          url: "quizzess/quizList"
        },
      ],
    },
    {
      id: 3,
      catname: "Podcasts",
      subcategory: [
        {
          subcatname: "Parent Pods",
          url: "podcasts/parentPodCastList"
        },
        {
          subcatname: "Kid Pods",
          url: "podcasts/podcastList"
        },
      ],
    }
  ];
  