import React, { useEffect, useState, useRef } from "react";
import { TextField, Grid, Modal, MenuItem } from "@mui/material";
import styles from "../ecommerce/ecommerce.module.css";
import Files from "react-files";
import uploads from "../../../Assets/images/uploads.png";
import { addBooks } from "../../../services/StoryBooks/books";
import { useNavigate } from "react-router";

const CreateBook = () => {
  const navigate = useNavigate();
  const [src, setSrc] = useState();
  const [files, setFiles] = useState({});

  const initialValues = {
    bookname: "",
    moral: "",
  };

  const [objectivesFields, setObjectivesFields] = useState([]);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  const addObjectiveFields = (e) => {
    e.preventDefault();
    let object = {
      objectiveName: "",
    };
    setObjectivesFields([...objectivesFields, object]);
  };

  const objHandleFormChange = (event, index) => {
    console.log(event, index);
    let data = [...objectivesFields];
    data[index][event.target.name] = event.target.value;
    setObjectivesFields(data);
    console.log("childFormFields", objectivesFields);
  };

  const submithandler = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    const formdata = new FormData();
    formdata.append("image", files);
    formdata.append("bookname", formValues.bookname);
    formdata.append("moral", formValues.moral);
    // formdata.append("objectives", objectivesFields);

    if (formValues.bookname && formValues.moral) {
      console.log("API HIT", formValues);
      console.log(objectivesFields, 'objectivesFields');
      let { data: res } = await addBooks(formdata);
      console.log("data", res);
      if (res.status === "success") {
        setTimeout(() => {
          alert("Book Added Successfully");
          navigate(`/storybooks/bookList`);
        }, 800);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.bookname) {
      errors.bookname = "Product Name is Required";
    }

    if (!values.moral) {
      errors.moral = "Description is Required";
    }
    if (values.moral.length > 150) {
      errors.moral = "Can't Add more than 500 Charatcers";
    }
    return errors;
  };

  return (
    <div className={styles.container}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="bookname"
            value={formValues.bookname}
            onChange={handleChange}
            className={styles.inputs}
            label="Book Name *"
            variant="outlined"
          />
          <small className="text-red">{formErrors.bookname}</small>
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="moral"
            value={formValues.moral}
            onChange={handleChange}
            className={styles.inputs}
            label="Moral*"
            variant="outlined"
          />
          <small className="text-red">{formErrors.moral}</small>
        </Grid>

        <Grid item xs={6}>
          {!files?.id && (
            <div className={styles.img}>
              <Files
                className="files-dropzone"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={[
                  "image/png",
                  "image/jpg",
                  "image/jpeg",
                  "image/webp",
                  "audio/*",
                ]}
                multiple
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <img src={uploads} height="170" width={"100%"} />
              </Files>
            </div>
          )}
          {files?.id && (
            <div className={styles.img}>
              <Files
                className="files-dropzone"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={["image/png", ".pdf", "audio/*"]}
                multiple
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <img src={src} height="170" width="170" />
              </Files>
            </div>
          )}
        </Grid>

        <Grid item xs={12}>
          <h3>Book Objectives</h3>

          {objectivesFields.map((data, index) => {
            return (
              <Grid item xs={6} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} key={index}>
                <TextField
                  id="outlined-basic"
                  name="objectiveName"
                  className={styles.inputs}
                  label="Objective *"
                  variant="outlined"
                  value={data.objectiveName}
                  onChange={(event) => objHandleFormChange(event, index)}
                />
                <small className="text-red">{formErrors.bookname}</small>
              </Grid>
            );
          })}

          <Grid item xs={6}>
          <button className={styles.button} onClick={addObjectiveFields}>
            Add Objective
          </button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <button className={styles.button} onClick={submithandler}>
            Save Details
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateBook;
