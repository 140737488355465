import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { TextField, Grid, MenuItem, colors } from "@mui/material";
import styles from "../ecommerce/ecommerce.module.css";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Files from "react-files";
import uploads from "../../../Assets/images/uploads.png";
import Swal from "sweetalert2";
import {
  addPodAudios,
  getAllAudios,
  audioStatus,
  editSerial
} from "../../../services/Podcasts/podcasts";
import Modal from "react-bootstrap/Modal";
import { BiEdit } from "react-icons/bi";
import Switch from "@mui/material/Switch";

const PodcastAudios = () => {
  const { id } = useParams();
  const navigate = useNavigate()

  const initValues = {
    podId: id,
    audioName: "",
    description: "",
    serialNumber: ""
  };
  const [checked, setchecked] = useState(false);
  const [formValues, setformValues] = useState(initValues);
  const [formErrors, setFormErrors] = useState({});
  const [activeId, setActiveId] = useState("");
  const [files, setFiles] = useState({});
  const [src, setSrc] = useState();
  const [serialNum, setSerialNum] = useState({
    ids: "",
    value: ""
  });

  console.log(id, "ididid");

  const [audio, setAudio] = useState({});
  const [variantsData, setVariantsData] = useState([]);
  const [checkdelete, setcheckdelete] = useState(true);
  const [allfaq, setAllfaq] = useState([]);
  const [fetchconnectFaqs, setFetchConnectFaqs] = useState([]);
  const [serialPop, setSerialPop] = useState(false);

  console.log(serialNum, "print serial num");

  const updateSerial = (pageData) => {
    console.log(pageData, "pageData");
    setActiveId(pageData?._id);
    setSerialPop(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setformValues({ ...formValues, [name]: value });
  };

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const addFile = (audios) => {
    console.log(audios, "lolp");
    setAudio(audios[0]);
    const audio = audios.map((item) => URL.createObjectURL(item));
  };

  console.log(audio, "audio files after upload");

  const submithandler = (e) => {
    e.preventDefault();
    // setFormErrors(validate(formValues));
    // setIsSubmit(true);
    handleSubmit();
  };

  const handleSubmit = async () => {
    const formdata = new FormData();
    formdata.append("image", files);
    formdata.append("audio", audio);
    formdata.append("podId", formValues.podId);
    formdata.append("audioName", formValues.audioName);
    formdata.append("description", formValues.description);
    formdata.append("serialNumber", formValues.serialNumber);

    let { data: res } = await addPodAudios(formdata);
    console.log("response add var", res);
    if (res.status) {
      getAudioData();
      Swal.fire(res.message);
    }
  };

  const getAudioData = async () => {
    let { data: res } = await getAllAudios(id);
    console.log("data", res.data);
    setVariantsData(res.data);
  };

  console.log(variantsData, "variantsData");

  const handleChangeSwitch = async (id) => {
    let { data: res } = await audioStatus(id);
    console.log("checked value", res.data);
    setchecked(!checked);
  };

  useEffect(() => {
    getAudioData();
  }, [checkdelete, checked]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (
      !/^[a-z A-Z0-9]+$/.test(values.audioName) ||
      /^[ ]+$/.test(values.audioName)
    ) {
      errors.audioName = "Name is not valid";
    }

    if (!values.audioName) {
      errors.audioName = "Name is Required";
    }

    if (!values.serialNumber) {
      errors.serialNumber = "Serial Number is Required";
    }
    return errors;
  };

  const updateAudios = (row) => {
    navigate(`/podcasts/${id}/audio/${row._id}`, { state: row });
  };

  return (
    <>
      <div className={styles.container}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              value={formValues.audioName}
              onChange={handleChange}
              className={styles.inputs}
              label="Audio Name *"
              variant="outlined"
              name="audioName"
            />
            <p className="text-red">{formErrors.audioName}</p>
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              value={formValues.serialNumber}
              onChange={handleChange}
              className={styles.inputs}
              label="Reserve Quantity *"
              variant="outlined"
              name="serialNumber"
            />
            <p className="text-red">{formErrors.serialNumber}</p>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              value={formValues.description}
              onChange={handleChange}
              className={styles.inputs}
              label="Podcast Description *"
              variant="outlined"
              name="description"
            />
            <p className="text-red">{formErrors.description}</p>
          </Grid>

          <Grid item xs={6}>
            {!files?.id && (
              <div className={styles.img}>
                <Files
                  className="files-dropzone"
                  onChange={onFilesChange}
                  onError={onFilesError}
                  accepts={[
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                    "image/webp"
                  ]}
                  multiple
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                >
                  <img src={uploads} height="170" width={"100%"} />
                </Files>
              </div>
            )}
            {files?.id && (
              <div className={styles.img}>
                <Files
                  className="files-dropzone"
                  onChange={onFilesChange}
                  onError={onFilesError}
                  accepts={[
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                    "image/webp"
                  ]}
                  multiple
                  maxFileSize={30000000}
                  minFileSize={0}
                  clickable
                >
                  <img src={src} height="170" width="170" />
                </Files>
              </div>
            )}
          </Grid>

          {/* addd audio file here */}

          <Grid item xs={6}>
            {!audio?.id && (
              <div className={styles.img}>
                <Files
                  className="files-dropzone"
                  onChange={addFile}
                  onError={onFilesError}
                  accepts={["audio/*"]}
                  multiple
                  maxFileSize={300000000}
                  minFileSize={0}
                  clickable
                >
                  {/* <img src={uploads} height="170" width={"100%"} /> */}
                  <h3
                    style={{
                      height: "160px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    Upload Audio here
                  </h3>
                </Files>
              </div>
            )}
            {audio?.id && (
              <div className={styles.img}>
                <Files
                  className="files-dropzone"
                  onChange={addFile}
                  onError={onFilesError}
                  accepts={["audio/*"]}
                  multiple
                  maxFileSize={300000000}
                  minFileSize={0}
                  clickable
                >
                  {/* <img src={src} height="170" width="170" /> */}
                  <h3
                    style={{
                      height: "160px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    Audio Uploaded
                  </h3>
                </Files>
              </div>
            )}
          </Grid>

          {/* <Grid item xs={6}>
          <h3>Add Audio File here</h3>
        <input type='file' onChange={addFile} /> 
        </Grid> */}

          {/*  Variants */}

          {/* size */}
          <Grid
            width={"95%"}
            style={{ display: "flex", marginLeft: "25px" }}
          ></Grid>
          <Grid item xs={12}>
            <button className={styles.button} onClick={submithandler}>
              Save Page
            </button>
          </Grid>
        </Grid>
      </div>

      <div className={styles.container}>
        <div className="myaccount-table table-responsive text-center">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th>Page Name</th>
                <th>Serial No.</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {variantsData.map((variants, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {variants?.audioName}, {variants?._id}
                    </td>
                    <td>
                      {variants.serialNum} &nbsp; &nbsp;
                      <BiEdit
                        onClick={() => {
                          updateSerial(variants);
                        }}
                        className="product-update"
                      />
                      {/* <div className="serial-num">
                        <input type="text"
                        value={serialNum.value}
                        name={variants._id}
                        onChange={(e) => handleSerial(variants._id, e)}
                        />
                      </div> */}
                    </td>
                    <td>
                      {" "}
                      <img
                        src={variants.imagePath}
                        alt={variants.audioName}
                        height="40"
                        width="50"
                      />
                    </td>
                    <td>
                      {/* </Link> */}
                      <MdDelete
                        // onClick={() => delModelHandle(variants._id)}
                        className="product-delete"
                      />

                      <BiEdit
                        onClick={() => {
                          updateAudios(variants);
                        }}
                        className="product-update"
                      />

                      <Switch
                        checked={variants.status}
                        onChange={() => handleChangeSwitch(variants._id)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <Grid item xs={12}>
        <Link to="/podcasts/podcastList" className={styles.button}>
          PodCast List
        </Link>
      </Grid>
      <UpdateSerial
        show={serialPop}
        handleShow={() => setSerialPop(!serialPop)}
        activeId={activeId}
        handleSubmit={editSerial}
        getBookData={getAudioData}
      />
    </>
  );
};

export default PodcastAudios;

const UpdateSerial = ({
  show,
  handleShow,
  activeId,
  handleSubmit,
  getBookData
}) => {
  const [serailNum, setSerialNum] = useState("");

  const editSubmitHandler = async () => {
    try {
      console.log(serailNum, "serailNum");
      const formdata = new FormData();
      let obj = {
        number: serailNum
      };
      formdata.append("serailNum", serailNum);
      let { data: res } = await handleSubmit(activeId, obj);
      if (res.success) {
        handleShow(false);
        getBookData();
      }
      console.log(res, "res for add fqaqs");
    } catch (err) {
      Swal.fire(err.response.statusText);
      console.log(err.response.statusText, "err at update sreial");
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => handleShow(false)}>
        <div className="modelMain  modal-dialog-centered ">
          <Modal.Body className="modelBody ">
            <div className="container m-2">
              <div className="catName m-0">
                <label>Update Serial Number:</label>
                <Grid item xs={6}>
                  <input
                    type="text"
                    value={serailNum}
                    name="serialNum"
                    onChange={(e) => setSerialNum(e.target.value)}
                  />
                  <br />
                </Grid>
              </div>
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                  handleShow(false);
                }}
              >
                Cancel
              </button>
              <button
                className="mainButton modelBtn "
                variant="primary"
                onClick={editSubmitHandler}
              >
                Update
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
