// getParents
import API from "../axios/api";
import axios from "axios";

const BASE_LINK = `${API.BASE_LINK}/reports`;


export const getAllParents = async () => {
    return await axios.get(`${BASE_LINK}/getAllUsers`);
};

export const getAllParentsByDate = async (data) => {
    return await axios.post(`${BASE_LINK}/getAllUsers/byDate`, data);
};