
import styles from "./stickers.module.css";
import GGPLOGO from "../../../Assets/images/new-logo.png";


import { stickerDelete } from "../../../services/sticker";

const DelModal = ({ closeDelModal, activeId, reload}) => {

  const handleDel = async (id) => {
      let { status: status } = await stickerDelete(id)
      if (status === 200) {
        reload();
        console.log("banner deleted", id);
        setTimeout(closeDelModal, 400);
      }
  }

  return (
    <>
      <div className={styles.wrapper} onClick={closeDelModal}></div>
      <div className={styles.delmodal}>
        <div className={styles.delpopup}>
          <img className={styles.logo} src={GGPLOGO} alt="logo" />
          <h6>Are your sure you want to delete this sticker.</h6>
          <button className={styles.delMOdbutton} onClick={closeDelModal} >No</button>
          <button className={styles.delMOdbutton} onClick={() => handleDel(activeId)}>Yes</button>
        </div>
      </div>
    </>
  )
}

export default DelModal;