// import React from "react";
// import ReactDOM from "react-dom";

// const Backdrop = (props) => {
//   return ReactDOM.createPortal(
//     <div className="backdrop" onClick={props.onClick}></div>,
//     document.getElementById("backdrop-hook")
//   );
// };

// export default Backdrop;

import React from "react";
import ReactDOM from "react-dom";

const Backdrop = (props) => {
  return ReactDOM.createPortal(
    <div class="loader-element backdrop" onClick={props.onClick}>
      <div class="custom-loader"></div>
    </div>,
    document.getElementById("backdrop-hook")
  );
};

export default Backdrop;
