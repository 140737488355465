import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { TextField, Grid, MenuItem, colors } from "@mui/material";
import styles from "../ecommerce/ecommerce.module.css";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import Files from "react-files";
import uploads from "../../../Assets/images/uploads.png";
import Swal from "sweetalert2";
import {
  addBookPages,
  getBookPages,
  addFaqs,
  getallFaqs,
  getPagesFaqs,
  editSerial,
  pageStatus,
  updatePage
} from "../../../services/StoryBooks/books";
import { FaPlus } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { BiEdit } from "react-icons/bi";
import Switch from "@mui/material/Switch";

const CreatePages = () => {
  const { id } = useParams();

  const initValues = {
    bookId: id,
    pageName: "",
    serialNumber: ""
  };
  const [checked, setchecked] = useState(false);
  const [formValues, setformValues] = useState(initValues);
  const [formErrors, setFormErrors] = useState({});
  const [delPopup, setDelPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [files, setFiles] = useState({});
  const [src, setSrc] = useState();
  const [serialNum, setSerialNum] = useState({
    ids: "",
    value: ""
  });

  const [audio, setAudio] = useState({});
  const [variantsData, setVariantsData] = useState([]);
  const [checkdelete, setcheckdelete] = useState(true);
  const [objPop, setObjPop] = useState(false);
  const [allfaq, setAllfaq] = useState([]);
  const [fetchconnectFaqs, setFetchConnectFaqs] = useState([]);
  const [serialPop, setSerialPop] = useState(false);
  const [editPopup, setEditPopup] = useState(false);

  console.log(serialNum, "print serial num");

  const updateSerial = (pageData) => {
    console.log(pageData, "pageData");
    setActiveId(pageData?._id);
    setSerialPop(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setformValues({ ...formValues, [name]: value });
  };

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const addFile = (audios) => {
    console.log(audios, "lolp");
    setAudio(audios[0]);
    // const audio = audios.map((item) => URL.createObjectURL(item));
    // setSrc(audio);
  };

  console.log(audio, "audio files after upload");

  const submithandler = (e) => {
    e.preventDefault();
    // setFormErrors(validate(formValues));
    // setIsSubmit(true);
    handleSubmit();
  };

  const handleSubmit = async () => {
    const formdata = new FormData();
    formdata.append("image", files);
    formdata.append("audio", audio);
    formdata.append("bookId", formValues.bookId);
    formdata.append("pageName", formValues.pageName);
    formdata.append("serialNumber", formValues.serialNumber);

    let { data: res } = await addBookPages(formdata);
    console.log("response add var", res);
    if (res.status) {
      getBookData();
      Swal.fire(res.message);
    }
  };

  const getBookData = async () => {
    let { data: res } = await getBookPages(id);
    console.log("data", res.data);
    setVariantsData(res.data);
  };

  console.log(variantsData, "variantsData");

  const fetchfaqsforSet = async () => {
    console.log(
      "==================>>>>>>>>>>>>>>><<<<<<<<<<<<<<<===================="
    );
    let { data: res } = await getallFaqs();
    res?.data?.forEach((element) => {
      element.label = element.question;
      element.value = element._id;
      // console.log(element, "elwement print for reacrh");
    });
    setAllfaq(res.data);
  };

  const handleChangeSwitch = async (id) => {
    let { data: res } = await pageStatus(id);
    console.log("checked value", res.data);
    setchecked(!checked);
  };

  useEffect(() => {
    fetchfaqsforSet();
  }, [objPop]);

  useEffect(() => {
    getBookData();
  }, [checkdelete, checked]);

  const connectFaqs = (data) => {
    console.log(data, "data");
    setActiveId(data?._id);
    getConnectFaqs(data?._id);
    setObjPop(true);
  };

  const getConnectFaqs = async (activeId) => {
    let { data: res } = await getPagesFaqs(activeId);
    console.log("data for faqs", res.data);
    setFetchConnectFaqs(res.data);
  };

  const sendEditPage = (data) => {
    setActiveId(data?._id);
    setEditPopup(true);
  };

  return (
    <>
      <div className={styles.container}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              value={formValues.pageName}
              onChange={handleChange}
              className={styles.inputs}
              label="Page Name *"
              variant="outlined"
              name="pageName"
            />
            <p className="text-red">{formErrors.pageName}</p>
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              value={formValues.serialNumber}
              onChange={handleChange}
              className={styles.inputs}
              label="Reserve Quantity *"
              variant="outlined"
              name="serialNumber"
            />
            <p className="text-red">{formErrors.serialNumber}</p>
          </Grid>

          <Grid item xs={6}>
            {!files?.id && (
              <div className={styles.img}>
                <Files
                  className="files-dropzone"
                  onChange={onFilesChange}
                  onError={onFilesError}
                  accepts={[
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                    "image/webp"
                  ]}
                  multiple
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                >
                  <img src={uploads} height="170" width={"100%"} />
                </Files>
              </div>
            )}
            {files?.id && (
              <div className={styles.img}>
                <Files
                  className="files-dropzone"
                  onChange={onFilesChange}
                  onError={onFilesError}
                  accepts={["image/png", ".pdf", "audio/*"]}
                  multiple
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                >
                  <img src={src} height="170" width="170" />
                </Files>
              </div>
            )}
          </Grid>

          {/* addd audio file here */}

          <Grid item xs={6}>
            {!audio?.id && (
              <div className={styles.img}>
                <Files
                  className="files-dropzone"
                  onChange={addFile}
                  onError={onFilesError}
                  accepts={["audio/*"]}
                  multiple
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                >
                  {/* <img src={uploads} height="170" width={"100%"} /> */}
                  <h3
                    style={{
                      height: "160px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    Upload Audio here
                  </h3>
                </Files>
              </div>
            )}
            {audio?.id && (
              <div className={styles.img}>
                <Files
                  className="files-dropzone"
                  onChange={addFile}
                  onError={onFilesError}
                  accepts={["audio/*"]}
                  multiple
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                >
                  {/* <img src={src} height="170" width="170" /> */}
                  <h3
                    style={{
                      height: "160px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    Audio Uploaded
                  </h3>
                </Files>
              </div>
            )}
          </Grid>

          {/* <Grid item xs={6}>
          <h3>Add Audio File here</h3>
        <input type='file' onChange={addFile} /> 
        </Grid> */}

          {/*  Variants */}

          {/* size */}
          <Grid
            width={"95%"}
            style={{ display: "flex", marginLeft: "25px" }}
          ></Grid>
          <Grid item xs={12}>
            <button className={styles.button} onClick={submithandler}>
              Save Page
            </button>
          </Grid>
        </Grid>
      </div>

      <div className={styles.container}>
        <div className="myaccount-table table-responsive text-center">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th>Page Name</th>
                <th>Serial No.</th>
                <th>Image</th>
                <th>Faq List</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {variantsData.map((variants, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {variants?.pageName}, {variants?._id}
                    </td>
                    <td>
                      {variants.serialNum} &nbsp; &nbsp;
                      <BiEdit
                        onClick={() => {
                          updateSerial(variants);
                        }}
                        className="product-update"
                      />
                      {/* <div className="serial-num">
                        <input type="text"
                        value={serialNum.value}
                        name={variants._id}
                        onChange={(e) => handleSerial(variants._id, e)}
                        />
                      </div> */}
                    </td>
                    <td>
                      {" "}
                      <img
                        src={variants.imagePath}
                        alt={variants.pageName}
                        height="40"
                        width="50"
                      />
                    </td>
                    <td>{variants?.faqs.length}</td>
                    <td>
                      {/* <Link to={`/storyBooks/book/${variants?._id}/pages`}> */}
                      <FaPlus
                        onClick={() => {
                          connectFaqs(variants);
                        }}
                        className="product-update product-add"
                      />
                      {/* </Link> */}
                      {/* <MdDelete
                        // onClick={() => delModelHandle(variants._id)}
                        className="product-delete"
                      /> */}

                      <BiEdit
                        onClick={() => {
                          sendEditPage(variants);
                        }}
                        className="product-update mx-2"
                      />

                      <Switch
                        checked={variants.status}
                        onChange={() => handleChangeSwitch(variants._id)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <Grid item xs={12}>
        <Link to="/storybooks/bookList" className={styles.button}>
          Book List
        </Link>
      </Grid>

      <AddFAQ
        show={objPop}
        handleShow={() => setObjPop(!objPop)}
        activeId={activeId}
        allfaq={allfaq}
        handleSubmit={addFaqs}
        fetchconnectFaqs={fetchconnectFaqs}
      />

      <UpdateSerial
        show={serialPop}
        handleShow={() => setSerialPop(!serialPop)}
        activeId={activeId}
        handleSubmit={editSerial}
        getBookData={getBookData}
      />

      <UpdatePage
        editPopup={editPopup}
        handleShow={() => setEditPopup(!editPopup)}
        activeId={activeId}
        updatePage={updatePage}
        getBookData={getBookData}
      />
    </>
  );
};

export default CreatePages;

const AddFAQ = ({
  show,
  handleShow,
  activeId,
  allfaq,
  handleSubmit,
  fetchconnectFaqs
}) => {
  // console.log(activeId, "activeId");

  // console.log(allfaq, "allfaq");

  const initialValues = {
    faq: []
  };
  const [formValues, setFormValues] = useState(initialValues);

  const handleMultiple = async (name, data) => {
    if (name === "faq") {
      console.log("running if part", data);
      setFormValues({ ...formValues, [name]: data });
    } else {
      console.log("running else part");
    }
  };

  const editSubmitHandler = async () => {
    const formdata = new FormData();
    formdata.append(
      "faqs",
      formValues.faq.map((x) => x._id)
    );
    // console.log("objectivesFields", formValues);
    let { data: res } = await handleSubmit(activeId, formdata);
    if (res.status == "success") {
      handleShow(false);
    }
    console.log(res, "res for add fqaqs");
  };

  return (
    <>
      <Modal show={show} onHide={() => handleShow(false)}>
        <div className="modelMain  modal-dialog-centered ">
          <Modal.Body className="modelBody ">
            <div className="container m-2">
              <div className="faqs-name">
                <h5>FAQ NAME LIST</h5>
                {fetchconnectFaqs.map((data, index) => {
                  return <p key={index}>{data.question}</p>;
                })}
              </div>
              <div className="catName m-0">
                <label>Connect FAQS:</label>
                <Grid item xs={6}>
                  <Select
                    isMulti
                    onChange={(data) => {
                      handleMultiple("faq", data);
                    }}
                    options={allfaq}
                    value={formValues.faq}
                  />
                  <br />
                </Grid>
              </div>
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                  handleShow(false);
                }}
              >
                Cancel
              </button>
              <button
                className="mainButton modelBtn "
                variant="primary"
                onClick={editSubmitHandler}
              >
                Update
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

const UpdateSerial = ({
  show,
  handleShow,
  activeId,
  handleSubmit,
  getBookData
}) => {
  const [serailNum, setSerialNum] = useState("");

  const editSubmitHandler = async () => {
    try {
      console.log(serailNum, "serailNum");
      const formdata = new FormData();
      let obj = {
        number: serailNum
      };
      formdata.append("serailNum", serailNum);
      let { data: res } = await handleSubmit(activeId, obj);
      if (res.success) {
        handleShow(false);
        getBookData();
      }
      console.log(res, "res for add fqaqs");
    } catch (err) {
      Swal.fire(err.response.statusText);
      console.log(err.response.statusText, "err at update sreial");
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => handleShow(false)}>
        <div className="modelMain  modal-dialog-centered ">
          <Modal.Body className="modelBody ">
            <div className="container m-2">
              <div className="catName m-0">
                <label>Update Serial Number:</label>
                <Grid item xs={6}>
                  <input
                    type="text"
                    value={serailNum}
                    name="serialNum"
                    onChange={(e) => setSerialNum(e.target.value)}
                  />
                  <br />
                </Grid>
              </div>
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                  handleShow(false);
                }}
              >
                Cancel
              </button>
              <button
                className="mainButton modelBtn "
                variant="primary"
                onClick={editSubmitHandler}
              >
                Update
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

const UpdatePage = ({
  editPopup,
  handleShow,
  activeId,
  updatePage,
  getBookData
}) => {
  const [files, setFiles] = useState({});
  const [src, setSrc] = useState();
  const [audio, setAudio] = useState({});

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const addFile = (audios) => {
    console.log(audios, "lolp");
    setAudio(audios[0]);
  };

  const editPageHandler = async () => {
    try {
      const formdata = new FormData();
      formdata.append("image", files);
      formdata.append("audio", audio);
      let { data: res } = await updatePage(activeId, formdata);
      console.log(res, "res for add fqaqs");

      if (res.status === "success") {
        handleShow(false);
        getBookData();
      }
    } catch (err) {
      Swal.fire(err.response.statusText);
      console.log(err.response.statusText, "err at update sreial");
    }
  };

  return (
    <>
      <Modal show={editPopup} onHide={() => handleShow(false)}>
        <div className="modelMain  modal-dialog-centered ">
          <Modal.Body className="modelBody">
            <div className="container m-2">
              <div className="uploadImg">
                <div>
                  <label>Upload Image</label>
                  <div className="upload-outer">
                    <>
                      <Grid item xs={6}>
                        {!files?.id && (
                          <div className={styles.inputs}>
                            <Files
                              className="files-dropzone"
                              onChange={onFilesChange}
                              onError={onFilesError}
                              accepts={[
                                "image/png",
                                "image/jpg",
                                "image/jpeg",
                                "image/webp"
                              ]}
                              multiple
                              maxFileSize={10000000}
                              minFileSize={0}
                              clickable
                            >
                              <img src={uploads} height="170" width={"100%"} />
                            </Files>
                          </div>
                        )}
                        {files?.id && (
                          <div className={styles.inputs}>
                            <Files
                              className="files-dropzone"
                              onChange={onFilesChange}
                              onError={onFilesError}
                              accepts={[
                                "image/png",
                                "image/jpg",
                                "image/jpeg",
                                "image/webp"
                              ]}
                              multiple
                              maxFileSize={10000000}
                              minFileSize={0}
                              clickable
                            >
                              <div style={{ background: "white", padding: 20 }}>
                                {src[0] &&
                                  src.map((item) => {
                                    return (
                                      <img
                                        src={item}
                                        height="110"
                                        width={"100"}
                                        style={{
                                          marginRight: 20,
                                          marginBottom: 20
                                        }}
                                      />
                                    );
                                  })}
                              </div>
                            </Files>
                          </div>
                        )}
                      </Grid>
                    </>
                  </div>
                </div>
                <div>
                  <label>Upload Audio</label>
                  <div className="upload-outer">
                    <>
                      <Grid item xs={6}>
                        {!audio?.id && (
                          <div className={styles.img}>
                            <Files
                              className="files-dropzone"
                              onChange={addFile}
                              onError={onFilesError}
                              accepts={["audio/*"]}
                              multiple
                              maxFileSize={10000000}
                              minFileSize={0}
                              clickable
                            >
                              {/* <img src={uploads} height="170" width={"100%"} /> */}
                              <h3
                                style={{
                                  height: "160px",
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                              >
                                Upload Audio here
                              </h3>
                            </Files>
                          </div>
                        )}
                        {audio?.id && (
                          <div className={styles.img}>
                            <Files
                              className="files-dropzone"
                              onChange={addFile}
                              onError={onFilesError}
                              accepts={["audio/*"]}
                              multiple
                              maxFileSize={10000000}
                              minFileSize={0}
                              clickable
                            >
                              <h3
                                style={{
                                  height: "160px",
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}
                              >
                                Audio Uploaded
                              </h3>
                            </Files>
                          </div>
                        )}
                      </Grid>
                    </>
                  </div>
                </div>
              </div>
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                  handleShow(false);
                }}
              >
                Cancel
              </button>
              <button
                className="mainButton modelBtn "
                variant="primary"
                onClick={editPageHandler}
              >
                Save
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
