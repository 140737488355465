import React, { useEffect, useState } from "react";
import { TextField, Grid } from "@mui/material";
import styles from "../ecommerce/ecommerce.module.css";
import Files from "react-files";
import uploads from "../../../Assets/images/uploads.png";
import { addQuizGroups } from "../../../services/StoryBooks/books";
import { useNavigate } from "react-router";

const AddGroup = () => {
  const navigate = useNavigate();
  const [src, setSrc] = useState();
  const [files, setFiles] = useState({});

  const initialValues = {
    groupName: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  const submithandler = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    const formdata = new FormData();
    formdata.append("image", files);
    formdata.append("groupName", formValues.groupName);

    if (formValues.groupName) {
      console.log("API HIT", formValues);
      let { data: res } = await addQuizGroups(formdata);
      console.log("data", res);
      if (res.status === "success") {
        setTimeout(() => {
          alert("Quiz Group Added Successfully");
          navigate(`/quizzess/quizGroup`);
        }, 200);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.groupName) {
      errors.groupName = "Group Name is Required";
    }

    return errors;
  };

  return (
    <div className={styles.container}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="groupName"
            value={formValues.groupName}
            onChange={handleChange}
            className={styles.inputs}
            label="Quiz Group Name *"
            variant="outlined"
          />
          <small className="text-red">{formErrors.groupName}</small>
        </Grid>

        <Grid item xs={6}>
          {!files?.id && (
            <div className={styles.img}>
              <Files
                className="files-dropzone"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={[
                  "image/png",
                  "image/jpg",
                  "image/jpeg",
                  "image/webp",
                  "audio/*",
                ]}
                multiple
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <img src={uploads} height="170" width={"100%"} />
              </Files>
            </div>
          )}
          {files?.id && (
            <div className={styles.img}>
              <Files
                className="files-dropzone"
                onChange={onFilesChange}
                onError={onFilesError}
                accepts={["image/png", ".pdf", "audio/*"]}
                multiple
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <img src={src} height="170" width="170" />
              </Files>
            </div>
          )}
        </Grid>

        <Grid item xs={12}>
          <button className={styles.button} onClick={submithandler}>
            Save Details
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddGroup;
