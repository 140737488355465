import API from "../../axios/api";
import axios from "axios";

const BASE_LINK = `${API.BASE_LINK}`;
const BASE_LINK2 = `${API.BASE_LINK2}`;

export const addBooks = async (formData) => {
  console.log(formData, "form data");
  return await axios.post(`${BASE_LINK}/storyBooks/books/createnew`, formData);
};

export const updateBook = async (id, formData) => {
  console.log(formData, "form data");
  return await axios.patch(`${BASE_LINK}/storyBooks/books/updateBook/${id}`, formData);
};

export const getallBooks = async () => {
  return await axios.get(`${BASE_LINK}/storybooks/books/getAllBooks`);
};

export const bookStatus = async (id) => {
  return await axios.get(`${BASE_LINK}/storyBooks/books/updateStatus/${id}`);
};


export const getBookObjects = async (bookId) => {
  console.log(bookId, 'book id')
  return await axios.get(`${BASE_LINK}/storyBooks/books/getObjective/${bookId}`)
}

export const addObjective = async (id, objectData) => {
  console.log(objectData, "form data", id);
  return await axios.patch(
    `${BASE_LINK}/storyBooks/books/addobjective/${id}`,
    objectData
  );
};

export const addBookPages = async (formData) => {
  return await axios.post(`${BASE_LINK}/storyBooks/pages/createnew`, formData);
};

export const getBookPages = async (id) => {
  return await axios.get(`${BASE_LINK}/storyBooks/pages/getAllPages/${id}`);
};

export const addFAQs = async (formData) => {
  console.log(formData, "form data");
  return await axios.post(`${BASE_LINK}/storyBooks/faqs/createnew`, formData);
};

export const getallFaqs = async () => {
  return await axios.get(`${BASE_LINK}/storybooks/faqs/getAllFaqs`);
};

export const getAllFaqsforList = async () => {
  return await axios.get(`${BASE_LINK}/storybooks/faqs/getAllFaqsforList`);
};

export const faqStatus = async (id) => {
  return await axios.get(`${BASE_LINK}/storyBooks/faqs/updateStatus/${id}`);
};

export const addFaqs = async (id, data) => {
  return await axios.post(
    `${BASE_LINK}/storybooks/pages/connectFaq/${id}`,
    data
  );
};

export const updatePage = async (id, formdata) => {
  console.log("form data", id);
  return await axios.patch(
    `${BASE_LINK}/storyBooks/pages/updatePage/${id}`,
    formdata
  );
};


export const getPagesFaqs = async (pageId) => {
  return await axios.get(
    `${BASE_LINK}/storybooks/pages/getPagesFaqs/${pageId}`
  );
};

export const editSerial = async (id, serialNum) => {
  console.log(serialNum, "form data", id);
  return await axios.patch(
    `${BASE_LINK}/storyBooks/pages/updateSerial/${id}`,
    serialNum
  );
};

export const pageStatus = async (id) => {
  return await axios.get(`${BASE_LINK}/storyBooks/pages/updateStatus/${id}`);
};


////////////////////////// QUIZZESSS APIS


export const addQuizGroups = async (formData) => {
  console.log(formData, "form data");
  return await axios.post(`${BASE_LINK}/quizzess/groups/createnewgroup`, formData);
};

export const updateQuizGroup = async (id, formData) => {
  console.log(formData, "form data");
  return await axios.patch(`${BASE_LINK}/quizzess/groups/updateGroup/${id}`, formData);
};

export const groupStatus = async (id) => {
  return await axios.get(`${BASE_LINK}/quizzess/groups/updateStatus/${id}`);
};

export const getallGroups = async () => {
  return await axios.get(`${BASE_LINK}/quizzess/groups/getAllGroups`);
};

export const addQuiz = async (formData) => {
  console.log(formData, "form data");
  return await axios.post(`${BASE_LINK}/quizzess/quizs/createnewQuiz`, formData);
};

export const updateQuizQues = async (id, formData) => {
  console.log(formData, "form data");
  return await axios.patch(`${BASE_LINK}/quizzess/quizs/updateQuizQues/${id}`, formData);
};

export const getAllQuizList = async () => {
  return await axios.get(`${BASE_LINK}/quizzess/quizs/getAllQuizList`);
};

export const quizStatus = async (id) => {
  return await axios.get(`${BASE_LINK}/quizzess/quizs/updateStatus/${id}`);
};

export const getGroupQuizs = async (pageId) => {
  return await axios.get(
    `${BASE_LINK}/quizzess/groups/getGroupQuizs/${pageId}`
  );
};

export const getallQuizsForGroups = async () => {
  return await axios.get(`${BASE_LINK}/quizzess/quizs/getAllQuizListForGroups`);
};

export const addQuizs = async (id, data) => {
  console.log(data, 'data')
  return await axios.post(
    `${BASE_LINK}/quizzess/groups/connectQuiz/${id}`,
    data
  );
};


export const addSubs = async (formData) => {
  // console.log(formData, "form data");
  return await axios.post(`${BASE_LINK2}/admin/subscription/createnew`, formData);
};