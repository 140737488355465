import React, { useEffect, useState, useRef } from "react";
import { TextField, Grid, Modal, MenuItem } from "@mui/material";
import styles from "../ecommerce/ecommerce.module.css";
import Files from "react-files";
import uploads from "../../../Assets/images/uploads.png";
import { useNavigate } from "react-router";
import { createPodcast } from "../../../services/Podcasts/podcasts";

const AddPodcast = () => {
  const navigate = useNavigate();
  const [src, setSrc] = useState();
  const [files, setFiles] = useState({});

  const initialValues = {
    podCastName: "",
    moral: ""
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  const submithandler = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    const formdata = new FormData();
    formdata.append("image", files);
    formdata.append("podCastName", formValues.podCastName);
    formdata.append("moral", formValues.moral);
    // formdata.append("objectives", objectivesFields);

    if (formValues.podCastName && formValues.moral) {
      console.log("API HIT", formValues);
      let { data: res } = await createPodcast(formdata);
      console.log("data", res);
      if (res.status === "success") {
        setTimeout(() => {
          alert("Podcast Added Successfully");
          navigate(`/podcasts/podcastList`);
        }, 800);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.podCastName) {
      errors.podCastName = "Name is Required";
    }

    if (!values.moral) {
      errors.moral = "Moral is Required";
    }
    if (values.moral.length > 150) {
      errors.moral = "Can't Add more than 500 Charatcers";
    }
    return errors;
  };

  return (
    <>
      <div className={styles.container}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              name="podCastName"
              value={formValues.podCastName}
              onChange={handleChange}
              className={styles.inputs}
              label="Pod Cast Name *"
              variant="outlined"
            />
            <small className="text-red">{formErrors.podCastName}</small>
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="outlined-basic"
              name="moral"
              value={formValues.moral}
              onChange={handleChange}
              className={styles.inputs}
              label="Moral*"
              variant="outlined"
            />
            <small className="text-red">{formErrors.moral}</small>
          </Grid>

          <Grid item xs={6}>
            {!files?.id && (
              <div className={styles.img}>
                <Files
                  className="files-dropzone"
                  onChange={onFilesChange}
                  onError={onFilesError}
                  accepts={[
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                    "image/webp",
                    "audio/*"
                  ]}
                  multiple
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                >
                  <img src={uploads} height="170" width={"100%"} />
                </Files>
              </div>
            )}
            {files?.id && (
              <div className={styles.img}>
                <Files
                  className="files-dropzone"
                  onChange={onFilesChange}
                  onError={onFilesError}
                  accepts={["image/png"]}
                  multiple
                  maxFileSize={10000000}
                  minFileSize={0}
                  clickable
                >
                  <img src={src} height="170" width="170" />
                </Files>
              </div>
            )}
          </Grid>

          <Grid item xs={12}>
            <button className={styles.button} onClick={submithandler}>
              Save Details
            </button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AddPodcast;
