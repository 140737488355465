import API from "../axios/api";
import axios from "axios";

const BASE_LINK = `${API.BASE_LINK}/links`;

export const getAllImportantLink = async () => {
  return await axios.get(BASE_LINK);
};

export const addImportantLink = async (data) => {
  return await axios.post(BASE_LINK, data);
};
export const deleteImportantLink = async (id) => {
  return await axios.delete(BASE_LINK + `/deleteById/${id}`);
};
