import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    getTaxTypeTable,
    updateTaxTypeTable,
  taxTableDelete,
} from "../../../services/GstDetails/gstDetails";
import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import pigImg from "../../../Assets/images/GGP-newlogo-wo-word1.png";
import moment from "moment";


const TaxTypeTable = () => {
  const [taxTypeList, setTaxTypeList] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [checked, setchecked] = useState(false);
  const [delPopup, setDelPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [selectedPrefix, setSelectedPrefix] = useState({});

  const getUniverse = async () => {
    let { data: res } = await getTaxTypeTable();
    setTaxTypeList(res.data);
    console.log(taxTypeList, "taxTypeList");
  };

  useEffect(() => {
    getUniverse();
  }, [checked, showAddModal]);

  const delModelHandle = async (id) => {
    console.log("Delete", id);
    if (!activeId) {
      setActiveId(id);
    } else {
      setActiveId("");
    }
    setchecked(!checked);
    setDelPopup(!delPopup);
  };

  const deletePrefix = async (id) => {
    try {
      let { status: status } = await taxTableDelete(id);
      if (status === 200) {
        console.log("Deleted Tax Type Done", id);
        delModelHandle();
      }
    } catch (error) {
      delModelHandle();
      Swal.fire(
        error?.response?.data?.message,
        "Error While Deleting Tax Type"
      );
    }
  };

  const initialValues = {
    taxCode: "",
    taxName: "",
    taxValue: "",
    applicableOn: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleEdit = (item) => {
    console.log("item");
    let editItem = item;
    setFormValues(editItem);
    setShowAddModal(true);
  };

  const editSubmitHandler = async () => {
    console.log("id");
    setFormErrors(validate(formValues));
    const formdata = new FormData();
    formdata.append("taxName", formValues.taxName);
    formdata.append("taxValue", formValues.taxValue);
    formdata.append("applicableOn", formValues.applicableOn);

    if (
        formValues.taxName && formValues.taxValue
    ) {
      let { data: res } = await updateTaxTypeTable(
        selectedPrefix?._id,
        formValues
      );
      console.log("res", res);
      if (res.success == true) {
        Swal.fire(res.message);
        setShowAddModal(false);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.taxName) {
      errors.taxName = "Tax Name is Required";
    }
    if (!values.taxValue) {
      errors.taxValue = "Tax Value is Required";
    }
    if (!values.applicableOn) {
      errors.applicableOn = "ApplicableOn Value is Required";
    }
    return errors;
  };

  return (
    <>
      <Link to="/add-taxtype">
        <button className="button2">Add More</button>
      </Link>
      <div className="myaccount-table table-responsive text-center">
        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th>Tax Name</th>
              <th>tax Value</th>
              <th>Valid From</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {taxTypeList?.map((prefix, index) => {
              return (
                <tr>
                
                  <td>{`${prefix.taxName.valueDesc} - (${prefix.taxName.valueCode})`}</td>

                  <td>{prefix.taxValue}%</td>
                  <td>{moment(prefix.applicableOn).format("ll")}</td>
                  <td>
                    <MdDelete
                      onClick={() => delModelHandle(prefix._id)}
                      className="product-delete"
                    />
                    <BiEdit
                      onClick={() => {
                        handleEdit(prefix);
                        setSelectedPrefix(prefix);
                      }}
                      className="product-update"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <DelModel
        show={delPopup}
        handleShow={() => setDelPopup(!delPopup)}
        activeDelId={activeId}
        handleSubmit={(x) => deletePrefix(x)}
      />

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <div className="modelMain  modal-dialog-centered ">
          <Modal.Body className="modelBody ">
            <div className="container m-2">
              <div className="catName m-0">
                <label>Tax Code:</label>
                <input
                  className="catInput"
                  placeholder="Tax Code *"
                  required
                  value={formValues.taxCode}
                  name="taxCode"
                  onChange={handleChange}
                ></input>
                <small className="text-red">{formErrors.taxCode}</small>
              </div>

              <div className="catName m-0">
                <label>Tax Name :</label>
                <input
                  className="catInput"
                  placeholder="tax Name*"
                  required
                  value={formValues.taxName}
                  onChange={handleChange}
                  name="taxName"
                ></input>
                <small className="text-red">{formErrors.taxName}</small>
              </div>
              <div className="catName m-0">
                <label>Tax Value *</label>
                <input
                  className="catInput"
                  placeholder="Tax Value *"
                  required
                  value={formValues.taxValue}
                  onChange={handleChange}
                  name="taxValue"
                ></input>
                <small className="text-red">{formErrors.taxValue}</small>
              </div>
              <div className="catName m-0">
                <label>Valid Till</label>
                <input
                type="date"
                  className="catInput"
                  placeholder="Applicable on *"
                  required
                  value={formValues.applicableOn}
                  onChange={handleChange}
                  name="applicableOn"
                ></input>
                <small className="text-red">{formErrors.applicableOn}</small>
              </div>
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                  setShowAddModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="mainButton modelBtn "
                variant="primary"
                onClick={editSubmitHandler}
              >
                Save
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default TaxTypeTable;

const DelModel = ({ show, handleShow, activeDelId, handleSubmit }) => {
  return (
    <>
      <Modal show={show} onHide={() => handleShow()}>
        <div className="modelMain">
          <Modal.Body className="modelBody ">
            <div className="d-flex">
              <div className="row">
                <div className="col d-flex ">
                  <h3 className="deletePlaylistTitle">
                    Are you sure you want to delete this Universe Table ?
                  </h3>
                </div>
                <div className="col modelFooter mt-4">
                  <button
                    style={{ width: "160px" }}
                    className="mainButton modelBtn"
                    variant="secondary"
                    onClick={() => handleSubmit(activeDelId)}
                  >
                    Delete It
                  </button>
                  <button
                    className="mainButton modelBtn"
                    style={{
                      backgroundColor: "#AA23AD",
                      color: "#F2F4F6",
                      width: "160px",
                    }}
                    variant="primary"
                    onClick={() => handleShow()}
                  >
                    No
                  </button>
                </div>
              </div>
              <img width={124} src={pigImg} alt="Piggy" />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
