import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom';
import axios from 'axios';
// import Swal from 'sweetalert2';
import API from '../../../axios/api';
import { useSearchParams } from 'react-router-dom';
import { createTransaction } from '../../../services/AxisBank';
import Loader from '../../../Component/Loader';
// import renderHTML from 'react-render-html';

export default function AxisBankTopupPayment() {
    const params = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const [paymentData, setPaymentData] = useState(params);
    const [htmlData, setHtmlData] = useState('');
    const [signature, setSignature] = useState('');
    const [loading, setLoading] = useState(false);
    console.log(searchParams);

    // const handleCreateOrder = async () => {
    //     try {
    //         let orderObj2 = {
    //             _id: new Date().getTime(),
    //             price: parseInt(searchParams.get("amount")),
    //         };
    //         setOrder_id(orderObj2._id);
    //         let res = await createOrder(searchParams.get('user_id'), orderObj2)
    //         console.log(JSON.stringify(res.data, null, 2), "razorpayData ")
    //         if (res.data) {
    //             let form = document.getElementById("form");
    //             form.submit()
    //         }
    //     }
    //     catch (err) {
    //         if (err?.response?.data?.message) {
    //             console.log(err?.response?.data?.message)
    //             alert(err?.response?.data?.message)
    //         } else {
    //             console.log(err)
    //             alert(err)
    //         }
    //     }
    // }

    const setData = () => {
        // console.log(paymentData);
    }

    const createToken = async() => {
      setLoading(true);
        const data = {
            "merchantTxnId": `${paymentData.merchantTxnId}`,
            "merchantTxnAmount": paymentData.amount,
            "customerId": `${paymentData.customerId}`, 
            "customerName": `${paymentData.customerName}`, 
            "customerEmailId": `${paymentData.customerEmailId}`,
            "customerMobileNo": `${paymentData.customerMobileNo}`,
            "customerPIN": `${paymentData.customerPIN}`
          }
        const res = await createTransaction(data);
        if(res.status === 200){
          // console.log(JSON.parse(res.data.data));
          // setHtmlData(`${JSON.parse(res.data.data)}`);
            // setSignature(res.reqData);
            // setPaymentData(res.reqData)
            // setTimeout(() => {
            //   let form = document.getElementById("form");
            //   form.submit()
            // }, 2000)
            await doTracnctions(res.data);
        }
        setLoading(true);
    }

    const doTracnctions = async (responce) => {
        const { data, signature } = responce;
        // console.log("Request Data", data.data)
        console.log("Signature", signature)
    const config = {
        method: 'post',
        url: 'https://sandbox-axispg.freecharge.in/payment/v1/checkout/',
        headers: { 
          'Accepts': 'application/x-www-form-urlencoded',
          'accepts': 'application/x-www-form-urlencoded',
          'accept': 'application/x-www-form-urlencoded',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data.data
      };
      let dataText;
      const res = await axios(config);
      // .then(function (response) {
      //   dataText = JSON.stringify(response.data);
      //   console.log(dataText);
      //   return JSON.stringify(response.data);
      // })
      // .catch(function (error) {
      //   console.log(error);
      // });
    console.log("Payment Request Response", res);
    }

    useEffect(() => {
      setData();
        // handleCreateOrder()
        createToken();
        // setPaymentData(params);
    }, [])


    return (
      <>
        <div>
          <Loader show={loading}/>
        </div>
      </>
      // <>
      //   {/* <div>
      //   {htmlData ?
      //     renderHTML(htmlData)
      //     : ''
      //   }
      //   </div> */}
      // </>
        // <div>
        //     <Loader show={true} />
        //     <form id="form" style={{ display: "none" }} action="https://secure-axispg.freecharge.in/payment/v1/checkout" method="POST" enctype="application/x-www-form-urlencoded">
        //         <input style={{ display: "none" }} type="text" name="merchantId" value={paymentData.merchantId}/>
        //         <input style={{ display: "none" }} type="text" name="callbackUrl" value={paymentData.callbackUrl}/>
        //         <input style={{ display: "none" }} type="text" name="merchantTxnId" value={paymentData.merchantTxnId}/>
        //         <input style={{ display: "none" }} type="text" name="merchantTxnAmount" value={paymentData.merchantTxnAmount}/>
        //         <input style={{ display: "none" }} type="text" name="Currency" value={paymentData.Currency}/>
        //         <input style={{ display: "none" }} type="text" name="timestamp" value={paymentData.timestamp}/>
        //         <input style={{ display: "none" }} type="text" name="Signature" value={paymentData.Signature}/>
        //         <input style={{ display: "none" }} type="text" name="customerId" value={paymentData.customerId}/>
        //         <input style={{ display: "none" }} type="text" name="customerName" value={paymentData.customerName}/>
        //         <input style={{ display: "none" }} type="text" name="customerEmailId" value={paymentData.customerEmailId}/>
        //         <input style={{ display: "none" }} type="text" name="customerMobileNo" value={paymentData.customerMobileNo}/>
        //         <input style={{ display: "none" }} type="text" name="customerPIN" value={paymentData.customerPIN}/>
        //     </form>
        // </div>
    )
}   

/* 
merchantId -
callbackUrl -
merchantTxnId -
merchantTxnAmount -
Currency -
timestamp -
Signature -
customerId
customerName
customerEmailId
customerMobileNo
customerPIN


const data = {
    "merchantTxnId": "CUSTOMER016",
    "merchantTxnAmount": 500, 
    "customerId": "Custom098767DS", 
    "customerName": "Vaibhav Pal", 
    "customerEmailId": "vaibhavpal98@gmail.com",
    "customerMobileNo": "8052152147",
    "customerPIN": "226022"
  }
*/






