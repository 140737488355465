import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import { Form, Modal } from "react-bootstrap";
import EllipseImg from "../../../Assets/images/Ellipse.png";
import editImg from "../../../Assets/images/edit.png";
import GroupImg from "../../../Assets/images/Group.png";
import pigImg from "../../../Assets/images/mascot.png";
import NavbarComponent from "../../../Component/Navbar";
import SidenavTabs from "../../../Component/SidenavTabs";
import { getSingleCategory } from "../../../services/category";
import {
  addNewActivity,
  deleteSingleActivity,
  getActivitiesByCategoryId,
} from "../../../services/activity";
import styles from "./activity.module.css";
import uploadIcon from "../../../Assets/images/uploadicon.png";
import Swal from "sweetalert2";
import {
  addNewPlaylist,
  deleteSinglePlaylist,
  getAllPlaylist,
} from "../../../services/playlist";

function Playlists() {
  const [show, setShow] = useState(false);
  const [showActivityAddModel, setShowActivityAddModel] = useState(false);
  const [showActivityDeleteModal, setShowActivityDeleteModal] = useState(false);
  const [activityArr, setActivityArr] = useState([]);
  const [categoryObj, setCategoryObj] = useState([]);
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [fileLink, setFileLink] = useState("");
  const [fromCount, setFromCount] = useState(0);
  const [toCount, setToCount] = useState(0);
  const [selectedActivity, setSelectedActivity] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  let navigate = useNavigate();
  let id = useParams();

  const getPlaylists = async () => {
    try {
      let { data: res } = await getAllPlaylist(id.id);
      console.log(res.data);
      if (res.success == true) {
        setCategoryObj(res?.data);
        setFromCount(res.data.ageGroupObj.startAge);
        setToCount(res.data.ageGroupObj.endAge);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        // Swal.fire(error?.message, "this is errore");
      }
    }
  };
  const getActivityByCategoryId = async () => {
    try {
      let { data: res } = await getActivitiesByCategoryId(id.id);
      console.log(res.data);
      if (res.success == true) {
        setActivityArr(res?.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  const handlePlaylistAdd = async () => {
    try {
      if (
        name != "" &&
        categoryObj._id != "" &&
        fromCount != "" &&
        toCount != ""
      ) {
        let obj = {
          startAge: fromCount,
          endAge: toCount,
        };

        let formData = new FormData();
        formData.append("name", name);
        formData.append("ageGroupObj", JSON.stringify(obj));
        formData.append("file", file);

        const { data: res } = await addNewPlaylist(formData);
        if (res.success) {
          console.log(res.data);
          Swal.fire(res.message);
          getPlaylists();
          getActivityByCategoryId();
          setShowActivityAddModel(false);
          // setFileLink(res.data.thumbnailImage);
          clearstates();
        }
      } else {
        Swal.fire("Please Fill all fields");
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };
  // handlePlaylistAdd
  const clearstates = () => {
    setName("");
    setFromCount(0);
    setFileLink("");
    setToCount(0);
  };

  useEffect(() => {
    getPlaylists();
    // getActivityByCategoryId();
  }, []);

  useEffect(() => {
    if (toCount > fromCount) {
      setErrorMessage("");
    } else if (fromCount > toCount) {
      setErrorMessage("cannot be greaterthanTo");
    } else if (fromCount < 0) {
      setErrorMessage("cannot be in negative");
    }
  }, [fromCount, toCount]);

  // const getActId = (id) => {
  //     console.log(`id>>`, id)
  // }

  const handleDeleteModalTrigger = () => {
    if (selectedActivity.name) {
      setShowActivityDeleteModal(true);
    } else {
      Swal.fire("No activity selected to perform this action on");
    }
  };
  const editActHandle = () => {
    if (selectedActivity.name) {
      navigate(`/playlist/update/${selectedActivity?._id}`);
    } else {
      Swal.fire("Please select an activity to perform this action on");
    }
  };

  const handleActivityDelete = async () => {
    try {
      let { data: res } = await deleteSinglePlaylist(selectedActivity?._id);
      console.log(res.data);
      if (res.success == true) {
        Swal.fire(res.message);
        getPlaylists();
        setShowActivityDeleteModal(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (toCount > fromCount) {
      setErrorMessage("");
    }
  }, [fromCount, toCount]);

  const handleFromCount = (e) => {
    if (fromCount >= toCount) {
      setErrorMessage("cannot be greaterthanTo");
    } else {
      setFromCount((prevState) => parseInt(prevState) + 1);
    }
  };
  const handleToCount = (e) => {
    if (toCount <= fromCount) {
      setErrorMessage("cannot be lessthanFrom");
    } else {
      setToCount((prevState) => parseInt(prevState) - 1);
    }
  };
  const handleNegativeFromCount = () => {
    if (fromCount <= 0) {
      setErrorMessage("cannot be lessthan0");
    } else {
      setFromCount((prevState) => parseInt(prevState) - 1);
    }
  };
  console.log(`categoryObj`, categoryObj);

  const TableListElement = () => {
    return (
      <table id="resultTable">
        <thead id="first" className="table-title">
          <tr>
            <th>Playlist Name</th>
            <th>Age Group</th>
            <th>No. of Videos</th>
          </tr>
        </thead>
        <tbody>
          {categoryObj.map((el, index) => (
            <tr key={index}>
              <td>
                <div className="d-flex">
                  <input
                    onChange={() => console.log(el)}
                    onClick={() => {
                      setSelectedActivity(el);
                    }}
                    type="radio"
                    checked={selectedActivity._id == el._id}
                  />
                  <p className="tableInputTxt" type="text" name="column_1">
                    {el?.name}
                  </p>
                </div>
              </td>
              <td>
                <div className={styles.tableImgOuter}>
                  <p className="tableInputTxt" type="text" name="column_1">
                    {el?.ageGroupObj?.startAge} - {el?.ageGroupObj?.endAge}
                  </p>
                </div>
                {/* <p className="tableInputTxt" type="text" name="column_2">{data?.ageGroupObj?.startAge}-{data?.ageGroupObj?.endAge}</p> */}
              </td>
              <td>
                <div className={styles.tableImgOuter}>
                  <p className="tableInputTxt" type="text" name="column_1">
                    {el.videoCount}
                  </p>
                </div>
                {/* <p className="tableInputTxt" type="text" name="column_2">{data?.ageGroupObj?.startAge}-{data?.ageGroupObj?.endAge}</p> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="home-outer">
      <div
        className={"container col playlist-outer pt-5 " + styles.activityInnner}
      >
        <div className="row">
          {/* <h2>Category Name</h2> */}
          <div style={{ padding: "8rem" }} className="col-md">
            <div className="row">
              <h1 style={{ padding: 0 }} className={styles.screenTitle}>
                {categoryObj?.name}
              </h1>
              <div className="col playlistTitle p-0">
                <h3 style={{ padding: 0 }}>List of all Playlists</h3>
              </div>
              <div className="col-lg-3 p-0">
                <div className="icon-outer">
                  <img
                    onClick={editActHandle}
                    className="tableIcon"
                    src={editImg}
                    alt=""
                  />
                  <img
                    onClick={() => handleDeleteModalTrigger()}
                    className="tableIcon"
                    src={GroupImg}
                    alt="delete"
                  />
                </div>
              </div>
              <Modal
                show={showActivityDeleteModal}
                onHide={() => setShowActivityDeleteModal(false)}
              >
                <div className="modelMain">
                  <Modal.Body className="modelBody ">
                    <div className="d-flex">
                      <div className="row">
                        <div className="col d-flex ">
                          <h3 className="deletePlaylistTitle">
                            Are you sure you want to delete this video?
                          </h3>
                        </div>
                        <div className="col modelFooter mt-4">
                          <button
                            style={{ width: "160px" }}
                            className="mainButton modelBtn"
                            variant="secondary"
                            onClick={handleActivityDelete}
                          >
                            Yes, remove it
                          </button>
                          <button
                            className="mainButton modelBtn"
                            style={{
                              backgroundColor: "#AA23AD",
                              color: "#F2F4F6",
                              width: "160px",
                            }}
                            variant="primary"
                            onClick={() => setShowActivityDeleteModal(false)}
                          >
                            No, i just clicked it
                          </button>
                        </div>
                      </div>
                      <img width={124} src={pigImg} alt="" />
                    </div>
                  </Modal.Body>
                </div>
              </Modal>
            </div>
            <div className="playlistTable">
              {/* {ActivitiesByCatIdInfo.loading === true ? (
                                    null
                                ) : activitiesState.data.length === 0 ? (<p style={{color: "red"}}>no activities found</p>) : ( */}
              <div className="row tableMain">
                <TableListElement />
              </div>
              {/* )} */}
            </div>
            <button
              className="addPlaylistBtn primaryBtn"
              onClick={() => setShowActivityAddModel(true)}
            >
              Add Playlist
            </button>
          </div>
          <Modal show={showActivityAddModel} onHide={() => setShow(false)}>
            <div className="modelMain">
              <Modal.Body style={{}} className="modelBody">
                <div className="container m-2">
                  <div className="catName m-0">
                    <label
                      className="primaryText"
                      style={{ marginBottom: 20, fontSize: "25px" }}
                    >
                      {categoryObj?.name}
                    </label>
                    <label>Playlist Name:</label>
                    <input
                      className="catInput"
                      placeholder="Type Playlist Name Here"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    ></input>
                  </div>

                  <div className="col-4 uploadImg">
                    <label className="p-2">Add Playlist Image:</label>

                    <div style={{ marginLeft: 0 }} className="upload-outer p-1">
                      <input
                        className="catInput inputFile"
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                      ></input>
                      <p>{file && file.name}</p>
                      {/* <img
                        style={{
                          position: 'absolute',
                          left: '46%',
                          top: '40%'
                        }}
                        src={uploadIcon}
                        alt='upload'
                      /> */}
                    </div>
                  </div>
                  <div className="ageGroup">
                    <label>Age Group: </label>
                    <div className="ageGroup-inner">
                      <p>From</p>
                      <div className="agegroupBtn-outer">
                        <button
                          className="agegroupBtn"
                          onClick={handleFromCount}
                        >
                          +
                        </button>
                        <input
                          className="ageCountInput"
                          value={fromCount}
                          type="number"
                          maxLength={10}
                          onChange={(e) => setFromCount(e.target.value)}
                        />
                        <button
                          style={{ marginRight: "-2px" }}
                          className="agegroupBtn"
                          onClick={handleNegativeFromCount}
                        >
                          -
                        </button>
                      </div>
                      <p>To</p>
                      <div className="agegroupBtn-outer">
                        <button
                          className="agegroupBtn"
                          onClick={() =>
                            setToCount((prevState) => parseInt(prevState) + 1)
                          }
                        >
                          +
                        </button>
                        <input
                          className="ageCountInput"
                          value={toCount}
                          type="number"
                          maxLength={10}
                          onChange={(e) => setToCount(e.target.value)}
                        />
                        <button
                          style={{ marginRight: "-2px" }}
                          className="agegroupBtn"
                          onClick={handleToCount}
                        >
                          -
                        </button>
                      </div>
                    </div>
                  </div>
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    {errorMessage}
                  </p>
                </div>
                <div className="modelFooter justify-content-center">
                  <button
                    className="mainButton modelBtn"
                    variant="secondary"
                    onClick={() => {
                      setShowActivityAddModel(false);
                      setFile("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="mainButton modelBtn"
                    variant="primary"
                    onClick={handlePlaylistAdd}
                  >
                    Save Playlist
                  </button>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Playlists;
