import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Modal } from "react-bootstrap";
import GroupImg from "../../../Assets/images/Group.png";
import pigImg from "../../../Assets/images/mascot.png";
import styles from "./activity.module.css";
import Swal from "sweetalert2";
import {
  addNotification,
  deleteNotification,
  getAllNotifications,
} from "../../../services/notification";

function Notifications() {
  const [showNotificationAddModel, setShowNotificationAddModel] =
    useState(false);
  const [showNotificationDeleteModal, setShowNotificationDeleteModal] =
    useState(false);
  const [notificationArr, setNotificationArr] = useState([]);
  const [text, setText] = useState("");
  const [fromCount, setFromCount] = useState(0);
  const [toCount, setToCount] = useState(0);
  const [selectedNotification, setSelectedNotifcation] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [disableInput, setDisableInput] = useState(false);
  let navigate = useNavigate();
  let id = useParams();

  const getAll = async () => {
    try {
      let { data: res } = await getAllNotifications();
      console.log(res.data);
      if (res.success == true) {
        setNotificationArr(res?.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  const handleNotificationAdd = async () => {
    try {
      if (text == "") {
        Swal.fire("Name Cannot Be Empty");
        return;
      }
      else if (fromCount > toCount) {
        Swal.fire("Invalid Age");
        return;
      } else {
        // if (file) {
        let obj = {
          text,
          ageFrom: parseInt(fromCount),
          ageTo: parseInt(toCount),
        };
        console.log(obj)
        const { data: res } = await addNotification(obj);
        if (res.success) {
          console.log("this is data: ", res.data);
          Swal.fire(res.message);
          // setsubcriptionObj(res.data)
          getAll();
          setShowNotificationAddModel(false);
          // setFileLink(res.data.thumbnailImage);
          clearstates();
        }
        // } else {
        //   Swal.fire("Please select file");
        // }
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };
  // handleActivityAdd
  const clearstates = () => {
    setText("");
    setFromCount(0);
    setToCount(0);
  };

  const handleToInput = (value) => {
    console.log(value)
    console.log(fromCount)
    console.log(parseInt(value) > parseInt(fromCount))
    if (parseInt(value) > 17) {
      setErrorMessage("age cannot be greater than 17");
      return;
    }
    else {
      setToCount(value)
    }

  };

  useEffect(() => {
    getAll();
  }, []);

  const handleDeleteModalTrigger = () => {
    if (selectedNotification._id) {
      setShowNotificationDeleteModal(true);
    } else {
      Swal.fire("No notification selected to perform this action on");
    }
  };
  const handleNotificationDelete = async () => {
    try {
      let { data: res } = await deleteNotification(selectedNotification?._id);
      console.log(res.data);
      if (res.success == true) {
        Swal.fire(res.message);
        getAll();
        setShowNotificationDeleteModal();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleToCount = (e) => {
    if (toCount <= fromCount) {
      setErrorMessage("cannot be lessthanFrom");
    } else if (toCount > 17) {
      setErrorMessage("cannot be greater than 17");
    } else {
      setToCount((prevState) => parseInt(prevState) - 1);
    }
  };
  useEffect(() => {
    if (toCount > fromCount) {
      setErrorMessage("");
    }
  }, [fromCount, toCount]);

  const handleFromCount = () => {
    if (fromCount >= toCount) {
      setErrorMessage("cannot be greaterthanTo Age");
      setDisableInput(true);
    } else {
      setFromCount((prevState) => parseInt(prevState) + 1);
      setDisableInput(false);
    }
  };

  const handleFromCountSet = (e) => {
    if (e.target.value >= toCount) {
      setErrorMessage("cannot be greaterthanTo Age");
      setDisableInput(true);
    } else {
      setFromCount(e.target.value);
      setDisableInput(false);
    }
  };

  const handleToCountReduce = () => {
    if (toCount <= fromCount) {
      setErrorMessage("cannot be lessthanFrom Age");
      setDisableInput(true);
    } else {
      setToCount((prevState) => parseInt(prevState) - 1);
      setDisableInput(false);
    }
  };
  const handleNegativeFromCount = () => {
    if (fromCount <= 0) {
      setErrorMessage("cannot be lessthan0");
      setDisableInput(true);
    } else {
      setFromCount((prevState) => parseInt(prevState) - 1);
      setDisableInput(false);
    }
  };
  // console.log(`categoryObj`, categoryObj);

  const handleManuallyAgeFrom = (e) => {
    if (e.target.value > 99) {
      setErrorMessage("Enter a valid age");
      setDisableInput(true);
    } else {
      setToCount(e.target.value);
      setDisableInput(false);
    }
  };

  const handleFromInput = (value) => {
    if (parseInt(value) < 0) {
      setErrorMessage("age cannot be less than 0");
      return;
    }
    else if (parseInt(value) > parseInt(toCount)) {
      setErrorMessage("cannot be greater than to");
      return;
    }
    else {
      setFromCount(value)
    }
  }

  const TableListElement = () => {
    return (
      <table id="resultTable">
        <thead id="first" className="table-title">
          <tr>
            <th>Notification Text</th>
            <th>Age</th>
          </tr>
        </thead>
        <tbody>
          {notificationArr.map((el, index) => (
            <tr key={index}>
              <td>
                <div className="d-flex">
                  <input
                    onChange={() => console.log(el)}
                    onClick={() => {
                      setSelectedNotifcation(el);
                    }}
                    type="radio"
                    checked={selectedNotification._id == el._id}
                  />
                  <p className="tableInputTxt" type="text" name="column_1">
                    {el?.text}
                  </p>
                </div>
              </td>
              <td>
                <div className={styles.tableImgOuter}>
                  <p className="tableInputTxt" type="text" name="column_1">
                    {el?.ageFrom} - {el?.ageTo}
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="home-outer">
      <div
        className={"container col playlist-outer pt-5 " + styles.activityInnner}
      >
        <div className="row">
          {/* <h2>Category Name</h2> */}
          <div style={{ padding: "8rem" }} className="col-md">
            <div className="row">
              <h1 style={{ padding: 0 }} className={styles.screenTitle}></h1>
              <div className="col playlistTitle p-0">
                <h3 style={{ padding: 0 }}>List of all Notifications</h3>
              </div>
              <div className="col-lg-3 p-0">
                <div className="icon-outer">
                  <img
                    onClick={() => handleDeleteModalTrigger()}
                    className="tableIcon"
                    src={GroupImg}
                    alt="delete"
                  />
                </div>
              </div>
              <Modal
                show={showNotificationDeleteModal}
                onHide={() => setShowNotificationDeleteModal(false)}
              >
                <div className="modelMain">
                  <Modal.Body className="modelBody ">
                    <div className="d-flex">
                      <div className="row">
                        <div className="col d-flex ">
                          <h3 className="deletePlaylistTitle">
                            Are you sure you want to delete this notification?
                          </h3>
                        </div>
                        <div className="col modelFooter mt-4">
                          <button
                            style={{ width: "160px" }}
                            className="mainButton modelBtn"
                            variant="secondary"
                            onClick={() => handleNotificationDelete()}
                          >
                            Yes, remove it
                          </button>
                          <button
                            className="mainButton modelBtn"
                            style={{
                              backgroundColor: "#AA23AD",
                              color: "#F2F4F6",
                              width: "160px",
                            }}
                            variant="primary"
                            onClick={() =>
                              setShowNotificationDeleteModal(false)
                            }
                          >
                            No, i just clicked it
                          </button>
                        </div>
                      </div>
                      <img width={124} src={pigImg} alt="" />
                    </div>
                  </Modal.Body>
                </div>
              </Modal>
            </div>
            <div className="playlistTable">
              {/* {ActivitiesByCatIdInfo.loading === true ? (
                                    null
                                ) : activitiesState.data.length === 0 ? (<p style={{color: "red"}}>no activities found</p>) : ( */}
              <div className="row tableMain">
                <TableListElement />
              </div>
              {/* )} */}
            </div>
            <button
              className="addPlaylistBtn primaryBtn"
              onClick={() => setShowNotificationAddModel(true)}
            >
              Add Notification
            </button>
          </div>

          <Modal
            show={showNotificationAddModel}
            onHide={() => setShowNotificationAddModel(false)}
          >
            <div className="modelMain  modal-dialog-centered ">
              <Modal.Body className="modelBody ">
                <div className="container m-2">
                  <div className="catName m-0">
                    <label>In App Notifications</label>
                    <input
                      className="catInput"
                      placeholder="Type here"
                      required
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    ></input>
                  </div>

                  <div className="ageGroup">
                    <label>Age Group: </label>
                    <div className="ageGroup-inner">
                      <p>From</p>
                      <div className="agegroupBtn-outer">
                        <button
                          className="agegroupBtn"
                          onClick={handleFromCount}
                        >
                          +
                        </button>
                        <input
                          className={`ageCountInput`}
                          value={fromCount}
                          type="number"
                          maxLength={10}
                          onChange={(e) => handleFromInput(e.target.value)}
                        />
                        <button
                          style={{ marginRight: "-2px" }}
                          className="agegroupBtn"
                          onClick={handleNegativeFromCount}
                        >
                          -
                        </button>
                      </div>
                      <p>To</p>
                      <div className="agegroupBtn-outer">
                        <button
                          className="agegroupBtn"
                          onClick={() =>
                            setToCount((prevState) => parseInt(prevState) + 1)
                          }
                        >
                          +
                        </button>
                        <input
                          className={`ageCountInput`}
                          value={toCount}
                          type="number"
                          max={5}
                          onChange={(e) => handleToInput(e.target.value)}
                        />
                        <button
                          style={{ marginRight: "-2px" }}
                          className="agegroupBtn"
                          onClick={handleToCount}
                        >
                          -
                        </button>
                      </div>
                    </div>
                  </div>
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    {errorMessage}
                  </p>
                </div>
                <div className="modelFooter justify-content-center">
                  <button
                    className="mainButton modelBtn"
                    variant="secondary"
                    onClick={() => {
                      setShowNotificationAddModel(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="mainButton modelBtn "
                    variant="primary"
                    onClick={() => handleNotificationAdd()}
                  >
                    Save
                  </button>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Notifications;
