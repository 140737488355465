import React, { useEffect, useState,useRef } from "react";
import { Link } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { Grid } from "@mui/material";
import styles from "./ecommerce.module.css";
import pigImg from "../../../Assets/images/mascot.png";
import {
  allcategories,
  categoryStatus,
  categoryDelete,
  updatecategory,
  allsubcategories,
  categoryById
} from "../../../services/Ecommerce/category";
import { MdDelete } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Files from "react-files";
import uploads from "../../../Assets/images/uploads.png";


const Category = () => {
  // const [allcategory, setAllcategory] = useState([]);
  const [checked, setchecked] = useState(false);
  const [delPopup, setDelPopup] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [reload, setreload] = useState(false);
  const [categoryList,setCategoryList] =useState([]);
  // const [idList, setIdList] = useState([]);
  const [newId,setNewId] = useState("");
  const [parentId,setParentId] = useState("");
 
  useEffect(() => {
    getallcategories();
  }, [checked, showAddModal]);

  const getallcategories = async () => {
    let { data: res } = await allcategories();
    console.log("all category data", res);
    // setAllcategory(res.data);
    setCategoryList(res.data);
  };

  // const updateModelHandle = async (id) => {
  //   console.log("Update categor data", id);
  // };

  const delModelHandle = async (id) => {
    if (!activeId) {
      setActiveId(id);
    } else {
      setActiveId("");
    }
    setchecked(!checked);
    setDelPopup(!delPopup);

  };

  const deleteParent = async (id) => {
    try {
      let { data: res } = await categoryDelete(id);
      
      if (res.message == "Categories Deleted Successfully") {
        // console.log(res.message);
        console.log("deleted Category Done", id);
        // getPlaylists();
        delModelHandle();
      }else if(res.message== "Delete the sub Categories first"){
        console.log("Delete the sub Categories first");
        alert("Delete the sub Categories first");
        delModelHandle();
      }
    } catch (error) {
      delModelHandle();
      Swal.fire(
        error?.response?.data?.message,
        "Error While Deleting Category"
      );
    }
  };

  const handleChangeSwitch = async (id) => {
    console.log("id",id);
    let { data: res } = await categoryStatus(id);
    
    console.log("checked value", res.data);
    setchecked(!checked);
  };

  const initialValues = { categoryname: "", description: "", image: "", height: "", categoryOrder: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  // const filehandleChange = (e) => {
  //   const { name, files } = e.target; // take name and value from input in destructring
  //   setFormValues({ ...formValues, [name]: files });
  //   console.log(formValues);
  // };

  const [files, setFiles] = useState({});
  const [src, setSrc] = useState([]);
  console.log(files.name, "hkoo");

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };
  
  const getsubcat = async (id) =>{
    setNewId(id);
    console.log(newId,"newid");
    let {data:res}  = await allsubcategories(id);
    console.log(id);
    if(res.message == "No Subcategories found"){
      alert(res.message);
    // getonecatdata();
    }
    
    console.log(res.data);
    setCategoryList(res.data);
  }

  // back functionality

  const getonecatdata = async ()=>{
    let {data:mainres} = await categoryById(newId);
    console.log(mainres.data.isParentCat,"is parent cat");
    if(mainres.data.isParentCat){
      let {data:res}  = await allcategories();
      setNewId("");
      setParentId("");
      setCategoryList(res.data);
    }else{
      setNewId(mainres.data.parentCategory);
       setParentId(mainres.data.parentCategory);
       console.log(mainres.data.parentCategory);
       console.log(parentId,"parentid"); 
       let {data:res}  = await allsubcategories(parentId);
       setCategoryList(res.data);
    }
    // if(res.data.isParentcat){
    //   let {data:res}  = await allsubcategories(parentCategory);
    // }
  }
  

  const handleEdit = (item) => {
    console.log("edit cat", item);
    setFormValues(item);
    setShowAddModal(true);
  };
  let hvalue = "";
  
  const editSubmitHandler = async () => {
    console.log("id");
    if(formValues.height == "true"){
            hvalue = true;
    }else{ hvalue =false};
    setFormErrors(validate(formValues));
    const formdata = new FormData();
    if(files) formdata.append("image", files);
    formdata.append("categoryname", formValues.categoryname);
    formdata.append("description", formValues.description);
    formdata.append("isBigCard", hvalue);
    formdata.append("categoryOrder", formValues.categoryOrder);


    const categorydata = formdata;
    if (formValues.categoryname && formValues.description) {
      const categoryDetails = { ...formValues, ...files };
      console.log("Category update data", categorydata);
      // console.log("Category update data files", files);
      let { data: res } = await updatecategory(
        selectedCategory?._id,
        categorydata
      );
      console.log(res.success);
      if (res.success) {
        Swal.fire(res.message);
        setShowAddModal(false);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (
      !/^[a-z A-Z0-9]+$/.test(values.categoryname) ||
      /^[ ]+$/.test(values.categoryname)
    ) {
      errors.categoryname = "Category is not valid";
    }

    if (!values.categoryname) {
      errors.categoryname = "Category is Required";
    }

    if (!values.description) {
      errors.description = "Description is Required";
    }
    return errors;
  };
  
 
  return (
    <> 
     <button className={styles.backbutton} onClick={()=>getonecatdata()} >Back</button>
      <div className={styles.container}>
      
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <p className={styles.headertext}>CategoryList</p>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          > 
          
          {newId == "" ? (
                <Link to= {`/ecommerce/category/addcategory`} >
                <button  className="button2"  >Add Category</button>
              </Link>
          ):(
            <Link to= {`/ecommerce/category/addsubcategory/${newId}`} state={newId}>
            <button  className="button2"  >Add Category</button>
          </Link>
          ) }
          
            
          </Grid>

          {categoryList?.map((item, index) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                <div style={{ backgroundColor: "white", paddingBottom: 30 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <p  className={styles.cattext}>{item?.categoryname}</p>
                      <p className={styles.cattext}><span>No of subcategories : </span>{item?.subCategory?.length}</p>
                      <Switch
                        checked={item?.status}
                        onChange={() => handleChangeSwitch(item?._id)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          marginLeft: 15,
                          alignItems: "center",
                        }}
                      ></div>
                    </div>
                    <div style={{ width: "50%", position: "relative" }}>
                      <MdDelete
                        onClick={() => delModelHandle(item?._id)}
                        className="categroy-delete"
                      />
                      <BiEdit
                        onClick={() => {
                          handleEdit(item);
                          setSelectedCategory(item);
                        }}
                        className="categroy-update"
                      />
                    </div>
                    <div></div>
                  </div>
                  <div onClick={()=>getsubcat(item?._id)} >
                  <img   
                    className={styles.catdiv}
                    src={item?.imagePath}
                    height="250"
                    width="90%"
                  />
                  </div>
                  {item?.isBigCard == true ? (
                    <p className={styles.cattext}>Big Height<span> ({item?.categoryOrder})</span></p>
                  ) : (
                    <p className={styles.cattext}>Small Height <span>O ({item?.categoryOrder})</span></p>
                  )}
                  <p className={styles.catdiv}>{item?.description}</p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      flexWrap: "wrap",
                    }}
                  ></div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
      <DelModel
        show={delPopup}
        handleShow={() => setDelPopup(!delPopup)}
        activeDelId={activeId}
        handleSubmit={(x) => deleteParent(x)}
      />

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <div className="modelMain  modal-dialog-centered ">
          <Modal.Body className="modelBody ">
            <div className="container m-2">
              <div className="catName m-0">
                <label>Category Name:</label>
                <input
                  className="catInput"
                  placeholder="Type Category Name Here *"
                  required
                  value={formValues.categoryname}
                  name="categoryname"
                  onChange={handleChange}
                ></input>
                <small className="text-red">{formErrors.categoryname}</small>
              </div>
              <div className="catName m-0">
                <label>Dynamic Height:</label>
                <select
                  className="catInput"
                  placeholder="Dynamic Height *"
                  required
                  defaultValue={formValues?.height}
                  value={formValues.height}
                  name="height"
                  onChange={handleChange}
                >
                  <option value="false">No</option>
                  <option value="true">Yes</option>
                </select>
                <small className="text-red">{formErrors.height}</small>
              </div>
              <div className="catName m-0">
                <label>Category Description:</label>
                <input
                  className="catInput"
                  placeholder="Category Description *"
                  required
                  value={formValues.description}
                  onChange={handleChange}
                  name="description"
                ></input>
                <small className="text-red">{formErrors.description}</small>
              </div>
              <div className="catName m-0">
                <label>Category Order:</label>
                <input
                  className="catInput"
                  placeholder="Category Description *"
                  required
                  value={formValues.categoryOrder}
                  onChange={handleChange}
                  name="categoryOrder"
                ></input>
                <small className="text-red">{formErrors.categoryOrder}</small>
              </div>
              <div className="uploadImg">
                <label>Upload Image</label>
                <div className="upload-outer">
                  <>
                    {/* <input
                      className="category-file"
                      type="file"
                      name="image"
                      onChange={onFilesChange}
                    ></input> */}
                    <Grid item xs={6}>
                      {!files?.id && (
                        <div className={styles.inputs}>
                          <Files
                            className="files-dropzone"
                            onChange={onFilesChange}
                            onError={onFilesError}
                            accepts={[
                              "image/png",
                              "image/jpg",
                              "image/jpeg",
                              "image/webp",
                              "audio/*",
                            ]}
                            multiple
                            maxFileSize={10000000}
                            minFileSize={0}
                            clickable
                          >
                            <img src={uploads} height="170" width={"100%"} />
                          </Files>
                        </div>
                      )}
                      {files?.id && (
                        <div className={styles.inputs}>
                          <Files
                            className="files-dropzone"
                            onChange={onFilesChange}
                            onError={onFilesError}
                            accepts={[
                              "image/png",
                              "image/jpg",
                              "image/jpeg",
                              "image/webp",
                              "audio/*",
                            ]}
                            multiple
                            maxFileSize={10000000}
                            minFileSize={0}
                            clickable
                          >
                            <div style={{ background: "white", padding: 20 }}>
                              {src[0] &&
                                src.map((item) => {
                                  return (
                                    <img
                                      src={item}
                                      height="110"
                                      width={"100"}
                                      style={{
                                        marginRight: 20,
                                        marginBottom: 20,
                                      }}
                                    />
                                  );
                                })}
                            </div>
                          </Files>
                        </div>
                      )}
                    </Grid>
                  </>
                </div>
              </div>
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                  setShowAddModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="mainButton modelBtn "
                variant="primary"
                onClick={editSubmitHandler}
              >
                Save
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default Category;

const DelModel = ({ show, handleShow, activeDelId, handleSubmit }) => {
  return (
    <>
      <Modal show={show} onHide={() => handleShow()}>
        <div className="modelMain">
          <Modal.Body className="modelBody ">
            <div className="d-flex">
              <div className="row">
                <div className="col d-flex ">
                  <h3 className="deletePlaylistTitle">
                    Are you sure you want to delete this Category ?
                  </h3>
                </div>
                <div className="col modelFooter mt-4">
                  <button
                    style={{ width: "160px" }}
                    className="mainButton modelBtn"
                    variant="secondary"
                    onClick={() => handleSubmit(activeDelId)}
                  >
                    Delete It
                  </button>
                  <button
                    className="mainButton modelBtn"
                    style={{
                      backgroundColor: "#AA23AD",
                      color: "#F2F4F6",
                      width: "160px",
                    }}
                    variant="primary"
                    onClick={() => handleShow()}
                  >
                    No
                  </button>
                </div>
              </div>
              <img width={124} src={pigImg} alt="Piggy" />
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};
