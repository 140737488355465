import React, { useEffect, useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import "../../../Assets/Styling/adminHome.css";
import Styles from "./Category.module.css";
import uploadIcon from "../../../Assets/images/uploadicon.png";
import Nutrition from "../../../Assets/images/Nutrition.png";
import deleteImg from "../../../Assets/images/delete.png";
import pencil from "../../../Assets/images/pencil.png";
import pigImg from "../../../Assets/images/mascot.png";
import { Link } from "react-router-dom";
import {
  addNewCategory,
  deleteSingleCategory,
  getAllCategories,
  updateSingleCategory,
} from "../../../services/category";
import { authContext, searchContext } from "../../../App";
import Swal from "sweetalert2";

export default function Categories() {

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditingTrue, setIsEditingTrue] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [categoryArr, setCategoryArr] = useState([]);
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [fileLink, setFileLink] = useState("");
  const [fromCount, setFromCount] = useState(0);
  const [toCount, setToCount] = useState(0);
  const [itemOptions, setItemOptions] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({});

  const [isAuthorized, setIsAuthorized] = useContext(authContext);
  const [searchObj, setSearchObj] = useContext(searchContext);

  const getCategories = async () => {
    try {
      const { data: res } = await getAllCategories();
      if (res.success) {
        console.log(res.data, "cat>>");
        setCategoryArr(res.data);
        setSearchObj((prevState) => ({
          ...prevState,
          searchArr: res.data,
        }));
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  console.log("categoryArr", categoryArr);
  
  const handleFilter = () => {
    console.log("clicked");
    if (searchObj?.searchInput !== "") {
      console.log("inside", searchObj.searchInput);
      var filteredArray = searchObj.searchArr.filter((name) => {
        return (
          // console.log(name)
          `${name}`
            .toLowerCase()
            .indexOf(`${searchObj.searchInput}`.toLowerCase()) >= 0
        );
      });

      setCategoryArr(filteredArray);
      setSearchObj((prevState) => ({
        ...prevState,
        filteredArr: filteredArray,
      }));
    }
  };

  const addCategories = async () => {
    try {
      if (name != "" && fromCount != "" && toCount != "") {
        if (file) {
          let formData = new FormData();
          formData.append("name", name);
          formData.append("file", file);
          let obj = {
            startAge: fromCount,
            endAge: toCount,
          };
          formData.append("ageGroupObj", JSON.stringify(obj));
          const { data: res } = await addNewCategory(formData);
          if (res.success) {
            console.log(res.data);
            Swal.fire(res.message);
            getCategories();
            setShowAddModal(false);
            clearstates();
            // setCategoryArr(res.data)
          }
        } else {
          Swal.fire("Please Select File");
        }
      } else {
        Swal.fire("Please Fill All fields");
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  const EditCategory = async () => {
    try {
      let formData = new FormData();
      formData.append("name", name);
      formData.append("file", file);
      let obj = {
        startAge: fromCount,
        endAge: toCount,
      };
      formData.append("ageGroupObj", JSON.stringify(obj));
      const { data: res } = await updateSingleCategory(
        selectedCategory?._id,
        formData
      );
      if (res.success) {
        console.log(res.data);
        Swal.fire(res.message);
        getCategories();
        clearstates();
        setShowAddModal(false);
        // setCategoryArr(res.data)
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  const deleteCategoryFunction = async () => {
    try {
      const { data: res } = await deleteSingleCategory(selectedCategory?._id);
      if (res.success) {
        console.log(res.data);
        Swal.fire(res.message);
        getCategories();
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message) {
        Swal.fire(error.response.data.message);
      } else {
        Swal.fire(error?.message);
      }
    }
  };

  const handleOnint = () => {
    getCategories();
    handleFilter();
  };

  useEffect(() => {
    handleOnint();
    if (toCount > fromCount) {
      setErrorMessage("");
    } else if (fromCount > toCount) {
      setErrorMessage("cannot be greaterthanTo");
    } else if (fromCount < 0) {
      setErrorMessage("cannot be in negative");
    }
  }, [fromCount, toCount, searchObj.searchInput]);
  // console.log("searchObj :>> ", searchObj);

  const clearstates = () => {
    setName("");
    setFromCount(0);
    setIsEditingTrue(false);
    setFileLink("");
    setToCount(0);
  };

  const handleEdit = (item) => {
    setName(item.name);
    setFromCount(item.ageGroupObj.startAge);
    setIsEditingTrue(true);
    setFileLink(item.thumbnailImage);
    setToCount(item.ageGroupObj.endAge);
    setShowAddModal(true);
  };

  const handleFromCount = (e) => {
    if (fromCount >= toCount) {
      setErrorMessage("cannot be greaterthanTo");
    } else {
      setFromCount((prevState) => prevState + 1);
    }
  };
  const handleToCountMinus = (e) => {
    if (toCount <= fromCount) {
      setErrorMessage("cannot be lessthanFrom");
    } else if (toCount > 17) {
      setToCount(17)

      setErrorMessage("cannot be greater than 17");
    } else {
      if (toCount > 0) {
        setToCount((prevState) => prevState - 1);
      }
    }
  };
  const handleNegativeFromCount = () => {
    if (fromCount <= 0) {
      setErrorMessage("cannot be lessthan0");
    } else {
      setFromCount((prevState) => prevState - 1);
    }
  };
  const handleToInput = (value) => {
    if (parseInt(value) > 17) {
      setToCount(17)
      setErrorMessage("age cannot be greater than 17");
      return;
    }
    else if (value < 0) {
      setToCount(0)
      setErrorMessage("age cannot be less than 0");
      return;
    }
    else {
      setToCount(value)
      return;
    }
  };


  const handleFromInput = (value) => {
    console.log(
      toCount, value, parseInt(toCount) < parseInt(value)
    )
    if (value < 0) {
      setFromCount(0)
      setErrorMessage("age cannot be less than 0");
      return;
    }
    else if (parseInt(toCount) < parseInt(value)) {
      setErrorMessage("cannot be greaterthanTo");
      return;
    }
    else {
      setErrorMessage("");
      setFromCount(value)
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div>
          <button
            style={{ float: "right", margin: "2em 1em 0 1em" }}
            onClick={() => {
              setIsEditingTrue(false);
              setShowAddModal(true);
            }}
            className="modelBtn mainButton"
          >
            Add Category
          </button>
        </div>
        <div style={{ paddingLeft: "4em" }} className="d-flex flex-wrap">
          {categoryArr?.map((el, i) => {
            return (
              <div
                key={i}
                onMouseEnter={() => setItemOptions(true)}
                onMouseLeave={() => setItemOptions(false)}
                className="catItem"
              >
                {itemOptions && (
                  <div className="itemOptions">
                    <div
                      style={{ cursor: "pointer" }}
                      className=""
                      onClick={() => {
                        handleEdit(el);
                        setSelectedCategory(el);
                      }}
                    >
                      <img
                        className="itemOptionIcon"
                        src={pencil}
                        alt="pencil"
                      />
                      <label className="editOption" htmlFor="">
                        Edits
                      </label>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      className=""
                      onClick={() => {
                        setShowDeleteModal(true);
                        setSelectedCategory(el);
                      }}
                    >
                      <img
                        className="itemOptionIcon"
                        src={deleteImg}
                        alt="delete"
                      />
                      <label className="editOption" htmlFor="">
                        Delete
                      </label>
                    </div>
                  </div>
                )}
                <div className="menu"></div>
                {/* //add no image condition  */}
                {el.thumbnailImage == "" ? (
                  <img
                    width={111}
                    height={132}
                    className="catitemImg"
                    src={Nutrition}
                    alt="catItem"
                  />
                ) : (
                  <img
                    width={111}
                    height={132}
                    className="catitemImg"
                    src={el.thumbnailImage}
                    alt="catItem"
                  />
                )}
                <p className="catitemName">{el.name}</p>
              </div>
            );
          })}
        </div>
      </div>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <div className="modelMain">
          <Modal.Body className="modelBody ">
            <div className="d-flex">
              <div className="row">
                <div className="col d-flex ">
                  <h3 className="deletePlaylistTitle">
                    {selectedCategory.isAlreadyAssigned
                      ? "Cannot delete this category because it is in use?"
                      : "Are you sure you want to delete the category?"}
                  </h3>
                </div>
                {selectedCategory.isAlreadyAssigned ? null : (
                  <div className="col modelFooter mt-4">
                    <button
                      style={{ width: "160px" }}
                      id="deleteRow"
                      className="mainButton modelBtn cancelBtn"
                      variant="secondary"
                      onClick={() => {
                        setShowDeleteModal(false);
                        deleteCategoryFunction();
                      }}
                    >
                      Yes, remove it
                    </button>
                    <button
                      className="mainButton modelBtn cancelBtn"
                      style={{
                        backgroundColor: "#AA23AD",
                        color: "#F2F4F6",
                        width: "160px",
                      }}
                      variant="primary"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      No, i just clicked it
                    </button>
                  </div>
                )}
              </div>
              <img width={124} src={pigImg} alt="" />
            </div>
          </Modal.Body>
        </div>
      </Modal>

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <div className="modelMain  modal-dialog-centered ">
          <Modal.Body className="modelBody ">
            <div className="container m-2">
              <div className="catName m-0">
                <label>Category Name:</label>
                <input
                  className="catInput"
                  placeholder="Type Category Name Here"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
              <div className="uploadImg">
                <label>Upload Image</label>
                <div className="upload-outer">
                  <>
                    <input
                      className="catInput inputFile"
                      type="file"
                      // name={file.name}
                      onChange={(e) => setFile(e.target.files[0])}
                    ></input>
                    <p>{file && file.name}</p>

                    {/* <img className='uploadIcon' src={uploadIcon} alt='upload' /> */}
                  </>
                </div>
              </div>
              <div className="ageGroup">
                <label>Age Group: </label>
                <div className="ageGroup-inner">
                  <p>From</p>
                  <div className="agegroupBtn-outer">
                    <button className="agegroupBtn" onClick={handleFromCount}>
                      +
                    </button>
                    <input
                      className={Styles.ageCountInput}
                      value={fromCount}
                      type="number"
                      maxLength={10}
                      onChange={(e) => handleFromInput(e.target.value)}
                    />
                    <button
                      style={{ marginRight: "-2px" }}
                      className="agegroupBtn"
                      onClick={handleNegativeFromCount}
                    >
                      -
                    </button>
                  </div>
                  <p>To</p>
                  <div className="agegroupBtn-outer">
                    <button
                      className="agegroupBtn"
                      onClick={() => setToCount((prevState) => parseInt(prevState) < 17 ? prevState + 1 : 17)}
                    >
                      +
                    </button>
                    <input
                      className={Styles.ageCountInput}
                      value={toCount}
                      type="number"
                      max={5}
                      onChange={(e) => handleToInput(e.target.value)}
                    />
                    <button
                      style={{ marginRight: "-2px" }}
                      className="agegroupBtn"
                      onClick={handleToCountMinus}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
              <p
                style={{ color: "red", fontSize: "12px", textAlign: "center" }}
              >
                {errorMessage}
              </p>
            </div>
            <div className="modelFooter justify-content-center">
              <button
                className="mainButton modelBtn"
                variant="secondary"
                onClick={() => {
                  setShowAddModal(false);
                  setFile("");
                }}
              >
                Cancel
              </button>
              <button
                className="mainButton modelBtn "
                variant="primary"
                onClick={isEditingTrue ? EditCategory : addCategories}
              >
                Save
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal >
    </div >
  );
}
