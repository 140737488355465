import React from 'react'
import { TextField, styled, Grid, Typography, MenuItem } from '@mui/material';
import styles from "./stickers.module.css";
import uploads from "../../../Assets/images/uploads.png";
import { useState, useRef } from 'react';

import { updatesticker, getonesticker } from '../../../services/sticker';


const Labels = styled(Typography)`
color: #878787;
font-size: 12px;
margin: 5px 0px;
`
const Field = styled(TextField)`
margin-top:20px;`

const EditModal = ({ closeEditModal, activeEditId, reload }) => {


    const initialValues = { name: "", type: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [image, setImage] = useState("")
    const inputRef = useRef(null);


    const data = async () => {
        let { data: res } = await getonesticker(activeEditId);
        console.log(res.data);
    }
    data();

    const handleImageClick = () => {
        inputRef.current.click();
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        console.log(file);
        setImage(e.target.files[0]);
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    }

    const handleDrop = (e) => {
        e.preventDefault();
        setImage(e.dataTransfer.files[0]);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        console.log("form data", formValues);
    };

    const handleEditSubmit = async (e) => {

        e.preventDefault();

        // setFormErrors(validate(formValues));

        const formdata = new FormData();
        if (image != "") formdata.append("image", image);
        if (formValues.name != "") formdata.append("name", formValues.name);
        if (formValues.description != "") formdata.append("type", formValues.type);

        if (formValues.name || formValues.type || image) {
            console.log("data edited to form ");
            let { data: res } = await updatesticker(activeEditId, formdata);
            alert(res.message)
            reload();
            setTimeout(closeEditModal, 500);
        } else {
            alert("Please fill at least one field")
            setTimeout(closeEditModal, 500);
        }
    }


    return (
        <>
            <div className={styles.wrapper} onClick={closeEditModal}></div>
            <div className={styles.editmodal}>
                <div className={styles.box}>
                    <div >
                        <p className={styles.headertext}>Edit Sticker Details</p>
                    </div>
                    <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <div onClick={handleImageClick} onDragOver={handleDragOver} onDrop={handleDrop}>
                                <div >
                                    {image ?
                                        (<img src={URL.createObjectURL(image)} className={styles.editupload} />)
                                        : (< img src={uploads} className={styles.editupload} />)}
                                </div>
                                <input
                                    type="file" ref={inputRef} onChange={handleImageChange} style={{ display: "none" }}></input>
                            </div>

                            <Field
                                id="outlined-basic"
                                label="Name"
                                variant="outlined"
                                name="name"
                                value={formValues.name}
                                onChange={handleChange}
                                className={styles.editInputs} />

                            <Field
                                id="outlined-select"
                                label="Type"
                                name="type"
                                select
                                onChange={handleChange}
                                value={formValues.type}
                                className={styles.editInputs}
                            >
                                <MenuItem value="Kid">Kid</MenuItem>
                                <MenuItem value="Parent">Parent</MenuItem>
                                <MenuItem value="Blessings">Blessings</MenuItem>
                            </Field>
                        </Grid>
                        <Grid xs={12}>
                            <button className={styles.button} onClick={closeEditModal}>
                                Close
                            </button>
                            <button className={styles.button} onClick={handleEditSubmit}>
                                Save Details
                            </button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}

export default EditModal