import API from "../axios/api";
import axios from "axios";

const BASE_LINK = API.BASE_LINK;

export const addNewActivity = async (formData) => {
  return await axios.post(`${BASE_LINK}/activity`, formData);
};

export const getSingleActivity = async (id) => {
  return await axios.get(`${BASE_LINK}/activity/getById/${id}`);
};
export const getActivitiesByCategoryId = async (id) => {
  return await axios.get(`${BASE_LINK}/activity/getByCategoryId/${id}`);
};

export const deleteSingleActivity = async (id) => {
  return await axios.delete(`${BASE_LINK}/activity/deleteById/${id}`);
};

export const updateSingleActivity = async (id, formData) => {
  return await axios.patch(`${BASE_LINK}/activity/updateById/${id}`, formData);
};

export const getAllActivities = async () => {
  return await axios.get(`${BASE_LINK}/activity/`);
};
