import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import styles from "./ecommerce.module.css";
import { TextField, Grid, Modal, MenuItem } from "@mui/material";
import { updateProduct } from "../../../services/Ecommerce/Product";
// import TextareaAutosize from "@mui/base/TextareaAutosize";
import { TextareaAutosize } from '@material-ui/core'
import Select from "react-select";
import Files from "react-files";
import uploads from "../../../Assets/images/uploads.png";
import {
  allcategories,
  allsubcategoriesByCategoties,
} from "../../../services/Ecommerce/category";

const EditProduct = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const prodData = location.state;
  const [loading, setloading] = useState(false);

  const initialValues = {
    product_name: prodData.product_name,
    category: prodData?.category?.map((x) => {
      return {
        label: x.categoryname,
        value: x._id,
      };
    }),
    subCategories: prodData?.subCategories?.map((x) => {
      return {
        label: x.categoryname,
        value: x._id,
      };
    }),
    description: prodData.description,
    return_policy: prodData.return_policy,
    madein: "India",
    return: prodData.return,
  };

  // console.log(initialValues, 'init values');
  const [formValues, setFormValues] = useState(initialValues);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCat, setSelectedSubCat] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [files, setFiles] = useState({});
  const [toCount, setToCount] = useState(prodData.ageto);
  const [fromCount, setFromCount] = useState(prodData.agefrom);
  const [src, setSrc] = useState();
  const [allcategory, setAllcategory] = useState([]);

  useEffect(() => {
    getallcategories();
  }, []);

  const getallcategories = async () => {
    let { data: res } = await allcategories();
    res?.data?.forEach((element) => {
      element.label = element.categoryname;
      element.value = element._id;
    });
    setAllcategory(res.data);
  };

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    // console.log("form vla", formValues);
  };

  const handleMultiple = (name, data) => {
    setFormValues({ ...formValues, [name]: data });
    console.log("cat", data);
  };

  const getSubCategory = async (name, data) => {
    // console.log(data, "data fromk cat");
    let fetchCatId = "";
    if (name === "category") {
      setSubCategory([]);
      setFormValues({ ...formValues, [name]: data });
      console.log("cat data", data);
      fetchCatId = data.map((x) => x._id);
      if (fetchCatId) {
        // console.log(fetchCatId, "fetch id");

        let { data: res } = await allsubcategoriesByCategoties(fetchCatId);
        // console.log(res.data, "sub cat fetched");
        res?.data?.forEach((element) => {
          element.label = element.categoryname;
          element.value = element._id;
          // console.log(element);
        });
        setSubCategory(res.data);
      }
    } else {
      console.log("running me", data);
      setSelectedSubCat(data);
    }
  };

  const editSubmitHandler = async () => {
    console.log("id");
    setFormErrors(validate(formValues));
    const formdata = new FormData();
    formdata.append("image", files);
    formdata.append("product_name", formValues.product_name);
    formdata.append("description", formValues.description);
    formdata.append("return_policy", formValues.return_policy);
    formdata.append(
      "category",
      formValues.category.map((x) => x.value)
    );
    formdata.append(
      "subCategories",
      formValues.subCategories.map((x) => x.value)
    );
    formdata.append("return", formValues.return);
    formdata.append("agefrom", fromCount);
    formdata.append("ageto", toCount);
    if (
      formValues.product_name &&
      formValues.description &&
      formValues.return_policy &&
      formValues.category
    ) {
      let { data: res } = await updateProduct(prodData?._id, formdata);
      // console.log("res", res);
      if (res.status) {
        console.log(formValues, "form data send to api");
        // alert(res.message);
        // navigate("/ecommerce/product")
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.productname) {
      errors.productname = "Product Name is Required";
    }

    if (!values.description) {
      errors.description = "Description is Required";
    }
    if (!values.category) {
      errors.category = "Please select Product Category";
    }
    if (!values.returnpolicy) {
      errors.returnpolicy = "Please add Return Policy";
    }
    if (!values.return) {
      errors.return = "Please select True or False";
    }
    if (!values.madein) {
      errors.madein = "Please enter country of origin";
    }
    if (fromCount < 4) {
      errors.fromCount = "Min age is 4";
    }
    if (toCount > 14) {
      errors.toCount = "Max age is 14";
    }
    return errors;
  };

  return (
    <>
      {loading ? (
        <div class="custom-loader"></div>
      ) : (
        <div className={styles.container}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                name="product_name"
                value={formValues.product_name}
                onChange={handleChange}
                className={styles.inputs}
                label="Product Name *"
                variant="outlined"
              />
              <small className="text-red">{formErrors.product_name}</small>
            </Grid>
            <Grid item xs={6}>
              <Select
                isMulti
                onChange={(data) => {
                  getSubCategory("category", data);
                }}
                options={allcategory}
                value={formValues.category}
              />
              <br />
              <Select
                isMulti
                onChange={(data) => {
                  handleMultiple("subCategories", data);
                }}
                options={subCategory}
                value={formValues.subCategories}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-select"
                select
                label="Return & Exchange *"
                value={formValues.return}
                name="return"
                onChange={handleChange}
                className={styles.inputs}
              >
                <MenuItem value="false">No</MenuItem>

                <MenuItem value="true">Yes</MenuItem>
              </TextField>
              <small className="text-red">{formErrors.return}</small>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-select"
                select
                label="Country Of Origin *"
                value={formValues.madein}
                name="madein"
                onChange={handleChange}
                className={styles.inputs}
              >
                <MenuItem value="India">India</MenuItem>
              </TextField>
              <small className="text-red">{formErrors.madein}</small>
            </Grid>
            <Grid item xs={6}>
              <label>Age Group: </label>
              <div>
                <p>From</p>
                <div className={styles.age}>
                  <button
                    className={styles.agebutton}
                    onClick={() =>
                      setFromCount((prevState) => parseInt(prevState) - 1)
                    }
                  >
                    -
                  </button>
                  <p className={styles.agep}>{fromCount}</p>
                  <button
                    className={styles.agebutton}
                    onClick={() =>
                      setFromCount((prevState) => parseInt(prevState) + 1)
                    }
                  >
                    +
                  </button>
                  <small className="text-red" style={{ paddingLeft: "10px" }}>
                    {formErrors.fromCount}
                  </small>
                </div>

                <p>To</p>
                <div className={styles.age}>
                  <button
                    className={styles.agebutton}
                    onClick={() =>
                      setToCount((prevState) => parseInt(prevState) - 1)
                    }
                  >
                    -
                  </button>
                  <p className={styles.agep}>{toCount}</p>
                  <button
                    className={styles.agebutton}
                    onClick={() =>
                      setToCount((prevState) => parseInt(prevState) + 1)
                    }
                  >
                    +
                  </button>
                  <small className="text-red" style={{ paddingLeft: "10px" }}>
                    {formErrors.toCount}
                  </small>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              {!files?.id && (
                <div className={styles.img}>
                  <Files
                    className="files-dropzone"
                    onChange={onFilesChange}
                    onError={onFilesError}
                    accepts={[
                      "image/png",
                      "image/jpg",
                      "image/jpeg",
                      "image/webp",
                      "audio/*",
                    ]}
                    multiple
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <img src={uploads} height="170" width={"100%"} />
                  </Files>
                </div>
              )}
              {files?.id && (
                <div className={styles.img}>
                  <Files
                    className="files-dropzone"
                    onChange={onFilesChange}
                    onError={onFilesError}
                    accepts={["image/png", ".pdf", "audio/*"]}
                    multiple
                    maxFileSize={10000000}
                    minFileSize={0}
                    clickable
                  >
                    <img src={src} height="170" width={"100%"} />
                  </Files>
                </div>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextareaAutosize
                minRows={6}
                id="outlined-basic"
                value={formValues.description}
                name="description"
                onChange={handleChange}
                className={styles.textarea}
                placeholder="Description *"
                variant="outlined"
              />
              <small className="text-red">{formErrors.description}</small>
            </Grid>

            <Grid item xs={6}>
              <TextareaAutosize
                minRows={6}
                id="outlined-basic"
                value={formValues.return_policy}
                onChange={handleChange}
                name="return_policy"
                className={styles.textarea}
                placeholder="Return & Policy"
                variant="outlined"
              />
              <small className="text-red">{formErrors.return_policy}</small>
            </Grid>

            <Grid item xs={12}>
              <button className={styles.button} onClick={editSubmitHandler}>
                Save Details
              </button>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default EditProduct;
