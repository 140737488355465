import React, { useEffect, useState, useRef } from "react";
import { TextField, Grid, Modal, MenuItem } from "@mui/material";
import styles from "../ecommerce/ecommerce.module.css";
import { addQuiz } from "../../../services/StoryBooks/books";
import { useNavigate } from "react-router";

const AddQuiz = () => {

  const navigate = useNavigate();

  const initialValues = {
    question: "",
  };

  const [objectivesFields, setObjectivesFields] = useState([]);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log("form vla", formValues);
  };

  const addObjectiveFields = (e) => {
    e.preventDefault();
    let object = {
      objectiveName: "",
      valid: "false",
    };
    setObjectivesFields([...objectivesFields, object]);
  };

  const removeFields = (index) => {
    let data = [...objectivesFields];
    data.splice(index, 1);
    setObjectivesFields(data);
  };

  const objHandleFormChange = (event, index) => {
    console.log(event, index);
    let data = [...objectivesFields];
    data[index][event.target.name] = event.target.value;
    setObjectivesFields(data);
    console.log("childFormFields", objectivesFields);
  };

  const submithandler = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    formValues.options = objectivesFields;
    console.log(formValues, 'faq data ')
    // return
    if (formValues.question) {
      console.log("objectivesFields inside if gone");
      let { data: res } = await addQuiz(formValues);
      console.log("data", res);
      if (res.status === "success") {
        setTimeout(() => {
          alert("Quiz Added Successfully");
          navigate(`/quizzess/quizList`);
        }, 300);
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.question) {
      errors.question = "Question is Required";
    }
    return errors;
  };

  return (
    <div className={styles.container}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            name="question"
            value={formValues.question}
            onChange={handleChange}
            className={styles.inputs}
            label="Question *"
            variant="outlined"
          />
          <small className="text-red">{formErrors.question}</small>
        </Grid>
      

        <Grid item xs={12}>
          <h3>Options</h3>

          {objectivesFields.map((data, index) => {
            return (
              <Grid sx={{ p: 1 }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} key={index}>
                <Grid
                  item
                  xs={6}
                 
                >
                  <TextField
                    id="outlined-basic"
                    name="objectiveName"
                    className={styles.inputs}
                    label="Objective *"
                    variant="outlined"
                    value={data.objectiveName}
                    onChange={(event) => objHandleFormChange(event, index)}
                  />
                  <small className="text-red">{formErrors.bookname}</small>
                </Grid>
                <Grid
                  item
                  xs={4}
                >
                  <TextField
                    id="outlined-select"
                    select
                    label="Valid Output *"
                    value={data.valid}
                    name="valid"
                    onChange={(event) => objHandleFormChange(event, index)}
                    className={styles.inputs}
                  >
                    <MenuItem value="false">False</MenuItem>

                    <MenuItem value="true">True</MenuItem>
                  </TextField>
                </Grid>
                <button
                  className="remove-btn"
                  onClick={() => removeFields(index)}
                >
                  remove
                </button>
              </Grid>
            );
          })}

          <Grid item xs={6}>
            <button className={styles.button} onClick={addObjectiveFields}>
              Add Options
            </button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <button className={styles.button} onClick={submithandler}>
            Save Details
          </button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddQuiz;
