import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import styles from "../ecommerce/ecommerce.module.css";
import { TextField, Grid } from "@mui/material";
import { updateQuizQues } from "../../../services/StoryBooks/books";
import TextareaAutosize from "@mui/base/TextareaAutosize";

const UpdateQuizList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location, "location of edit bok");
  const quizData = location.state;
  const [loading, setloading] = useState(false);

  console.log(quizData, "bookData");

  const initialValues = {
    question: quizData.question,
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    // console.log("form vla", formValues);
  };

  const editSubmitHandler = async () => {

    if (formValues.question) {
      let { data: res } = await updateQuizQues(quizData?._id, formValues);
      console.log("res", res);
      if (res.status) {
        console.log(formValues, "form data send to api");
        alert(res.message);
        navigate("/quizzess/quizList")
      }
    }
  };

  return (
    <>
      {loading ? (
        <div class="custom-loader"></div>
      ) : (
        <div className={styles.container}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <textarea
              type="text"
                value={formValues.question}
                onChange={handleChange}
                style={{
                    padding: '10px',
                    borderRadius: '4px',
                    height: '200px',
                    width: '100%'
                }}
                name="question"
              />
              <small className="text-red">{formErrors.question}</small>
            </Grid>

            <Grid item xs={12}>
              <button className={styles.button} onClick={editSubmitHandler}>
                Save Details
              </button>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default UpdateQuizList;